import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        appBar: {
            zIndex: 4000,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
    },
    hide: {
        display: 'none',
    },
    toolbar: {
        minHeight: 60,
        paddingLeft: 16,
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.349019607843137)',
        background:
            'linear-gradient(170deg, rgba(102, 51, 255, 1) 0%, rgba(102, 51, 255, 1) 0%, rgba(187, 0, 234, 1) 100%, rgba(187, 0, 234, 1) 100%)',
    },
    title: {
        flexGrow: 1,
    },
}));

export default function Header(props) {
    const classes = useStyles();
    const { isOpen, onDrawerOpen, isLoading, appName } = props;

    return (
        <>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isOpen,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    {onDrawerOpen && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={onDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, {
                                [classes.hide]: isOpen,
                            })}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap className={classes.title}>
                        {appName}
                    </Typography>
                    {isLoading ? <CircularProgress color="inherit" /> : null}
                </Toolbar>
            </AppBar>
        </>
    );
}
