const colors = {
    contrastForWhite: '#F2F2F2', // lightGrey
    contrast2ForWhite: '#e4e4e4', // darkGrey
    white: '#FFF',
    yellow: 'rgba(255, 249, 213, 1)',
    secondaryMain: 'rgb(255, 224, 159)',
    red: 'rgb(255,102, 153)',
    green: 'rgb(0 ,153,0)',
    dark: '#777E86',
    contrastColorForDark: '#d0d2d4',
    listItemDark: '#D2D5D7',
    hoverListItemDark: '#dedfe0',
    contrastListItemDark: '#666666',
    tabDarkBg: '#8b9198',
    tabDarkBgHover: '#9a9fa5',
    treeViewBg: '#a5a9af',
    treeViewBgHover: '#b0b4b9',
    detailsSectionBg: '#54595f',
    detailsSectionCardBg: '#646971',
    iconsMainColor: '#797979',
};

export default {
    type: 'dark',
    palette: {
        background: {
            paper: colors.contrastForWhite,
        },
        action: {
            hover: colors.contrastForWhite,
        },
        secondary: {
            main: colors.secondaryMain,
        },
    },
    zIndex: {
        drawer: 1000,
    },

    hoverColors: {
        menuItemBg: colors.contrast2ForWhite,
        removeIconBg: colors.red,
        addIconBg: colors.green,
        hoverListItemDark: colors.hoverListItemDark,
        tabDarkBgHover: colors.tabDarkBgHover,
        treeViewBgHover: colors.treeViewBgHover,
    },
    activeColors: {
        yellow: colors.yellow,
    },
    mainColors: {
        white: colors.white,
        dark: colors.dark,
        contrastColorForDark: colors.contrastColorForDark,
        listItemDark: colors.listItemDark,
        contrastListItemDark: colors.contrastListItemDark,
        tabDarkBg: colors.tabDarkBg,
        treeViewBg: colors.treeViewBg,
        detailsSectionBg: colors.detailsSectionBg,
        detailsSectionCardBg: colors.detailsSectionCardBg,
        iconsMainColor: colors.iconsMainColor,
    },
    headerHeight: 60,
    menuClosedWidth: 50,
    drawerWidth: 240,
};
