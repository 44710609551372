import React, { useEffect } from 'react';
import { makeStyles, createMuiTheme, Select, MenuItem, ThemeProvider, Typography } from '@material-ui/core';
import Card from './CustomCard';

const useStyles = makeStyles((theme) => ({
    cardNodeList: {
        boxShadow: 'none',
        position: 'relative',
        width: '100%',
        height: 40,
        padding: 6,
        background: '#e9eaeb',
    },
    cardContent: {
        padding: '0 !important',
    },
    label: {
        color: theme.mainColors.white,
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        margin: 0,
    },
    text: {
        fontSize: 16,
        marginRight: 6,
    },
    slider: {
        width: '95%',
    },
}));

const DropDown = ({ data, label, type, onChange, defaultValue }) => {
    const classes = useStyles();
    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#dedfe0',
            },
        },
    });
    const [val, setValue] = React.useState(0);

    useEffect(() => {
        if (typeof defaultValue !== 'undefined') setValue(defaultValue);
    }, [defaultValue]);

    const handleChange = (event) => {
        setValue(event.target.value);

        onChange(event.target.value, type);
    };

    return (
        <ThemeProvider theme={theme}>
            <Card>
                {label ? (
                    <div className={classes.label}>
                        <Typography className={classes.text} variant="h5" gutterBottom>
                            {label}
                        </Typography>
                    </div>
                ) : null}
                <Select className={classes.cardNodeList} value={val} defaultValue={val} onChange={handleChange}>
                    {data.map((item) => (
                        <MenuItem value={+item.id} key={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </Card>
        </ThemeProvider>
    );
};
DropDown.propTypes = {};

export default DropDown;
