import api from '../apiSingleton';
import { showNotification } from './UiEffectsActions';

export const GET_NODES_LIST = 'GET_NODES_LIST';
export const GET_NODE_ONE = 'GET_NODE_ONE';
export const CREATE_NODE = 'CREATE_NODE';
export const UPDATE_NODE = 'UPDATE_NODE';
export const REMOVE_NODE = 'REMOVE_NODE';
export const REMOVE_ALL_NODE = 'REMOVE_ALL_NODE';

export function getNodesList(sceneId, buttonId) {
    return async (dispatch) => {
        const data = await api.node.list(sceneId, buttonId);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_NODES_LIST,
                payload: data,
            });
        }
    };
}

export function getOneNode(sceneId, buttonId, nodeId) {
    return async (dispatch) => {
        const data = await api.node.one(sceneId, buttonId, nodeId);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_NODE_ONE,
                payload: data,
            });
        }
    };
}

export function createNode(sceneId, buttonId) {
    return async (dispatch, getState) => {
        const state = getState();
        const nodesLength = (state.node.nodeList && state.node.nodeList.length) || 0;

        const payload = { buttonNodeName: `Node ${nodesLength + 1}` };
        const data = await api.node.create(sceneId, buttonId, payload);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: CREATE_NODE,
                payload: data,
            });
        }
    };
}

export function updateNode({ sceneId, buttonId, nodeId, value }) {
    return async (dispatch) => {
        const data = await api.node.update(sceneId, buttonId, nodeId, { buttonNodeName: value });

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: UPDATE_NODE,
                payload: data,
            });
        }
    };
}

export function removeNode(sceneId, buttonId, nodeId) {
    return async (dispatch) => {
        const data = await api.node.remove(sceneId, buttonId, nodeId);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: REMOVE_NODE,
                payload: data,
            });
        }
    };
}

export function removeAllNodes() {
    return (dispatch) => {
        dispatch({
            type: REMOVE_ALL_NODE,
        });
    };
}
