import React, { useEffect, useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import withNotificationHOC from '../HOC/withNotificationHOC';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}.
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    wrapper: {},
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: 'white',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Reset({ resetPassword, history }) {
    const classes = useStyles();
    const [state, setState] = useState({ newPassword: '', passwordResetToken: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        const passwordResetToken = urlParams.get('passwordResetToken') || urlParams.get('resetToken');
        setState({ ...state, passwordResetToken });
    }, []);

    const onSubmit = async () => {
        setLoading(true);
        await resetPassword(state);
        setLoading(false);
        history.push('/');
    };

    const handleOnChange = (e) => {
        const { value } = e.target;
        setState({ ...state, newPassword: value });
    };

    return (
        <div className={classes.wrapper}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={handleOnChange}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            className={classes.submit}
                            disabled={!state.newPassword}
                        >
                            Set new password
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                    </form>
                </div>
                <Box mt={1}>
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}

const ResetWithNotification = withNotificationHOC(Reset);

export default ResetWithNotification;
