import Base from './Base';

class Button extends Base {
    async list(sceneId) {
        const data = await this.apiClient.get(`button/${sceneId}/`);

        return data;
    }

    async one(sceneId, buttonId) {
        const data = await this.apiClient.get(`button/${sceneId}/${buttonId}`);

        return data;
    }

    async create(sceneId, payload) {
        const data = await this.apiClient.post(`button/${sceneId}`, payload);

        return data;
    }

    async remove(sceneId, buttonId) {
        const data = await this.apiClient.delete(`button/${sceneId}/${buttonId}`);

        return data;
    }

    async update(sceneId, buttonId, payload) {
        const data = await this.apiClient.put(`button/${sceneId}/${buttonId}`, payload);

        return data;
    }
}

export default Button;
