import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import ButtonIcon from '../ButtonIcon';
import styles from './styles';

const useStyles = makeStyles(styles);

export default (props) => {
    const { children, onClose, isDark, ...other } = props;

    const classes = useStyles();

    const headerStyles = clsx(classes.rootHeader, {
        [classes.whiteColor]: isDark,
    });
    const textStyles = clsx(classes.headerText, {
        [classes.whiteColor]: isDark,
    });
    return (
        <DialogTitle className={headerStyles} {...other}>
            <span className={textStyles}>{children}</span>
            {onClose && (
                <ButtonIcon
                    onClick={onClose}
                    iconType="DeleteIcon"
                    classNames={['middleSize', 'withRedIconHover', 'modalClose', isDark && 'withWhiteColor']}
                />
            )}
        </DialogTitle>
    );
};
