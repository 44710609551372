import React from 'react';
import { connect } from 'react-redux';
import ActivationPage from '../pages/Activation';
import { resentActivationEmail } from '../actions/AuthActions';

function Activation(props) {
    const { resentActivationEmail, resentEmailStatus } = props;

    return <ActivationPage resentEmailStatus={resentEmailStatus} resentActivationEmail={resentActivationEmail} />;
}

const mapStateToProps = (state) => ({
    resentEmailStatus: state.auth.resentEmailStatus,
});

const mapDispatchToProps = {
    resentActivationEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Activation);
