import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RadioGroupMaterial from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: 10,
		background: '#646971',
		height: '100%',
		color: '#fff',
	},
	text: {
		fontSize: 16,
	},
}));

const WhiteRadio = withStyles({
	root: {
		color: '#fff',
		'&$checked': {
			color: '#fff',
		},
	},
	checked: {},
})((props) => <Radio color="default" {...props} />);

const RadioGroup = (props) => {
	const { name, initValue, onChange, label } = props;
	const [value, setValue] = React.useState('0');

	useEffect(() => {
		initValue && setValue(initValue.toString());
	}, [initValue]);

	const handleChange = (event) => {
		console.log('VAL', event.target.value);
		setValue(event.target.value);
		onChange(event.target.value, name);
	};

	const slasses = useStyles();

	return (
		<div className={slasses.root}>
			{label && (
				<Typography className={slasses.text} variant="h5" gutterBottom>
					{label}
				</Typography>
			)}
			<RadioGroupMaterial name={name} value={value} onChange={handleChange}>
				<FormControlLabel value="0" control={<WhiteRadio />} label="None" />
				<FormControlLabel value="1" control={<WhiteRadio />} label="Soft studio lighting" />
				<FormControlLabel value="2" control={<WhiteRadio />} label="Room with reflections" />
				<FormControlLabel value="3" control={<WhiteRadio />} label="Open field sunset" />
			</RadioGroupMaterial>
		</div>
	);
};
RadioGroup.propTypes = {};
RadioGroup.defaultProps = {
	name: 'defaultHDRIMap',
};

export default RadioGroup;
