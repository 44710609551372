import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.mainColors.detailsSectionCardBg,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 10,
        width: '100%',
        position: 'relative',
    },
    Slider: {
        alignItems: 'start',
    },
    textareaWrapper: {
        height: '100%',
    },
    editableInput: {
        marginTop: 10,
        padding: 10,
        width: '100%',
        display: 'block',
    },
    imageWrapper: {
        padding: '10px 0px',
        marginTop: 10,
        padding: 10,
        alignItems: 'start',
    },
    highlightImageWrapper: {
        padding: '10px 0px',
        width: '100%',
        height: 340,
        marginTop: 10,
        padding: 10,
        alignItems: 'start',
    },
    backdrop: {
        justifyContent: 'center',
        minHeight: '100%',
        width: '66.66%',
        background: theme.mainColors.detailsSectionBg,
    },
    backdrop_full: {
        width: '100%',
        justifyContent: 'center',
        minHeight: '100%',
        background: theme.mainColors.dark,
    },
    fullWidth: {
        width: '100%',
    },
    whiteModal: {
        background: theme.palette.background.paper,
        width: '72%',
        alignItems: 'start',
    },
    CheckBox: {
        paddingRight: 4,
    },
    textareaWrapperFeedback: {
        height: 'calc(100% - 50px)',
    },
}));

export default React.forwardRef((props, ref) => {
    const { children, styles, classNames = [] } = props;
    const classes = useStyles();

    const cardStyles = clsx(
        classes.wrapper,
        classNames.map((key) => classes[key])
    );

    return (
        <div className={cardStyles} style={styles} ref={ref}>
            {children}
        </div>
    );
});
