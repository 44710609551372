import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';
import List from '../../ButtonDetailsList';
import CheckBox from '../../CheckBox';
import Slider from '../../Slider';

import withSettingsWrapperHOC from '../SettingsHOC';
import withDisabled from '../../../HOC/DisableHOC';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.LIGHTING;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const LightingContainer = (props) => {
    const { currentConfig, onChange, onAdd, onRemove } = props;
    const { lightsWithShadows, noShadowObjects, shadowsEnabled, shadowQuality } = currentConfig;

    const [shadowsList, setShadowsList] = useState([]);
    const [withoutShadowsList, setWithoutShadowsList] = useState([]);
    const [shadowsEnabledVal, setShadowsEnabledVal] = useState(false);
    const [sliderVal, setSliderVal] = useState(0);

    useEffect(() => {
        setShadowsList(lightsWithShadows);
    }, [lightsWithShadows]);

    useEffect(() => {
        setWithoutShadowsList(noShadowObjects);
    }, [noShadowObjects]);

    useEffect(() => {
        setShadowsEnabledVal(shadowsEnabled);
    }, [shadowsEnabled]);

    useEffect(() => {
        setSliderVal(shadowQuality);
    }, [shadowQuality]);

    const setListByType = (type, state) => {
        switch (type) {
            case 'lightsWithShadows':
                setShadowsList(state);
                break;
            case 'noShadowObjects':
                setWithoutShadowsList(state);
                break;
            default:
                break;
        }
    };

    const handleAdd = (type) => {
        const newStateByType = onAdd(type);
        setListByType(type, newStateByType);
    };
    const handleRemove = (id, type) => {
        const newStateByType = onRemove(type, id);
        setListByType(type, newStateByType);
    };
    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };

    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowsEnabled}>
                    <CheckBox
                        val={shadowsEnabledVal}
                        onChange={handleUpdate}
                        type="shadowsEnabled"
                        label="Enable shadows"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.lightsWithShadows}>
                    <List
                        title="Lights with shadows"
                        isDisabled={false}
                        data={shadowsList}
                        type="lightsWithShadows"
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        onChange={handleUpdate}
                        idKey="id"
                        nameKey="name"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.noShadowObjects}>
                    <List
                        title="Objects without shadows"
                        isDisabled={false}
                        data={withoutShadowsList}
                        type="noShadowObjects"
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        onChange={handleUpdate}
                        idKey="id"
                        nameKey="name"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowQuality}>
                    <Slider
                        val={sliderVal}
                        onChange={handleUpdate}
                        label="Shadow quality"
                        type="shadowQuality"
                        min={128}
                        max={4096}
                        step={128}
                    />
                </CardContent>
            </Card>
        </>
    );
};

LightingContainer.propTypes = {
    //  : PropTypes.,
};
const CardContent = withDisabled(CardContentComponent);
const GenerslContsinerHOC = withSettingsWrapperHOC(LightingContainer, 'LIGHTING');

export default GenerslContsinerHOC;
