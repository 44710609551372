import React, { useEffect, useRef } from 'react';
import { Redirect, Route } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';

const $ = window;
const globalConfig = $.config;

export const generateButtonMaterailPayload = (count) => {
    return {
        materialID: uuid(),
        materialName: `Material ${count}`,
        materialNode: 'mat1',
        changeNodes: [{ id: uuid(), name: 'Node' }],
    };
};

export function PrivateRoute({ ...props }) {
    const isLoggedIn = localStorage.getItem('token');

    return isLoggedIn ? <Route {...props} /> : <Redirect to="/login" />;
}

export function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

export function prepareFormDataFile(payload) {
    const formData = new FormData();

    if (payload && Object.keys(payload).length > 0) {
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }
    } else {
        throw new Error('Incorrect file');
    }

    return formData;
}

export function prepareFormBody(body) {
    const formBody = [];

    for (const property in body) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(body[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    }

    return formBody.join('&');
}

export function getBase64(file, cb) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const getGroupList = (buttonsList) => {
    let groupList = [];
    groupList = buttonsList.filter((item) => item.isGroup === true);

    groupList = groupList.map((group) => {
        const currentGroupId = group.buttonId;
        return { ...group, buttons: buttonsList.filter((button) => button.buttonGroupId === currentGroupId) };
    });

    return groupList;
};

export const prepareListForSelect = (array, key) => {
    return array.map((item) => {
        return { id: item[key], name: item.name, selected: false };
    });
};

export const getCleanButtons = (buttonsList) => {
    return buttonsList.filter((button) => {
        return button.isGroup === false;
    });
};

const isUrl = (str) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return pattern.test(str);
};

const isPath = (str) => {
    return str.startsWith('/api/images/', 0);
};

export const checkImageLink = (link) => {
    if (isUrl(link)) return 'LINK';
    if (isPath(link)) return 'PATH';
};

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const getWorldConfig = (payload) => {
    const config = JSON.parse(JSON.stringify(payload));
    const alloved = globalConfig.allovedFieldsForWorldConfig || [];
    return _.pick(config, alloved);
};

export const getInterfaceConfig = (config) => {
    const fieldsList = Object.keys(config);
    const alloved = _.difference(fieldsList, globalConfig.allovedFieldsForWorldConfig);
    return _.pick(config, alloved);
};

export const prepareConfig = (dataObj) => {
    const data = JSON.parse(JSON.stringify(dataObj));

    Object.keys(data).map((key) => {
        const element = data[key];
        if (Array.isArray(element) && element.length) {
            const preparedArray = element.map((item) => (typeof item === 'object' && item.name) || item);
            data[key] = preparedArray;
            // console.log('prepareConfig', element, preparedArray);
        }
        return element;
    });

    return data;
};

export function getFileExtension(filename) {
    return filename.split('.').pop();
}

export const normilizeObjToArr = (dataObj) => {
    const data = JSON.parse(JSON.stringify(dataObj));

    Object.keys(data).map((key) => {
        const element = data[key];

        if (Array.isArray(element) && element.length && element.every((item) => typeof item === 'string')) {
            const preparedArray = element.map((item) => ({ id: uuid(), name: item }));

            console.log('UTILS PREPARED  CONFI', preparedArray);
            data[key] = preparedArray;
        }
        return element;
    });

    return data;
};

const ALLOWED_ITEMS = {
    GENERAL: ['allowedHost', 'alwaysVisible', 'alwaysHidden', 'showStats', 'preventDefault'],
    CAMERA: [
        'fieldOfView',
        'limitRotationAngle',
        'allowPanning',
        'zoomButtons',
        'zoomPinchEnabled',
        'minZoom',
        'maxZoom',
        'initx',
        'inity',
        'initz',
        'targetx',
        'targety',
        'targetz',
        'zoomButtonsPrecision',
        'zoomPinchPrecision',
        'zoomWheelPrecision',
    ],
    LIGHTING: ['lightsWithShadows', 'noShadowObjects', 'shadowsEnabled', 'shadowQuality'],
    ENVIRONMENT: [
        'enableBackgroundColor',
        'backgroundColor',
        'backgroundOpacity',
        'defaultHDRIMap',
        'HDRIMapRotation',
        'showHDRIMap',
        'highlightOutline',
        'highlightColor',
    ],
    POSTPROCESSING: [
        'enablePostProcessing',
        'antiAliasingSamples',
        'enableBloom',
        'bloomThreshold',
        'bloomWeight',
        'bloomKernel',
        'enableColorProcessing',
        'globalHue',
        'globalDensity',
        'globalExposure',
        'globalSaturation',
        'highlightsHue',
        'highlightsDensity',
        'highlightsExposure',
        'highlightsSaturation',
        'midtonesHue',
        'midtonesDensity',
        'midtonesExposure',
        'midtonesSaturation',
        'shadowsHue',
        'shadowsDensity',
        'shadowsExposure',
        'shadowsSaturation',
    ],
    CUSTOM: [
        'allowedHost',
        'alwaysVisible',
        'alwaysHidden',
        'showStats',
        'preventDefault',
        'fieldOfView',
        'limitRotationAngle',
        'allowPanning',
        'zoomButtons',
        'zoomPinchEnabled',
        'minZoom',
        'maxZoom',
        'initx',
        'inity',
        'initz',
        'targetx',
        'targety',
        'targetz',
        'zoomButtonsPrecision',
        'zoomPinchPrecision',
        'zoomWheelPrecision',
        'lightsWithShadows',
        'noShadowObjects',
        'shadowsEnabled',
        'shadowQuality',
        'enableBackgroundColor',
        'backgroundColor',
        'backgroundOpacity',
        'defaultHDRIMap',
        'HDRIMapRotation',
        'showHDRIMap',
        'highlightOutline',
        'highlightColor',
        'enablePostProcessing',
        'antiAliasingSamples',
        'enableBloom',
        'bloomThreshold',
        'bloomWeight',
        'bloomKernel',
        'enableColorProcessing',
        'globalHue',
        'globalDensity',
        'globalExposure',
        'globalSaturation',
        'highlightsHue',
        'highlightsDensity',
        'highlightsExposure',
        'highlightsSaturation',
        'midtonesHue',
        'midtonesDensity',
        'midtonesExposure',
        'midtonesSaturation',
        'shadowsHue',
        'shadowsDensity',
        'shadowsExposure',
        'shadowsSaturation',
    ],
};

const ALLOWED_ITEMS_INTERFACE = {
    GENERAL: [
        'uiMaxWidth',
        'showGroups',
        'columnView',
        'flexibleButtons',
        'defaultButtonImages',
        'buttonHeight',
        'buttonWidth',
        'buttonOpacity',
        'buttonDimmedOpacity',
        'buttonColor',
        'buttonBorderColor',
        'buttonFontColor',
        'buttonDimmedFontColor',
        'buttonBorderWidth',
        'buttonBorderRadius',
        'buttonFontSize',
        'showCredits',
    ],
    CUSTOM: [
        'uiMaxWidth',
        'showGroups',
        'columnView',
        'flexibleButtons',
        'defaultButtonImages',
        'buttonHeight',
        'buttonWidth',
        'buttonOpacity',
        'buttonDimmedOpacity',
        'buttonColor',
        'buttonBorderColor',
        'buttonFontColor',
        'buttonDimmedFontColor',
        'buttonBorderWidth',
        'buttonBorderRadius',
        'buttonFontSize',
        'showCredits',
    ],
};
export const filterConfigByType = (obj, type, tabType) => {
    const allowedItems = tabType ? ALLOWED_ITEMS_INTERFACE : ALLOWED_ITEMS;

    const objData = JSON.parse(JSON.stringify(obj));
    const fieldsList = Object.keys(objData);

    // console.log(obj, type, tabType, allowedItems, fieldsList, objData);

    const allovedForCustom = _.difference(fieldsList, allowedItems.CUSTOM);
    const res = type === 'CUSTOM' ? _.pick(objData, allovedForCustom) : _.pick(objData, allowedItems[type]);
    // console.log('__RES', res);
    return res;
};

// var a  = [{name: 'allowedHost', value: 'DEFAULT_VAL', component: 'CHECKBOX', props: { min: 5, max: 6 }];

// const cpmponents = {CHECKBOX: <CheckBox /> }
