export default {
    uiMaxWidth: 25,
    showGroups: true,
    columnView: false,
    flexibleButtons: false,
    defaultButtonImages: 0,
    buttonHeight: 50,
    buttonWidth: 70,
    buttonOpacity: 0.7,
    buttonDimmedOpacity: 0.5,
    buttonColor: '#555555',
    buttonBorderColor: '#CCCCCC',
    buttonFontColor: '#FFFFFF',
    buttonDimmedFontColor: '#777777',
    buttonBorderWidth: 0,
    buttonBorderRadius: 0,
    buttonFontSize: 16,
    showCredits: true,
};
