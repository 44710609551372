import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ScenesList from '../ui-components/List';
import ScenesDialog from '../ui-components/Modals/ScenesDialog';
import Tabs from '../ui-components/Tabs/SceneTabs';
import DeleteModal from '../ui-components/Modals/DeleteDialog';
import WarningDialog from '../ui-components/Modals/WarningDialog';
import withNotificationHOC from '../HOC/withNotificationHOC';
import _ from 'lodash';


const globalConfig = window.config;

const useStyles = makeStyles(() => ({
    content: {
        flexGrow: 1,
        paddingTop: 60,
    },
    root: {
        width: '100%',
        height: 'calc(100vh - 60px)',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
}));

function Scenes(props) {
    const {
        getConfig,
        saveScene,
        removeScene,
        saveConfig,
        config,
        interfaceConfig,
        worldConfig,
        errorMsg,
        successMsg,
        createButton,
        getConfigById,
        removeButton,
        getOneButton,
        updateButton,
        buttonList,
        currentButton,
        createNode,
        updateNode,
        removeNode,
        getNodesList,
        nodeList,
        createHighlight,
        removeHighlight,
        updateHighlight,
        highlightList,
        currentHighlight,
        getHighlightsList,
        getButtonsList,
        buttonGroupList,
        createButtonGroup,
        updateButtonForGroup,
        getOneHighlight,
        saveImage,
        buttonListMaterials,
        buttonListGeometry,
        preview,
        getIframe,
        clearMessages,
        updateScene,
        ...otherProps
    } = props;

    const { addToast } = useToasts();

    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [activeItemId, setActiveItem] = useState(null);
    const [sceneName, setSceneName] = useState('');
    const [editableSceneId, setEditableSceneId] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [idForRemove, setIdForRemove] = useState(null);
    const [openWarning, setOpenWarning] = useState(false);
    const [firstButtonId, setFirstButtonId] = useState(null);

    async function handleOpenScene(id) {
        if (id === activeItemId) return;
        await getConfigById(id);
        await getHighlightsList(id);
   
        const { buttons } = await getButtonsList(id);
        const firstButton = _.head(buttons);

        if (firstButton?.buttonId)  {
            setFirstButtonId(firstButton?.buttonId);
        }

        setActiveItem(id);
    }

    useEffect(() => {
        (async () => await getConfig())();
    }, []);

    useEffect(() => {
        if (config?.length) {
            const firstElement = config[0];
            handleOpenScene(activeItemId || firstElement.id);
        }
    }, [config, activeItemId]);

    useEffect(() => {
        if (errorMsg) {
            addToast(errorMsg, { appearance: 'error' });
        }
        if (successMsg) {
            addToast(successMsg, { appearance: 'success' });
        }
        setTimeout(() => clearMessages());
    }, [errorMsg, successMsg]);

    const handleOpenRemoveModal = (id) => {
        setIsOpenDeleteModal(true);
        setIdForRemove(id);
    };

    const handleCloseDelete = () => setIsOpenDeleteModal(false);

    const handleOpenCreateDialog = () => {
        const { limitScenes } = globalConfig;
        const role = localStorage.getItem('role');

        limitScenes <= config.length && role === 'CUSTOMER' ? setOpenWarning(true) : setOpenCreate(true);
    };

    const handleRemoveScene = async (id) => {
        await removeScene(id);
        handleCloseDelete();
        setActiveItem(null)
    };

    const handleCloseCreateModal = async (file) => {
        setOpenCreate(false);
        if (file) await saveScene({ file });
    };

    const handleGetOneButton = async (id, sceneId) => {
        await getOneButton(sceneId || activeItemId, id);
        await getNodesList(sceneId || activeItemId, id);
    };

    const handleEditScene = (id) => {
        const currentScene = config.find((sceneItem) => sceneItem.id === id) || {};
console.log('id',id, currentScene);
        setEditableSceneId(id);
        setSceneName(currentScene.name);
        setOpenEdit(true);
    };

    async function  handleRemoveButton(sceneId,buttonId,type) {
        await removeButton(sceneId,buttonId,type);

        if (type) {
            const { buttons } = await getButtonsList(sceneId);
            const firstButton = _.head(buttons);

            if (firstButton?.buttonId)  {
                setFirstButtonId(firstButton?.buttonId);
                await handleGetOneButton(firstButton?.buttonId, sceneId)
            }
    
        }
    }

    const handleCreateButtonGroup = async (sceneId, type) => {
        const {button} =  await createButtonGroup(sceneId, type);

        if (button?.buttonId)  {
            setFirstButtonId(button?.buttonId);
            await handleGetOneButton(button?.buttonId, sceneId)
        }
    }

    const handleCloseEditModal = async (file, name) => {
        console.log(file, name);
        if (!file && !name) {
            setOpenEdit(false);
            setSceneName('');

            return;
        }

        setSceneName('');
        setOpenEdit(false);
        await updateScene(editableSceneId, { file, name });
    };

    return (
        <main className={classes.content}>
            <div className={classes.root}>
                <ScenesList
                    data={config}
                    title="Scenes"
                    idKey="id"
                    nameKey="name"
                    onItemClick={handleOpenScene}
                    onCreate={handleOpenCreateDialog}
                    onRemoveItem={handleOpenRemoveModal}
                    onEditItem={handleEditScene}
                    activeItemId={activeItemId}
                />
                <Tabs
                    firstButtonId={firstButtonId}
                    buttonList={buttonList}
                    nodeList={nodeList}
                    updateNode={updateNode}
                    updateButton={updateButton}
                    createButton={createButton}
                    buttonGroupList={buttonGroupList}
                    createButtonGroup={handleCreateButtonGroup}
                    sceneId={activeItemId}
                    removeButton={handleRemoveButton}
                    createNode={createNode}
                    removeNode={removeNode}
                    createHighlight={createHighlight}
                    updateHighlight={updateHighlight}
                    removeHighlight={removeHighlight}
                    interfaceConfig={interfaceConfig}
                    worldConfig={worldConfig}
                    saveMockConfig={saveConfig}
                    getOneButton={handleGetOneButton}
                    getOneHighlight={getOneHighlight}
                    currentButton={currentButton}
                    currentHighlight={currentHighlight}
                    highlightList={highlightList}
                    chahgeButtonImage={updateButton}
                    updateButtonForGroup={updateButtonForGroup}
                    saveImage={saveImage}
                    buttonListMaterials={buttonListMaterials}
                    buttonListGeometry={buttonListGeometry}
                    preview={preview}
                    getIframe={getIframe}
                    showBackdrop={config.length < 1}
                    onOpenDialog={handleOpenCreateDialog}
                    {...otherProps}
                />
            </div>
            <ScenesDialog keepMounted open={openCreate} title="Add new scene" onClose={handleCloseCreateModal} />
            <ScenesDialog
                keepMounted
                sceneName={sceneName}
                open={openEdit}
                isEdit
                title="Edit scene"
                buttonName="SAVE"
                onClose={handleCloseEditModal}
            />
            <DeleteModal
                isOpen={isOpenDeleteModal}
                onClose={handleCloseDelete}
                onDelete={handleRemoveScene}
                title="Delete scene"
                pageName="scene"
                id={idForRemove}
            />
            <WarningDialog isOpen={openWarning} onClose={() => setOpenWarning(false)} />
        </main>
    );
}

export default withNotificationHOC(Scenes);
