import api from '../apiSingleton';
import { showNotification } from './UiEffectsActions';

export const GET_HIGHLIGHTS_LIST = 'GET_HIGHLIGHTS_LIST';
export const GET_HIGHLIGHT_ONE = 'GET_HIGHLIGHT_ONE';
export const CREATE_HIGHLIGHT = 'CREATE_HIGHLIGHT';
export const UPDATE_HIGHLIGHT = 'UPDATE_HIGHLIGHT';
export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';
export const REMOVE_ALL_HIGHLIGHT = 'REMOVE_ALL_HIGHLIGHT';

export function getHighlightsList(sceneId) {
    return async (dispatch) => {
        const data = await api.highlight.list(sceneId);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_HIGHLIGHTS_LIST,
                payload: data,
            });
        }
    };
}

export function getOneHighlight(sceneId, highlightId) {
    return async (dispatch) => {
        const data = await api.highlight.one(sceneId, highlightId);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_HIGHLIGHT_ONE,
                payload: data,
            });
        }
    };
}

export function createHighlight(sceneId) {
    return async (dispatch, getState) => {
        const state = getState();
        const highlightsLength = (state.highlight.highlightList && state.highlight.highlightList.length) || 0;

        const payload = { highlightName: `Highlight ${highlightsLength + 1}`, highlightPicturePath: null };
        const data = await api.highlight.create(sceneId, payload);
        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: CREATE_HIGHLIGHT,
                payload: data,
            });
        }
    };
}

export function updateHighlight({ sceneId, highlightId, payload }) {
    return async (dispatch) => {
        const data = await api.highlight.update(sceneId, highlightId, { ...payload });
        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: UPDATE_HIGHLIGHT,
                payload: data,
            });
        }
    };
}

export function removeHighlight(sceneId, highlightId) {
    return async (dispatch) => {
        const data = await api.highlight.remove(sceneId, highlightId);
        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: REMOVE_HIGHLIGHT,
                payload: data,
            });
        }
    };
}

export function removeAllHighlights() {
    return (dispatch) => {
        dispatch({
            type: REMOVE_ALL_HIGHLIGHT,
        });
    };
}
