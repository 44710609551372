/* eslint-disable react/prop-types */
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Backdrop from '../Backdrop';
import EditableInput from '../EditableInput';
import CustomList from '../List';
import { HighlightImage } from './HighlightImage';

const useStyles = makeStyles(() => {
	return {
		detailsRoot: {
			width: '66.66%',
			padding: '0 10px',
		},
		rootTabPanelBlack: {
			width: '100%',
			display: 'flex',
			height: '100%',
			minHeight: 600,
			background: '#505459',
		},
	};
});

export default function HighlightTab(props) {
	const classes = useStyles();
	const {
		highlightList,
		currentHighlight,
		sceneId,
		saveImage,
		removeHighlight,
		createHighlight,
		updateHighlight,
		getOneHighlight,
	} = props;

	const [highlightId, setHighlightId] = useState(null);
	const handleGetOne = (id) => {
		setHighlightId(id);
		getOneHighlight(sceneId, id);
	};

	useEffect(() => {
		if (!highlightId && highlightList.length) {
			const firstHighlightId = highlightList[0].highlightNodeId;

			handleGetOne(firstHighlightId);
		}
	}, [highlightList]);

	const handleCreate = (id) => {
		createHighlight(sceneId, id);
	};
	const handleRemove = (id) => {
		removeHighlight(sceneId, id);
		setHighlightId(null);
	};
	const handleChangeName = (highlightName) => updateHighlight({ highlightId, payload: { highlightName }, sceneId });
	const handleChangeImage = (payload) => updateHighlight({ highlightId, payload, sceneId });
	const handleChangeNode = (highlightNode) => updateHighlight({ highlightId, payload: { highlightNode }, sceneId });
	const handleSaveImage = (file) => saveImage(sceneId, file);

	return (
		<>
			<CustomList
				title="Highlights"
				onItemClick={handleGetOne}
				onCreate={handleCreate}
				onRemoveItem={handleRemove}
				data={highlightList}
				idKey="highlightNodeId"
				nameKey="highlightName"
				activeItemId={highlightId}
				isDisabledCreateButton={!sceneId}
				isDisabledCreateGroup
				isDark
				wrapperStyle={{
					boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',
					width: '33.33%',
					zIndex: 2,
				}}
			/>

			{highlightList.length ? (
				<div className={classes.detailsRoot}>
					<Scrollbars style={{ height: 'calc(100vh - 112px)' }} autoHide>
						<EditableInput
							title={(currentHighlight && currentHighlight.highlightName) || ''}
							label="Display name"
							onChange={handleChangeName}
							disabled={false}
						/>
						<HighlightImage
							onChange={handleChangeImage}
							onSaveImage={handleSaveImage}
							sceneId={sceneId}
							highlightPicture={(currentHighlight && currentHighlight.highlightPicture) || null}
						/>
						<EditableInput
							title={(currentHighlight && currentHighlight.highlightNode) || ''}
							label="Highlight node"
							onChange={handleChangeNode}
							disabled={!highlightId}
						/>
					</Scrollbars>
				</div>
			) : (
				<Backdrop onClick={handleCreate} title="Add a highlight" />
			)}
		</>
	);
}
