import Base from './Base';

class Auth extends Base {
    async signIn(payload) {
        let data = {};
        try {
            data = await this.apiClient.authPost('auth/signin', payload);
            data.status = 1;
        } catch (error) {
            console.error(error);
            data = { status: 0, message: error };
        }

        return data;
    }

    async signUp(payload) {
        let data = {};
        try {
            data = await this.apiClient.authPost('auth/signup', payload);

            data.status = 1;
        } catch (error) {
            console.error(error);
            data = { status: 0, message: error };
        }

        return data;
    }

    async resentActivation(payload) {
        const data = await this.apiClient.post('auth/verification/resend', payload);

        return data;
    }

    async verifyEmail(code) {
        const data = await this.apiClient.get(`auth/verify/${code}`);

        return data;
    }

    async resetPassword(payload) {
        const data = await this.apiClient.post('auth/password/reset', payload);

        return data;
    }

    async forgotPassword(payload) {
        const data = await this.apiClient.post('auth/password/forgot', payload);

        return data;
    }

    async sendFeedback(payload) {
        const data = await this.apiClient.feedbackPost('users/feedback', payload);

        return data;
    }
}

export default Auth;
