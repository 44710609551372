import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';
import List from '../../ButtonDetailsList';
import withDisabled from '../../../HOC/DisableHOC';
import CheckBox from '../../CheckBox';
import withSettingsWrapperHOC from '../SettingsHOC';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.GENERAL;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const GeneralContainer = (props) => {
    const { currentConfig, onChange, onAdd, onRemove } = props;
    const { allowedHost, alwaysVisible, alwaysHidden, preventDefault, showStats } = currentConfig;
    const [hostsList, setHostsList] = useState([]);
    const [visibleList, setVisibleList] = useState([]);
    const [hiddenList, setHiddenList] = useState([]);

    const [preventDefaultVal, setPreventDefaultVal] = useState(false);
    const [showStatsVal, setShowStatsVal] = useState(false);

    useEffect(() => {
        setHostsList(allowedHost);
    }, [allowedHost]);

    useEffect(() => {
        setVisibleList(alwaysVisible);
    }, [alwaysVisible]);

    useEffect(() => {
        setHiddenList(alwaysHidden);
    }, [alwaysHidden]);

    useEffect(() => {
        setPreventDefaultVal(preventDefault);
    }, [preventDefault]);

    useEffect(() => {
        setShowStatsVal(showStats);
    }, [showStats]);

    const setListByType = (type, state) => {
        switch (type) {
            case 'allowedHost':
                setHostsList(state);
                break;
            case 'alwaysVisible':
                setVisibleList(state);
                break;
            case 'alwaysHidden':
                setHiddenList(state);
                break;
            default:
                break;
        }
    };

    const handleAdd = (type) => {
        const newStateByType = onAdd(type);
        setListByType(type, newStateByType);
    };
    const handleRemove = (id, type) => {
        const newStateByType = onRemove(type, id);
        setListByType(type, newStateByType);
    };
    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };

    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.allowedHost}>
                    <List
                        title="Allowed hosts"
                        isDisabled={false}
                        data={hostsList}
                        type="allowedHost"
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        onChange={handleUpdate}
                        idKey="id"
                        nameKey="name"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.alwaysVisible}>
                    <List
                        title="Always visible"
                        isDisabled={false}
                        data={visibleList}
                        type="alwaysVisible"
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        onChange={handleUpdate}
                        idKey="id"
                        nameKey="name"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.alwaysHidden}>
                    <List
                        title="Always hidden"
                        isDisabled={false}
                        data={hiddenList}
                        type="alwaysHidden"
                        onAdd={handleAdd}
                        onRemove={handleRemove}
                        onChange={handleUpdate}
                        idKey="id"
                        nameKey="name"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.showStats}>
                    <CheckBox val={showStatsVal} onChange={handleUpdate} type="showStats" label="Show statistics" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.preventDefault}>
                    <CheckBox
                        val={preventDefaultVal}
                        onChange={handleUpdate}
                        type="preventDefault"
                        label="Prevent default page actions"
                    />
                </CardContent>
            </Card>
        </>
    );
};

GeneralContainer.propTypes = {
    //  : PropTypes.,
};

const CardContent = withDisabled(CardContentComponent);

const GenerslContsinerHOC = withSettingsWrapperHOC(GeneralContainer, 'GENERAL');

export default GenerslContsinerHOC;
