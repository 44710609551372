import { makeStyles } from '@material-ui/core';
// import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import PropTypes from 'prop-types';
import Card from './CustomCard';

const useStyles = makeStyles(() => ({
    pickerWrapper: {
        position: 'absolute',
        zIndex: 111111,
        width: '500px',
    },
    backDrop: {
        width: '100vw',
        height: '100vh',
        background: 'transparent',
        zIndex: 10,
        top: 0,
        left: 0,
        position: 'fixed',
    },
}));

function ColorPicker(props) {
    const classes = useStyles();
    const { initColor, onChange, disabled, cardStyles, cardClassName, pickerVisible, toglePicker } = props;

    const [value, setValue] = useState('#ddd');

    useEffect(() => {
        setValue(initColor);
    }, [initColor]);

    const handleChange = (color) => {
        setValue(color.hex);
        onChange(color.hex);
    };

    return (
        <Card classNames={cardClassName} styles={{ padding: 0 }}>
            {pickerVisible && (
                <>
                    <div className={classes.backDrop} onClick={() => toglePicker()} />

                    <div className={classes.pickerWrapper}>
                        <ChromePicker
                            width="auto"
                            style={{ fontFamily: 'Roboto' }}
                            disabled={disabled}
                            color={value}
                            disableAlpha
                            onChange={handleChange}
                        />
                    </div>
                </>
            )}
        </Card>
    );
}

ColorPicker.propTypes = {
    initColor: PropTypes.string,
    pickerVisible: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    cardStyles: PropTypes.string,
    cardClassName: PropTypes.arrayOf(PropTypes.string),
};

ColorPicker.defaultProps = {
    initColor: '',
    onChange: () => {},
    disabled: false,
    cardStyles: '',
    pickerVisible: false,
    cardClassName: ['colorPicker'],
};

export default ColorPicker;
