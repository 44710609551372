import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainAppWrapper from './containers/MainApp';
import Scenes from './containers/Scenes';
import Login from './containers/SignIn';
import SignUp from './containers/SignUp';
import Activation from './containers/Activation';
import Verification from './containers/Verification';
import Support from './containers/Support';
import ResetPassword from './containers/ResetPassword';
import ForgotPassword from './containers/ForgotPassword';
import { PrivateRoute } from './utils';

function App() {
    return (
        <div className="main-app-wrapper">
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/activation`} component={Activation} />
                <Route path={`${process.env.PUBLIC_URL}/verification`} component={Verification} />
                <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL}/reset-password`} component={ResetPassword} />
                <Route path={`${process.env.PUBLIC_URL}/forgot-password`} component={ForgotPassword} />
                <Route path={`${process.env.PUBLIC_URL}/signup`} component={SignUp} />
                <MainAppWrapper>
                    <Redirect from={`${process.env.PUBLIC_URL}/`} to={`${process.env.PUBLIC_URL}/scenes`} />
                    <PrivateRoute path={`${process.env.PUBLIC_URL}/scenes`} component={Scenes} />
                    <PrivateRoute path={`${process.env.PUBLIC_URL}/support`} component={Support} />
                </MainAppWrapper>
            </Switch>
        </div>
    );
}

export default App;
