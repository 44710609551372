/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearImagePath, getProtectedImage, removeImage, saveImage } from '../actions/ScenesActions';
import Image from '../ui-components/Image';

const CustomImage = (props) => {
    const { saveImage, getProtectedImage, clearImagePath, removeImage, ...otherProps } = props;
    return (
        <Image
            saveImage={saveImage}
            removeImage={removeImage}
            getProtectedImage={getProtectedImage}
            clearImagePath={clearImagePath}
            {...otherProps}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        imagePath: state.scene.imagePath || '',
    };
};

const mapDispatchToProps = {
    getProtectedImage,
    saveImage,
    clearImagePath,
    removeImage,
};

CustomImage.propTypes = {
    getProtectedImage: PropTypes.func.isRequired,
    saveImage: PropTypes.func.isRequired,
    clearImagePath: PropTypes.func.isRequired,
    removeImage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomImage);
