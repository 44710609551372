import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import DialogTitle from './DialogTitle';
import OnBoardingStepper from '../Stepper';

const { config } = window;

const BUTTON_BG = '#009999';
const BUTTON_BG_RED = '#cc0202';
const GREY_BG = '#777e86';
const WHITE_BG = '#FFFFFF';

const Styles = () => ({
    modalButton: {
        width: 120,
        height: 40,
        backgroundColor: BUTTON_BG,
        borderRadius: 20,
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.247058823529412)',
        fontWeight: 400,
        color: 'white',
        fontSize: 16,
        '&:hover': {
            backgroundColor: BUTTON_BG,
        },
    },
    modalRoot: {
        background: GREY_BG,
        maxWidth: 'none',
        width: '100vw',
        height: '100vh',
        color: WHITE_BG,
        borderRadius: 20,
    },
    modalContentRoot: {
        padding: '0px !important',
    },
    modalText: {
        color: WHITE_BG,
        fontSize: 20,
        fontWeight: 600,
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    redBtn: {
        backgroundColor: BUTTON_BG_RED,
        '&:hover': {
            backgroundColor: BUTTON_BG_RED,
        },
    },
});

const useStyles = makeStyles(Styles);

const OnBoarding = (props) => {
    const { isOpen, onClose } = props;
    const classes = useStyles();

    return (
        <Dialog open={isOpen} keepMounted onClose={onClose} classes={{ paper: classes.modalRoot }}>
            <DialogTitle isDark onClose={onClose}>
                {config.appName} quick start
            </DialogTitle>
            <DialogContent dividers className={classes.modalContentRoot}>
                <OnBoardingStepper onClose={onClose} />
            </DialogContent>
        </Dialog>
    );
};

OnBoarding.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default OnBoarding;
