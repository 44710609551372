import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

const useStyles = makeStyles(() => ({
    input: {
        background: '#e9eaeb',
        padding: 6,
        height: 28,
    },
}));

export default function CustomInput({ label, defaultValue, value, classname, name, onChange, ...otherProps }) {
    const [state, setState] = useState({ [name]: value });

    const handleChange = (e) => {
        const { value } = e.target;
        const newState = { [name]: value };

        setState(newState);
        onChange({ name, value });
    };

    const classes = useStyles();

    return (
        <DebounceInput
            debounceTimeout={500}
            element={Input}
            label={label}
            value={state[name]}
            name={name}
            classes={{ input: classes.input }}
            onChange={handleChange}
            {...otherProps}
        />
    );
}
