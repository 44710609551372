import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import App from './App';
import configureStore from './store/configureStore';
import defaultTheme from './themes';
import './global.css';

const store = configureStore();

const theme = createMuiTheme(defaultTheme);

ReactDOM.render(
    <Provider store={store}>
        <ToastProvider placement="bottom-right" autoDismiss>
            <HashRouter basename="/">
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </HashRouter>
        </ToastProvider>
    </Provider>,
    document.getElementById('root')
);
