import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';

import EditableInputWithColorPicker from '../../EditableInput';
import CheckBox from '../../CheckBox';
import Slider from '../../Slider';
import EditableInput from '../../EditableInput';
import DropDown from '../../DropDown';

import withSettingsWrapperHOC from '../InterfaceHOC';
import withDisabled from '../../../HOC/DisableHOC';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.GENERAL;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const GeneralContainer = (props) => {
    const { currentConfig, onChange } = props;
    const {
        uiMaxWidth,
        showGroups,
        columnView,
        flexibleButtons,
        defaultButtonImages,
        buttonHeight,
        buttonWidth,
        buttonOpacity,
        buttonDimmedOpacity,
        buttonColor,
        buttonBorderColor,
        buttonFontColor,
        buttonDimmedFontColor,
        buttonBorderWidth,
        buttonBorderRadius,
        buttonFontSize,
        showCredits,
    } = currentConfig;

    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };
    const handleChangeInput = (val, type) => {
        const correctVal = Number(val) || val;
        onChange(type, { name: correctVal });
    };

    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.uiMaxWidth}>
                    <Slider
                        val={uiMaxWidth}
                        onChange={handleUpdate}
                        label="Interface maximum width"
                        type="uiMaxWidth"
                        min={0}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.showGroups}>
                    <CheckBox val={showGroups} onChange={handleUpdate} type="showGroups" label="Show groups" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.columnView}>
                    <CheckBox val={columnView} onChange={handleUpdate} type="columnView" label="Column view" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.flexibleButtons}>
                    <CheckBox
                        val={flexibleButtons}
                        onChange={handleUpdate}
                        type="flexibleButtons"
                        label="Flexible Buttons"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.defaultButtonImages}>
                    <DropDown
                        data={config.defaultButtonImeges}
                        defaultValue={+defaultButtonImages}
                        label="Default button images"
                        type="defaultButtonImages"
                        onChange={handleChangeInput}
                    />
                </CardContent>
            </Card>
            <EditableInput
                label="Button height"
                title={buttonHeight}
                onChange={handleChangeInput}
                name="buttonHeight"
                disabled={disabledFields.buttonHeight}
                withDisable
            />
            <EditableInput
                label="Button width"
                title={buttonWidth}
                onChange={handleChangeInput}
                name="buttonWidth"
                disabled={disabledFields.buttonWidth}
                withDisable
            />
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.buttonOpacity}>
                    <Slider
                        val={buttonOpacity}
                        onChange={handleUpdate}
                        label="Button opacity"
                        type="buttonOpacity"
                        min={0}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.buttonDimmedOpacity}>
                    <Slider
                        val={buttonDimmedOpacity}
                        onChange={handleUpdate}
                        label="Dimmed button opacity"
                        type="buttonDimmedOpacity"
                        min={0}
                        max={100}
                    />
                </CardContent>
            </Card>
            <EditableInputWithColorPicker
                label="Button color"
                title={buttonColor}
                onChange={handleChangeInput}
                name="buttonColor"
                disabled={false}
                withColorPicker
            />
            <EditableInputWithColorPicker
                label="Border color"
                title={buttonBorderColor}
                onChange={handleChangeInput}
                name="buttonBorderColor"
                disabled={false}
                withColorPicker
            />
            <EditableInputWithColorPicker
                label="Button font color"
                title={buttonFontColor}
                onChange={handleChangeInput}
                name="buttonFontColor"
                disabled={false}
                withColorPicker
            />
            <EditableInputWithColorPicker
                label="Dimmed button font color"
                title={buttonDimmedFontColor}
                onChange={handleChangeInput}
                name="buttonDimmedFontColor"
                disabled={false}
                withColorPicker
            />
            <EditableInput
                label="Button border width"
                title={buttonBorderWidth}
                onChange={handleChangeInput}
                name="buttonBorderWidth"
                disabled={disabledFields.buttonBorderWidth}
                withDisable
            />
            <EditableInput
                label="Button border radius"
                title={buttonBorderRadius}
                onChange={handleChangeInput}
                name="buttonBorderRadius"
                disabled={disabledFields.buttonBorderRadius}
                withDisable
            />
            <EditableInput
                label="Button font size"
                title={buttonFontSize}
                onChange={handleChangeInput}
                name="buttonFontSize"
                disabled={disabledFields.buttonFontSize}
                withDisable
            />
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.showCredits}>
                    <CheckBox val={showCredits} onChange={handleUpdate} type="showCredits" label="Show credits" />
                </CardContent>
            </Card>
        </>
    );
};

GeneralContainer.propTypes = {
    //  : PropTypes.,
};

const CardContent = withDisabled(CardContentComponent);

const GenerslContsinerHOC = withSettingsWrapperHOC(GeneralContainer, 'GENERAL');

export default GenerslContsinerHOC;
