import {
    CREATE_NODE,
    UPDATE_NODE,
    REMOVE_NODE,
    GET_NODES_LIST,
    GET_NODE_ONE,
    REMOVE_ALL_NODE,
} from '../actions/NodeAction';

const initialState = {
    nodeList: [],
    currentNode: {},
    needSave: false,
};

export default function NodeReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REMOVE_ALL_NODE: {
            return {
                ...state,
                currentNode: {},
                nodeList: [],
            };
        }
        case GET_NODE_ONE:
        case UPDATE_NODE: {
            const { buttonNode } = payload;

            return {
                ...state,
                currentNode: buttonNode,
            };
        }

        case GET_NODES_LIST: {
            const { buttonNodes } = payload;

            return {
                ...state,
                currentNode: {},
                nodeList: buttonNodes ? [...buttonNodes] : [],
            };
        }

        case CREATE_NODE: {
            const { buttonNode } = payload;
            const oldList = state.nodeList;

            return {
                ...state,
                nodeList: [...oldList, buttonNode],
            };
        }

        case REMOVE_NODE: {
            const { buttonNode } = payload;
            const oldList = state.nodeList;

            return {
                ...state,
                currentNode: {},
                nodeList: oldList.filter((node) => node.buttonNodeId !== buttonNode[0].buttonNodeId),
            };
        }
        default:
            return state;
    }
}
