import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';
import CheckBox from '../../CheckBox';
import Slider from '../../Slider';
import RadioGroup from '../../RadioGroup';
import EditableInputWithColorPicker from '../../EditableInput';

import withSettingsWrapperHOC from '../SettingsHOC';
import withDisabled from '../../../HOC/DisableHOC';
import DropDown from '../../DropDown';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.ENVIRONMENT;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const EnvironmentContainer = (props) => {
    const { currentConfig, onChange } = props;
    const {
        enableBackgroundColor,
        backgroundColor,
        backgroundOpacity,
        defaultHDRIMap,
        HDRIMapRotation,
        showHDRIMap,
        highlightOutline,
        highlightColor,
    } = currentConfig;
    // const [state, setState] = useState({
    // 	enableBackgroundColor: true,
    // 	backgroundColor: '#fafafa',
    // 	backgroundOpacity: 100,
    // 	defaultHDRIMap: 0,
    // 	HDRIMapRotation: 50,
    // 	showHDRIMap: false,
    // 	highlightOutline: true,
    // 	highlightColor: '#fafafa',
    // });

    // useEffect(() => {
    // 	if (typeof enableBackgroundColor !== 'undefined') setState({ ...state, enableBackgroundColor });
    // }, [enableBackgroundColor]);

    // useEffect(() => {
    // 	if (currentConfig.backgroundColor) setState({ ...state, backgroundColor: currentConfig.backgroundColor });
    // }, [currentConfig.backgroundColor]);

    // useEffect(() => {
    // 	if (typeof backgroundOpacity !== 'undefined') {
    // 		setState({ ...state, backgroundOpacity });
    // 	}
    // }, [backgroundOpacity]);

    // useEffect(() => {
    // 	if (typeof defaultHDRIMap !== 'undefined') setState({ ...state, defaultHDRIMap });
    // }, [defaultHDRIMap]);

    // useEffect(() => {
    // 	if (typeof HDRIMapRotation !== 'undefined') setState({ ...state, HDRIMapRotation });
    // }, [HDRIMapRotation]);
    // useEffect(() => {
    // 	if (typeof showHDRIMap !== 'undefined') setState({ ...state, showHDRIMap });
    // }, [showHDRIMap]);
    // useEffect(() => {
    // 	if (typeof highlightOutline !== 'undefined') setState({ ...state, highlightOutline });
    // }, [highlightOutline]);
    // useEffect(() => {
    // 	if (typeof highlightColor !== 'undefined') setState({ ...state, highlightColor });
    // }, [highlightColor]);

    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };

    const handleChangeInput = (val, type) => {
        const correctVal = Number(val) || val;
        onChange(type, { name: correctVal });
    };

    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.enableBackgroundColor}>
                    <CheckBox
                        val={enableBackgroundColor}
                        onChange={handleUpdate}
                        type="enableBackgroundColor"
                        label="Enable background color"
                    />
                </CardContent>
            </Card>
            <EditableInputWithColorPicker
                label="Background color"
                title={backgroundColor}
                onChange={handleChangeInput}
                name="backgroundColor"
                disabled={false}
                withColorPicker
            />
            <EditableInputWithColorPicker
                label="Highlight color"
                title={highlightColor}
                onChange={handleChangeInput}
                name="highlightColor"
                disabled={false}
                withColorPicker
            />
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.backgroundOpacity}>
                    <Slider
                        val={backgroundOpacity}
                        onChange={handleUpdate}
                        label="Background opacity"
                        type="backgroundOpacity"
                        min={0}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.defaultHDRIMap}>
                    {/* <RadioGroup
                        label="Default HDRI map"
                        initValue={defaultHDRIMap}
                        type="defaultHDRIMap"
                        onChange={handleChangeInput}
                    /> */}
                    <DropDown
                        data={config.defaultHDRI}
                        label="Default HDRI map"
                        type="defaultHDRIMap"
                        defaultValue={+defaultHDRIMap}
                        onChange={handleChangeInput}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.HDRIMapRotation}>
                    <Slider
                        val={HDRIMapRotation}
                        onChange={handleUpdate}
                        label="HDRI map rotation"
                        type="HDRIMapRotation"
                        min={0}
                        max={360}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.showHDRIMap}>
                    <CheckBox val={showHDRIMap} onChange={handleUpdate} type="showHDRIMap" label="Show HDRI map" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.highlightOutline}>
                    <CheckBox
                        val={highlightOutline}
                        onChange={handleUpdate}
                        type="highlightOutline"
                        label="Highlight outline"
                    />
                </CardContent>
            </Card>
        </>
    );
};

EnvironmentContainer.propTypes = {
    //  : PropTypes.,
};

const CardContent = withDisabled(CardContentComponent);

const EnvironmrnContainerHOC = withSettingsWrapperHOC(EnvironmentContainer, 'ENVIRONMENT');

export default EnvironmrnContainerHOC;
