import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    createButton,
    createButtonGroup,
    getButtonsList,
    getOneButton,
    removeButton,
    updateButton,
    updateButtonForGroup,
    createButtonMaterial,
    removeButtonMaterial,
    updateButtonMaterial,
    createMaterialNode,
    removeMaterialNode,
    updateMaterialNode,
} from '../actions/ButtonAction';
import {
    createHighlight,
    getHighlightsList,
    getOneHighlight,
    removeHighlight,
    updateHighlight,
} from '../actions/HighlightAction';
import { createNode, getNodesList, getOneNode, removeNode, updateNode } from '../actions/NodeAction';
import {
    clearMessages,
    getConfig,
    getConfigById,
    getIframe,
    removeScene,
    saveConfig,
    saveImage,
    saveScene,
    updateScene,
} from '../actions/ScenesActions';
import ScenesPage from '../pages/Scenes';

import OnBoardingDialog from '../ui-components/Modals/OnBoardingDialog';

function Scenes(props) {
    const firstLogin = !localStorage.getItem('firstLogin');
    const [isOpen, setIsOpen] = useState(firstLogin);

    useEffect(() => {
        if (firstLogin) {
            setIsOpen(true);
            localStorage.setItem('firstLogin', 1);
        }
    }, [firstLogin]);

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <ScenesPage {...props} />
            <OnBoardingDialog isOpen={isOpen} onClose={handleCloseModal} />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        config: state.scene.config,
        buttonList: state.button.buttonList,
        buttonListMaterials: state.button.buttonList.filter((button) => button.buttonMaterial === null),
        buttonListGeometry: state.button.buttonList.filter((button) => button.buttonMaterial !== null),
        buttonGroupList: state.button.buttonGroupList,
        currentButton: state.button.currentButton,
        nodeList: state.node.nodeList,
        currentNode: state.node.currentNode,
        highlightList: state.highlight.highlightList,
        currentHighlight: state.highlight.currentHighlight,
        mockConfig: state.scene.mockConfig,
        interfaceConfig: state.scene.interfaceConfig,
        worldConfig: state.scene.worldConfig,
        successMsg: state.scene.successMsg,
        errorMsg: state.scene.errorMsg,
        preview: state.scene.preview,
    };
};

const mapDispatchToProps = {
    getConfig,
    getConfigById,
    saveScene,
    saveConfig,
    updateNode,
    createButton,
    createButtonGroup,
    getButtonsList,
    removeButton,
    removeScene,
    createHighlight,
    removeHighlight,
    updateHighlight,
    getOneButton,
    updateButton,
    createNode,
    getNodesList,
    getOneNode,
    removeNode,
    getOneHighlight,
    getHighlightsList,
    clearMessages,
    updateButtonForGroup,
    saveImage,
    getIframe,
    updateScene,
    createButtonMaterial,
    removeButtonMaterial,
    updateButtonMaterial,
    createMaterialNode,
    removeMaterialNode,
    updateMaterialNode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scenes);
