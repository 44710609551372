import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import PreviewContent from '../PreviewContent';

const useStyles = makeStyles((theme) => ({
    tabsRoot: {
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.35)',
        height: 50,
        background: theme.mainColors.tabDarkBg,
        display: 'flex',
        alignItems: 'center',
        padding: 20,
        fontWeight: 400,
        fontSize: 22,
        color: '#FFFFFF',
    },
}));

const FeedbackForm = ({ headerTitle, sendFeedback }) => {
    const classes = useStyles();

    const handleSave = async (value) => {
        console.log(value);

        const response = await sendFeedback({ subject: '', body: value });
        console.log('response', response);
    };

    return (
        <>
            <div className={classes.tabsRoot}>{headerTitle}</div>
            <PreviewContent mockConfig="" onSave={handleSave} type="string" />
        </>
    );
};
FeedbackForm.propTypes = {
    headerTitle: PropTypes.string,
};

export default FeedbackForm;
