import React, { useEffect } from 'react';

import { Button } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CustomCard from './CustomCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 10,
        background: '#54595f',
        height: '100%',
    },
    button: {
        margin: '20px 0 10px 0',
        minWidth: 270,
        borderRadius: 20,
    },
    inputRoot: {
        background: '#e9eaeb',
        padding: 10,
        height: '100%',
    },
    inputRootFeedback: {
        background: '#e9eaeb',
        padding: 10,
    },
    inputItem: {
        height: '100%',
    },
}));

const theme = createMuiTheme({
    palette: {
        primary: teal,
    },
});

export default function (props) {
    const { mockConfig, onSave, type } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState('');

    useEffect(() => {
        if (!type) setValue(JSON.stringify(mockConfig, null, 2));
    }, [mockConfig]);

    const handleSave = () => {
        try {
            const val = type ? value : JSON.parse(value);
            onSave(val);
        } catch (error) {
            console.log('Error', error);
        }
    };

    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
    };

    const cardClassNameArr = type ? ['textareaWrapperFeedback'] : ['textareaWrapper'];
    return (
        <div className={classes.root}>
            <CustomCard classNames={cardClassNameArr}>
                <TextField
                    label=""
                    multiline
                    rows={20}
                    onChange={handleChange}
                    fullWidth
                    value={value}
                    className={classes.inputRoot}
                    InputProps={{
                        className: classes.inputItem,
                    }}
                    inputProps={{
                        style: { height: '100%' },
                    }}
                    // defaultValue={JSON.stringify(mockConfig, null, 2)}
                />
                <ThemeProvider theme={theme}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </ThemeProvider>
            </CustomCard>
        </div>
    );
}
