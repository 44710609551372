import { Checkbox, makeStyles, FormControlLabel, createMuiTheme, ThemeProvider } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from './CustomCard';

const useStyles = makeStyles((theme) => ({
	label: {
		color: '#fff',
		justifyContent: 'space-between',
		width: '100%',
		margin: 0,
		height: 30,
	},
	checkbox: {
		color: theme.hoverColors.hoverListItemDark,
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#fff',
		},
	},
});

function CheckBox(props) {
	const classes = useStyles();
	const [value, setValue] = useState(false);

	const { val, label, type, onChange, disabled, cardStyles, cardClassName } = props;

	useEffect(() => {
		setValue(val);
	}, [val]);

	const handleChange = (e) => {
		setValue(e.target.checked);
		onChange({ value: e.target.checked }, type);
	};

	const labelStyless = clsx(classes.textfield, {
		[classes.label]: true,
	});

	return (
		<ThemeProvider theme={theme}>
			<Card classNames={cardClassName} style={cardStyles}>
				<FormControlLabel
					className={labelStyless}
					checked={value}
					disabled={disabled}
					onChange={handleChange}
					control={<Checkbox className={classes.checkbox} color="primary" />}
					label={label}
					labelPlacement="start"
				/>
			</Card>
		</ThemeProvider>
	);
}

CheckBox.propTypes = {
	val: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	cardStyles: PropTypes.string,
	type: PropTypes.string,
	cardClassName: PropTypes.arrayOf(PropTypes.string),
};

CheckBox.defaultProps = {
	label: '',
	onChange: () => {},
	type: '',
	disabled: false,
	cardStyles: '',
	val: false,
	cardClassName: ['CheckBox'],
};

export default CheckBox;
