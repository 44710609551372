import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { compose } from 'redux';

const NotificationWrapper = (WrappedComponent) => {
    return function Comp(props) {
        const { addToast } = useToasts();

        const { message, show, notificationType, dispatch, ...rest } = props;

        useEffect(() => {
            if (message && show) {
                addToast(message, { appearance: notificationType });
            }
        }, [message, show]);

        return <WrappedComponent {...rest} />;
    };
};

const mapStateToProps = (state) => {
    return {
        message: state.uiEffects.message || '',
        show: state.uiEffects.show,
        notificationType: state.uiEffects.notificationType || '',
    };
};

const composedWrapper = compose(connect(mapStateToProps, null), NotificationWrapper);

export default composedWrapper;
