export default (theme) => ({
    dialogRoot: {
        borderRadius: 20,
    },
    rootHeader: {
        margin: 0,
        padding: theme.spacing(2),
    },
    headerText: {
        color: '#666666',
        fontWeight: 400,
        fontSize: 20,
    },

    rootBody: {
        padding: theme.spacing(1),
        borderBottom: 0,
        overflowY: 'hidden',
        paddingBottom: 12,
    },
    paper: {
        minWidth: 500,
        position: 'relative',
    },
    error: {
        position: 'absolute',
        bottom: '-12px',
        fontSize: 14,
        color: 'red',
        left: 10,
    },
    errorName: {
        position: 'absolute',
        bottom: 'auto',
        fontSize: 14,
        color: 'red',
        left: 10,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '40ch',
    },
    button: {
        background: '#9a9fa5',
        borderRadius: 20,
        width: 120,
        height: 40,
        color: 'white',
        marginTop: 26,
        marginRight: 10,
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.35)',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            background: '#9a9fa5',
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
        },
    },
    addButton: {
        background: 'rgba(40, 198, 187, 1)',
        margin: 0,
        color: 'white !important',
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.35)',
        '&:hover': {
            background: 'rgba(40, 198, 187, 1)',
        },
    },
    whiteColor: {
        color: 'white',
    },
});
