import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import DeleteIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ButtonIcon from '../ButtonIcon';
import ListItemWithInput from './ListItemWithInput';
import withDisabled from '../../HOC/DisableHOC';
import styles from './styles';

const useStyles = makeStyles(styles);

export default (props) => {
    const {
        activeItemId,
        isDark,
        itemId,
        onRemove,
        onClickItem,
        name,
        isEditable,
        onChange,
        arrowIcon,
        onEdit,
        disabled,
    } = props;

    const classes = useStyles();
    const [id, setId] = useState('');
    const isActiveItem = id && activeItemId && id == activeItemId;

    useEffect(() => {
        if (itemId) setId(itemId);
    }, [itemId]);

    const handleRemove = () => onRemove && onRemove(id);
    const handleOnClick = () => onClickItem(id);
    const handleChange = (data) => onChange(data);
    const handleEdit = () => onEdit(id);

    const rootStyles = clsx(classes.listItemRoot, {
        [classes.whiteBg]: !isDark && !isActiveItem,
        [classes.darkBg]: isDark && !isActiveItem,
        [classes.activeItem]: isActiveItem,
        [classes.hoverListItemDark]: isDark,
        [classes.listItemSecpndaryAction]: typeof onEdit === 'function',
    });
    const textStyles = clsx({
        [classes.textField]: true,
        [classes.contrastForDarkColor]: isDark,
    });

    const isAdmin = localStorage.getItem('role') === 'ADMIN';
    const isDisabled = !isAdmin && disabled;

    return isEditable ? (
        <ListItemWithInput onChange={handleChange} itemId={id} onRemove={handleRemove} name={name} />
    ) : (
        <div style={{ position: 'relative' }}>
            <ListItemDisabledHOC button className={rootStyles} onClick={handleOnClick} disabled={isDisabled}>
                <ListItemText className={textStyles} primary={name} />
                <ListItemSecondaryAction className={classes.secondaryActionRoot}>
                    {typeof onEdit === 'function' && (
                        <ButtonIcon
                            onClick={handleEdit}
                            iconType="EditIcon"
                            classNames={['middleSize', 'withGreenIconHover']}
                        />
                    )}
                    <ButtonIcon
                        onClick={handleRemove}
                        iconType={arrowIcon ? 'ArrowRight' : 'DeleteIcon'}
                        classNames={['withMarginRight', !arrowIcon && 'withRedIconHover', 'deleteIcon']}
                    />
                </ListItemSecondaryAction>
            </ListItemDisabledHOC>
        </div>
    );
};

const ListItemDisabledHOC = withDisabled(ListItem);
