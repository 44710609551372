import Base from './Base';

class Node extends Base {
    async list(sceneId, buttonId) {
        const data = await this.apiClient.get(`button-node/${sceneId}/${buttonId}`);

        return data;
    }

    async one(sceneId, buttonId, nodeId) {
        const data = await this.apiClient.get(`button-node/${sceneId}/${buttonId}/${nodeId}`);

        return data;
    }

    async create(sceneId, buttonId, payload) {
        const data = await this.apiClient.post(`button-node/${sceneId}/${buttonId}`, payload);

        return data;
    }

    async remove(sceneId, buttonId, nodeId) {
        const data = await this.apiClient.delete(`button-node/${sceneId}/${buttonId}/${nodeId}`);

        return data;
    }

    async update(sceneId, buttonId, nodeId, payload) {
        const data = await this.apiClient.put(`button-node/${sceneId}/${buttonId}/${nodeId}`, payload);

        return data;
    }
}

export default Node;
