/* eslint-disable no-await-in-loop */
import { v4 as uuid } from 'uuid';
import api from '../apiSingleton';
import { removeAllNodes } from './NodeAction';
import { showNotification } from './UiEffectsActions';
import { generateButtonMaterailPayload } from '../utils';

export const GET_BUTTONS_LIST = 'GET_BUTTONS_LIST';
export const GET_BUTTONS_LIST_SUCCESS = 'GET_BUTTONS_LIST_SUCCESS';
export const GET_BUTTONS_LIST_FAILED = 'GET_BUTTONS_LIST_FAILED';

export const CREATE_BUTTON = 'CREATE_BUTTON';
export const CREATE_BUTTON_SUCCESS = 'CREATE_BUTTON_SUCCESS';
export const CREATE_BUTTON_FAILED = 'CREATE_BUTTON_FAILED';

export const GET_BUTTON_ONE = 'GET_BUTTON_ONE';
export const UPDATE_BUTTON = 'UPDATE_BUTTON';
export const REMOVE_BUTTON = 'REMOVE_BUTTON';
export const REMOVE_ALL_BUTTON = 'REMOVE_ALL_BUTTON';

export const CREATE_BUTTON_GROUP = 'CREATE_BUTTON_GROUP';
export const CREATE_BUTTON_GROUP_SUCCESS = 'CREATE_BUTTON_GROUP_SUCCESS';
export const CREATE_BUTTON_GROUP_FAILED = 'CREATE_BUTTON_GROUP_FAILED';
export const REMOVE_BUTTON_GROUP = 'REMOVE_BUTTON_GROUP';
export const UPDATE_BUTTON_FOR_GROUP = 'UPDATE_BUTTON_FOR_GROUP';

export const CREATE_BUTTON_MATERAIL = 'CREATE_BUTTON_MATERAIL';
export const REMOVE_BUTTON_MATERAIL = 'REMOVE_BUTTON_MATERAIL';
export const UPDATE_BUTTON_MATERAIL = 'UPDATE_BUTTON_MATERAIL';

export function getButtonsList(sceneId) {
    return async (dispatch) => {
        dispatch({ type: GET_BUTTONS_LIST });
        const data = await api.button.list(sceneId);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_BUTTONS_LIST_SUCCESS,
                payload: data,
            });
        }

        return data;
    };
}

export function getOneButton(sceneId, buttonId) {
    return async (dispatch) => {
        const data = await api.button.one(sceneId, buttonId);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_BUTTON_ONE,
                payload: data,
            });
        }
    };
}

export function createButtonGroup(sceneId, type) {
    return async (dispatch, getState) => {
        const state = getState();
        const groupsLength = (state.button.buttonGroupList && state.button.buttonGroupList.length) || 0;
        dispatch({ type: CREATE_BUTTON_GROUP });

        const payload = {
            buttonName: `Group ${groupsLength + 1}`,
            buttonImage: null,
            buttonHover: null,
            buttonClicked: null,
            buttonNodes: [],
            highlights: [],
            isGroup: true,
            buttonMaterial: null,
            groupType: type,
            buttonGroupId: null,
        };
        const data = await api.button.create(sceneId, payload);
        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: CREATE_BUTTON_GROUP_SUCCESS,
                payload: data,
            });
        }

        return data;
    };
}

export function createButton(sceneId, buttonGroupId = null) {
    return async (dispatch, getState) => {
        const state = getState();
        const currentGroup = state.button.buttonGroupList.find((group) => group.buttonId === buttonGroupId) || {};
        const buttonsLength = (currentGroup.buttons && currentGroup.buttons.length) || 0;
        dispatch({ type: CREATE_BUTTON });

        let payload = {
            buttonName: `Button ${buttonsLength + 1}`,
            buttonImage: null,
            buttonHover: null,
            buttonClicked: null,
            buttonImagePath: null,
            buttonHoverPath: null,
            buttonClickedPath: null,
            isGroup: false,
            buttonNodes: [],
            highlights: [],
            buttonGroupId,
            buttonMaterial: null,
        };

        if (buttonGroupId && currentGroup.groupType === 'MATERIALS') {
            const defaultButtonMaterial = [
                {
                    materialID: uuid(),
                    materialName: 'Material 1',
                    materialNode: 'mat1',
                    changeNodes: [{ id: uuid(), name: 'Node 1' }],
                },
            ];
            payload = { ...payload, buttonMaterial: defaultButtonMaterial };
        }

        const data = await api.button.create(sceneId, payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: CREATE_BUTTON_SUCCESS,
                payload: data,
            });
        }
    };
}

export function createButtonMaterial(sceneId) {
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial, buttonId } = currentButton;
        buttonMaterial.push(generateButtonMaterailPayload(buttonMaterial.length + 1));

        const data = await api.button.update(sceneId, buttonId, { buttonMaterial });

        const { success, message, button } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: CREATE_BUTTON_MATERAIL,
                payload: { ...data, button },
            });
        }
    };
}

export function removeButtonMaterial(sceneId, itemId) {
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial, buttonId } = currentButton;
        const newButtonMaterial = buttonMaterial.filter((item) => item.materialID !== itemId);

        const data = await api.button.update(sceneId, buttonId, { buttonMaterial: newButtonMaterial });

        const { success, message, button } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: REMOVE_BUTTON_MATERAIL,
                payload: { ...data, button },
            });
        }
    };
}

export function updateButtonMaterial(sceneId, itemId, payload) {
    // {materialNode: 'New material name'}
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial, buttonId } = currentButton;
        let currentMaterialItem = buttonMaterial.find((item) => item.materialID === itemId);

        currentMaterialItem = { ...currentMaterialItem, ...payload };

        const newButtonMaterial = buttonMaterial.map((item) =>
            item.materialID === itemId ? currentMaterialItem : item
        );
        const data = await api.button.update(sceneId, buttonId, {
            buttonMaterial: newButtonMaterial,
        });

        const { success, message, button } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: UPDATE_BUTTON_MATERAIL,
                payload: { ...data, button },
            });
        }
    };
}

export function createMaterialNode(sceneId, itemId) {
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial } = currentButton;
        const { changeNodes } = buttonMaterial.find((item) => item.materialID === itemId);

        changeNodes.push({ id: uuid(), name: `Node ${changeNodes.length + 1}` });
        dispatch(updateButtonMaterial(sceneId, itemId, { changeNodes }));
    };
}

export function removeMaterialNode(sceneId, itemId, nodeId) {
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial } = currentButton;
        let { changeNodes } = buttonMaterial.find((item) => item.materialID === itemId);
        changeNodes = changeNodes.filter((item) => item.id !== nodeId);

        dispatch(updateButtonMaterial(sceneId, itemId, { changeNodes }));
    };
}

export function updateMaterialNode(sceneId, itemId, nodeId, name) {
    // payload => // {name: 'New node name'}
    return async (dispatch, getState) => {
        const state = getState();

        const { currentButton } = state.button;
        const { buttonMaterial } = currentButton;
        let { changeNodes } = buttonMaterial.find((item) => item.materialID === itemId);
        changeNodes = changeNodes.map((item) => (item.id === nodeId ? { ...item, name } : item));

        dispatch(updateButtonMaterial(sceneId, itemId, { changeNodes }));
    };
}

export function updateButton(sceneId, buttonId, payload) {
    return async (dispatch) => {
        const data = await api.button.update(sceneId, buttonId, payload);

        const { success, message, button } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: UPDATE_BUTTON,
                payload: { ...data, button },
            });
        }
    };
}

export function updateButtonForGroup(sceneId, buttonId, payload) {
    return async (dispatch, getState) => {
        const state = getState();
        const data = await api.button.update(sceneId, buttonId, payload);

        const { success, message, button } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: UPDATE_BUTTON_FOR_GROUP,
                payload: { ...data, button, currentGroup: state.button.currentButton },
            });
        }
    };
}

function unlinkAllButtons(sceneId, groupId) {
    return async (dispatch, getState) => {
        const state = getState();
        const group = state.button.buttonGroupList.find((groupItem) => groupItem.buttonId === groupId);
        const groupButtons = (group && group.buttons) || [];
        const idsList = groupButtons.map((button) => button.buttonId) || [];

        for (let i = 0; i < idsList.length; i += 1) {
            const data = await api.button.remove(sceneId, idsList[i]);
            const { success, message } = data;

            if (success === false) dispatch(showNotification(false, message || 'Server error'));
        }
    };
}

export function removeButton(sceneId, buttonId, type = null) {
    return async (dispatch) => {
        const data = await api.button.remove(sceneId, buttonId);
        const { success, message } = data;

        if (success && type && type === 'GROUP') {
            dispatch(unlinkAllButtons(sceneId, buttonId));
            dispatch({
                type: REMOVE_BUTTON_GROUP,
                payload: data,
            });
            dispatch(showNotification(success, 'Group deleted'));
        } else if (success) {
            dispatch(removeAllNodes());
            dispatch({
                type: REMOVE_BUTTON,
                payload: data,
            });
            dispatch(showNotification(success, 'Button deleted'));
        }

        if (success === false) dispatch(showNotification(false, message || 'Server error'));
    };
}

export function removeAllButtons() {
    return (dispatch) => {
        dispatch({
            type: REMOVE_ALL_BUTTON,
        });
    };
}
