import { filterConfigByType, prepareConfig, normilizeObjToArr } from '../utils';
import defaultConfig from '../ui-components/Settings/initState';
import { saveConfig } from './ScenesActions';

export const GET_CONFIG_BY_TYPE = 'GET_CONFIG_BY_TYPE';

export function getConfigByType(type) {
    return (dispatch, getState) => {
        const state = getState();
        const oldWorldConfig = state.scene.worldConfig;
        const oldCurentConfig = state.settings.currentConfig;
        const mergedConfig =
            type !== 'CUSTOM' ? { ...defaultConfig, ...oldWorldConfig, ...oldCurentConfig } : oldWorldConfig;
        const filteredConfig = filterConfigByType(mergedConfig, type);
        const normalizedState =
            type === 'GENERAL' || type === 'LIGHTING' ? normilizeObjToArr(filteredConfig) : filteredConfig;

        dispatch({
            type: GET_CONFIG_BY_TYPE,
            payload: { type, config: normalizedState },
        });

        return normalizedState;
    };
}

export const SET_CONFIG_FOR_TAB = 'SET_CONFIG_FOR_TAB';

export function setConfigForTab(payload) {
    return (dispatch) => {
        console.log('SET_CONFIG', payload);
        dispatch({
            type: SET_CONFIG_FOR_TAB,
            payload,
        });
    };
}

export const SAVE_SETTING_CONFIG = 'SAVE_SETTING_CONFIG';

export function saveSettingsConfig({ sceneId, pageType }) {
    return (dispatch, getState) => {
        const state = getState();
        const oldWorldConfig = JSON.parse(JSON.stringify(state.scene.worldConfig));
        const oldCurentConfig = JSON.parse(JSON.stringify(state.settings.currentConfig));
        const mergedConfig = { ...oldWorldConfig, ...oldCurentConfig };
        const normalizedState =
            pageType === 'GENERAL' || pageType === 'LIGHTING' ? prepareConfig(mergedConfig) : mergedConfig;
        console.log('normalizedState', normalizedState, pageType, mergedConfig);

        dispatch(saveConfig(sceneId, normalizedState, 'MOCK'));
    };
}
