import ApiClient from './ApiClient';
import Auth from './Auth';
import Scene from './Scene';
import Button from './Button';
import Node from './Node';
import Highlight from './Highlight';

export default function ({ apiPrefix } = {}) {
    if (!apiPrefix) {
        throw new Error('[apiPrefix] required');
    }

    const api = new ApiClient({ prefix: apiPrefix });

    return {
        apiClient: api,
        auth: new Auth({ apiClient: api }),
        scene: new Scene({ apiClient: api }),
        button: new Button({ apiClient: api }),
        highlight: new Highlight({ apiClient: api }),
        node: new Node({ apiClient: api }),
    };
}
