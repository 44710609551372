import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/CollectionsOutlined';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import HelpIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import clsx from 'clsx';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../ui-components/Header';

const { appName } = window.config;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
        },
        drawer: {
            width: theme.drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: theme.drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.menuClosedWidth,
            marginTop: 11,
            [theme.breakpoints.up('sm')]: {
                width: theme.menuClosedWidth,
            },
        },
        link: {
            textDecoration: 'none',
            textTransform: 'capitalize',
            color: 'black',
        },
        list: {
            padding: 0,
            height: 50,
        },
        menuItem: {
            height: '100%',
            '&:hover': {
                background: theme.hoverColors.menuItemBg,
            },
        },
    };
});

export default function MainAppWrapper(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const history = useHistory();

    const { onLogout, isLoading } = props;

    function handleLogout() {
        onLogout();
        history.push('/');
    }

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        setOpen(false);
    }

    function handleClick() {
        localStorage.removeItem('firstLogin');
    }

    const generateAppName = () => {
        return history.location.pathname === '/support' ? `${appName} Support` : appName;
    };

    return (
        <div className={classes.root}>
            <Header onDrawerOpen={handleDrawerOpen} appName={generateAppName()} isOpen={open} isLoading={isLoading} />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
                open={open}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List className={classes.list}>
                    {['scenes'].map((text) => (
                        <Link to={text} key={text} className={classes.link}>
                            <ListItem button className={classes.menuItem}>
                                <ListItemIcon>
                                    <InboxIcon />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
                <List className={classes.list}>
                    {['support'].map((text) => (
                        <Link to={text} key={text} className={classes.link}>
                            <ListItem button className={classes.menuItem}>
                                <ListItemIcon>
                                    <HelpIcon />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <List className={classes.list}>
                    {['Instruction'].map((text) => (
                        <Link to="scenes" key={text} className={classes.link}>
                            <ListItem button onClick={handleClick} className={classes.menuItem}>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <List className={classes.list}>
                    {['Logout'].map((text) => (
                        <ListItem button key={text} onClick={handleLogout} className={classes.menuItem}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            {props.children}
        </div>
    );
}
