import { AppBar, ListItemSecondaryAction, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import ButtonIcon from '../ButtonIcon';
import styles from './styles';

const useStyles = makeStyles(styles);

export default (props) => {
    const classes = useStyles();

    const {
        title,
        onCreate,
        onCreateAdditional,
        isDisabledCreateButton,
        isDisabledAdditionalBtn,
        isNeedCreateGroup,
        showCreateGroupBtn,
        isDark,
        isEditable,
    } = props;

    const headerStyles = clsx(classes.header, {
        [classes.editableRootHeader]: isEditable,
        [classes.darkRootHeader]: isDark && !isEditable,
    });

    const textStyles = clsx(classes.text, {
        [classes.editableHeaderText]: isEditable,
    });

    return (
        <AppBar color="default" position="static" style={{ boxShadow: 'none' }}>
            <Toolbar variant="dense" className={headerStyles}>
                <Typography className={textStyles} variant="h5" gutterBottom>
                    {title}
                </Typography>
                <ListItemSecondaryAction className={classes.buttonsWrapper}>
                    {onCreate && (
                        <ButtonIcon
                            disabled={isDisabledCreateButton}
                            onClick={onCreate}
                            iconType="AddIcon"
                            classNames={[
                                'withMarginRight',
                                !isEditable && 'withHover',
                                isDark && !isEditable && 'withColorLight',
                                isDark && !isEditable && 'withHoverLight',
                                isEditable && 'isEditableListColor',
                                isEditable && 'withGreenIconHover',
                            ]}
                        />
                    )}
                    {isNeedCreateGroup && showCreateGroupBtn && (
                        <ButtonIcon
                            disabled={isDisabledAdditionalBtn}
                            onClick={onCreateAdditional}
                            iconType="FolderIcon"
                            classNames={['withMarginRight', 'withHoverLight', 'withColorLight', 'onSaveIcon']}
                        />
                    )}
                </ListItemSecondaryAction>
            </Toolbar>
        </AppBar>
    );
};
