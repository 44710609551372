export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';

export function showNotification(isSuccess, message) {
    return (dispatch) => {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: { message, isSuccess, show: true },
        });
        dispatch({
            type: HIDE_NOTIFICATION,
            payload: { message: '', show: false },
        });
    };
}
