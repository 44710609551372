import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import DialogTitle from './DialogTitle';

const BUTTON_BG = 'transparent';
const BUTTON_BG_RED = '#009999';
const GREY_BG = '#777e86';
const WHITE_BG = '#FFFFFF';
const { upgradePlanLink } = window.config;

const Styles = () => ({
    modalButton: {
        width: 120,
        height: 40,
        borderRadius: 20,
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.247058823529412)',
        fontWeight: 400,
        color: 'white',
        fontSize: 16,
        '&:hover': {
            backgroundColor: BUTTON_BG,
        },
    },
    closeBtn: {
        border: '1px solid #cccdcf',
        minWidth: 100,
        width: 120,
        height: 40,
        fontSize: 16,
        fontWeight: 400,
        borderRadius: 20,
        color: '#cccdcf',
        backgroundColor: BUTTON_BG,
    },
    modalRoot: {
        background: GREY_BG,
        maxWidth: 344,
        color: WHITE_BG,
        borderRadius: 20,
    },
    modalContentRoot: {
        padding: '30px !important',
    },
    modalText: {
        textAlign: 'center',
        color: WHITE_BG,
        fontSize: 20,
        fontWeight: 400,
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    redBtn: {
        backgroundColor: BUTTON_BG_RED,
        '&:hover': {
            backgroundColor: BUTTON_BG_RED,
        },
    },
    buttonsWrapper: {
        justifyContent: 'space-between',
    },
});
const useStyles = makeStyles(Styles);

const DeleteDialog = (props) => {
    const { isOpen, onClose } = props;
    const classes = useStyles();

    return (
        <Dialog open={isOpen} keepMounted onClose={onClose} classes={{ paper: classes.modalRoot }}>
            <DialogTitle isDark onClose={onClose}>
                Free limit reached
            </DialogTitle>
            <DialogContent dividers className={classes.modalContentRoot}>
                <DialogContentText className={classes.modalText}>
                    Your Free plan scenes limit has been reached. Upgrade to a paid plan to add more scenes
                </DialogContentText>
                <DialogActions className={classes.buttonsWrapper}>
                    <Button onClick={onClose} className={classes.closeBtn}>
                        CANCEL
                    </Button>
                    <Button
                        href={upgradePlanLink}
                        target="_blank"
                        className={`${classes.modalButton} ${classes.redBtn}`}
                    >
                        UPGRADE
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    pageName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default DeleteDialog;
