import api from '../apiSingleton';
import { onLogout } from './AuthActions';
import { removeAllButtons } from './ButtonAction';
import { removeAllHighlights } from './HighlightAction';
import { removeAllNodes } from './NodeAction';
import { showNotification } from './UiEffectsActions';

export const SAVE_SCENE = 'SAVE_SCENE';
export const SAVE_SCENE_SUCCESS = 'SAVE_SCENE_SUCCESS';
export const SAVE_SCENE_FAILURE = 'SAVE_SCENE_FAILURE';

export const UPDATE_SCENE = 'UPDATE_SCENE';
export const UPDATE_SCENE_SUCCESS = 'UPDATE_SCENE_SUCCESS';

export const SAVE_CONFIG = 'SAVE_CONFIG';
export const GET_SCENES = 'GET_SCENES';
export const REMOVE_SCENE = 'REMOVE_SCENE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const GET_PROTECTED_IMAGE = 'GET_PROTECTED_IMAGE';
export const CLEAR_IMAGE_PATH = 'CLEAR_IMAGE_PATH';
export const SAVE_IMAGE = 'SAVE_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const GET_IFRAME = 'GET_IFRAME';

export function updateScene(sceneId, payload) {
    return async (dispatch) => {
        dispatch({ type: UPDATE_SCENE });
        const data = await api.scene.updateScene(sceneId, payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error: failure update scene'));
        } else {
            dispatch({
                type: UPDATE_SCENE_SUCCESS,
                payload: {
                    success,
                    ...data,
                },
            });
        }
    };
}

export function getIframe(sceneId) {
    return async (dispatch) => {
        const data = await api.scene.getIframe(sceneId);

        const { success, message, preview } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error: failure getIframe'));
        } else {
            dispatch({
                type: GET_IFRAME,
                payload: {
                    success,
                    preview,
                },
            });
        }
    };
}

export function clearImagePath() {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_IMAGE_PATH,
            payload: '',
        });
    };
}

export function removeImage(path) {
    return async (dispatch) => {
        const url = path.replace('/api/', '');

        const data = await api.scene.removeImage(url);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch(showNotification(true, 'Image deleted'));

            dispatch({
                type: REMOVE_IMAGE,
                payload: { success, path },
            });
        }
    };
}

export function saveImage(id, payload) {
    return async (dispatch) => {
        const { formDataFields, name } = payload;

        const data = await api.scene.sendImage(id, formDataFields);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: SAVE_IMAGE,
                payload: { ...data, name },
            });
        }
    };
}

export function getProtectedImage(path) {
    return async () => {
        const url = path.replace('/api/', '');
        return api.scene.getProtectedImage(url);
    };
}

export function saveScene(payload) {
    return async (dispatch) => {
        dispatch({ type: SAVE_SCENE });
        const data = await api.scene.sendFile(payload);

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
            dispatch({
                type: SAVE_SCENE_FAILURE,
            });
        } else {
            dispatch({
                type: SAVE_SCENE_SUCCESS,
                payload: data,
            });
        }
    };
}

export function saveConfig(sceneId, payload) {
    return async (dispatch, getState) => {
        const state = getState();
        const fullConfig = state.scene.mockConfig;
        const mergedConfig = { ...fullConfig, ...payload };

        console.log('mergedConfig', mergedConfig, payload);
        const data = await api.scene.sendConfig(sceneId, {
            config: mergedConfig,
        });

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: SAVE_CONFIG,
                payload: { ...data, sceneId, mockConfig: mergedConfig },
            });
        }
    };
}

export function getConfig() {
    return async (dispatch) => {
        const data = await api.scene.getConfig();

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
            if (data.status && data.status === 401) dispatch(onLogout());
        } else {
            dispatch({
                type: GET_SCENES,
                payload: data,
            });
        }
    };
}

/**
 *
 * @param {String} sceneId
 */
export function removeScene(sceneId) {
    return async (dispatch) => {
        const data = await api.scene.removeScene(sceneId);

        dispatch(removeAllButtons());
        dispatch(removeAllHighlights());
        dispatch(removeAllNodes());

        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: REMOVE_SCENE,
                payload: { ...data, sceneId },
            });
        }
    };
}

export function clearMessages() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_MESSAGES,
        });
    };
}

export const GET_ONE_CONFIG = 'GET_ONE_CONFIG';

export function getConfigById(id) {
    return async (dispatch) => {
        const data = await api.scene.getConfigById(id);
        const { success, message } = data;
        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: GET_ONE_CONFIG,
                payload: data,
            });
        }
    };
}
