import React, { useEffect } from 'react';
import Iframe from 'react-iframe';
import CustomCard from '../CustomCard';
import EditableInput from '../EditableInput';

function Preview(props) {
    const { url, embededCode } = props;

    return (
        <div style={{ padding: 10 }}>
            <CustomCard styles={{ height: 'calc(100vh - 240px)' }}>
                <Iframe
                    url={url}
                    width="100%"
                    height="100%"
                    id="myId"
                    frameBorder="0"
                    className="myClassname"
                    display="initial"
                    position="relative"
                />
            </CustomCard>
            <EditableInput
                title={embededCode}
                label="Embed code"
                disabled
                cardStyles={{ minHeight: 164 }}
                labelWithButtons
                url={url}
            />
        </div>
    );
}

function areEqual(prevProps, nextProps) {
    return prevProps.url === nextProps.url;
}

export default React.memo(Preview, areEqual);
