import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Close';
import FolderIcon from '@material-ui/icons/FolderOpen';
import SaveIcon from '@material-ui/icons/Save';
import ArrowRight from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    icon: {
        color: '#656565',
        cursor: 'pointer',
        borderRadius: '100%',
        width: 40,
        height: 40,
        padding: 5,
        backgroundColor: 'transparent',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    colorPickerIcon: {
        position: 'absolute',
        right: 0,
        transform: 'rotate(90deg)',
    },
    modalClose: {
        position: 'absolute',
        top: 12,
        right: 16,
        color: theme.mainColors.iconsMainColor,
    },
    withWhiteColor: {
        color: theme.mainColors.white,
    },
    disabled: {
        pointerEvents: 'none',
    },
    withMarginRight: {
        marginRight: 5,
    },
    middleSize: {
        padding: 10,
    },
    onSaveIcon: {
        padding: 8,
    },
    deleteIcon: {
        padding: 7,
    },
    isEditableListColor: {
        color: theme.hoverColors.hoverListItemDark,
    },
    isEditableRemove: {
        position: 'absolute',
        right: 5,
    },
    withHover: {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    fullWidthAdd: {
        width: '100%',
    },
    withHoverLight: {
        '&:hover': {
            backgroundColor: '#fffefe1f',
        },
    },
    withColorLight: {
        color: theme.mainColors.contrastColorForDark,
    },
    withRedIconHover: {
        '&:hover': {
            color: theme.hoverColors.removeIconBg,
        },
    },
    withGreenIconHover: {
        '&:hover': {
            color: theme.hoverColors.addIconBg,
        },
    },
}));

const ICONS = {
    AddIcon,
    FolderIcon,
    DeleteIcon,
    ArrowRight,
    EditIcon,
    SaveIcon,
};

export default (props) => {
    const { onClick, styles, classNames = [], iconType = 'AddIcon', disabled, ...other } = props;

    const classes = useStyles();
    const Icon = ICONS[iconType];

    const iconStyles = clsx(
        classes.icon,
        { [classes.disabled]: disabled },
        classNames.map((key) => classes[key])
    );

    const handleClick = (e) => onClick(e);

    return <Icon className={iconStyles} style={styles} onClick={handleClick} {...other} />;
};
