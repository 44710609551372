import { combineReducers } from 'redux';
import auth from './Auth';
import scene from './Scene';
import node from './Node';
import button from './Button';
import highlight from './Highlight';
import settings from './Settings';
import interfaceReducer from './Interface';
import uiEffects from './UiEffects';

export default combineReducers({
    auth,
    scene,
    node,
    button,
    highlight,
    settings,
    interface: interfaceReducer,
    uiEffects,
});
