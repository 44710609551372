/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CustomList from '../List';

import withNotification from '../../HOC/withNotificationHOC';

import General from './General/GeneralContainer';
import Lighting from './Lighting/LightingContainer';
import PostProcessing from './PostProcessing/PostProcessing';
import Custom from './Custom/CustomContainer';
import Camera from './Camera/CameraContainer';
import Environment from './Environment/EnvironmentContainer';

const { config } = window;
const { SETTING_TABS } = config;

function SettingsTab(props) {
    const { sceneId } = props;
    const [activeTab, setActiveTab] = useState('GENERAL');

    useEffect(() => {
        setActiveTab('GENERAL');
    }, [sceneId]);

    const handleClickListItem = (id) => {
        setActiveTab(id);
    };

    return (
        <>
            <CustomList
                title="Scene settings"
                onItemClick={handleClickListItem}
                onCreate={null}
                onRemoveItem={null}
                data={SETTING_TABS}
                idKey="id"
                nameKey="name"
                activeItemId={activeTab}
                isDisabledCreateButton
                isDisabledCreateGroup
                arrowIcon
                isDark
                wrapperStyle={{
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',
                    width: '33.33%',
                    zIndex: 2,
                }}
            />
            <General sceneId={sceneId} activeTab={activeTab} />
            <Lighting sceneId={sceneId} activeTab={activeTab} />
            <PostProcessing sceneId={sceneId} activeTab={activeTab} />
            <Custom sceneId={sceneId} activeTab={activeTab} />
            <Camera sceneId={sceneId} activeTab={activeTab} />
            <Environment sceneId={sceneId} activeTab={activeTab} />
        </>
    );
}

export default withNotification(SettingsTab);
