import React from 'react';
import PropTypes from 'prop-types';
import ButtonDetailsList from './ButtonDetailsList';
import EditableInput from './EditableInput';

const MaterilsWithNodesList = ({
    isDisabledAddNode = false,
    nodesList,
    label,
    title,
    id,
    onRemove,
    onUpdateTitle,
    onCreateMaterialNode,
    onRemoveMaterialNode,
    onUpdateMaterialNode,
}) => {
    const handleChangeMaterialTitle = (value) => {
        onUpdateTitle(id, value);
    };
    const handleAdd = () => {
        onCreateMaterialNode(id);
    };
    const handleRemove = () => {
        onRemove(id);
    };
    const handleRemoveNode = (nodeId) => {
        onRemoveMaterialNode(id, nodeId);
    };
    const handleUpdate = ({ name, value }) => {
        onUpdateMaterialNode(id, name, value);
    };

    return (
        <>
            <EditableInput
                label={label}
                title={title}
                onChange={handleChangeMaterialTitle}
                disabled={false}
                withRemove
                onRemove={handleRemove}
            />
            <ButtonDetailsList
                title="Change nodes"
                type="NODES"
                isDisabled={isDisabledAddNode}
                data={nodesList}
                onAdd={handleAdd}
                onRemove={handleRemoveNode}
                onChange={handleUpdate}
                idKey="id"
                nameKey="name"
            />
        </>
    );
};

MaterilsWithNodesList.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isDisabledAddNode: PropTypes.bool,
    nodesList: PropTypes.array,
};

export default MaterilsWithNodesList;
