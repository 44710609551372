export default {
    allowedHost: [],
    alwaysVisible: [],
    alwaysHidden: [],
    showStats: false,
    preventDefault: false,
    lightsWithShadows: [],
    noShadowObjects: [],
    shadowsEnabled: false,
    shadowQuality: 0,
    highlightOutline: false,
    highlightColor: '',
    defaultHDRIMap: 1,
    showHDRIMap: false,
    HDRIMapRotation: 0,
    allowPanning: true,
    zoomButtons: true,
    zoomPinchEnabled: false,
    enableColorProcessing: true,
    enablePostProcessing: true,
    bloomWeight: 0.25,
    bloomThreshold: 0.75,
    zoomButtonsPrecision: 10,
    fieldOfView: 60,
    limitRotationAngle: 90,
    minZoom: 5,
    maxZoom: 20,
    initx: 5,
    inity: 5,
    initz: 5,
    targetx: 0,
    targety: 0,
    targetz: 0,
    zoomPinchPrecision: 3,
    zoomWheelPrecision: 16,
    nodesFadeTime: 500,
    pictureFadeTime: 300,
    bloomKernel: 64,
    globalHue: 0,
    globalDensit: 0,
    globalExposure: 0,
    globalSaturation: 20,
    highlightsHue: 0,
    highlightsDensit: 0,
    highlightsExposure: 0,
    highlightsSaturation: 0,
    midtonesHue: 0,
    midtonesDensit: 0,
    midtonesExposure: 0,
    midtonesSaturation: 0,
    shadowsHue: 0,
    shadowsDensit: 80,
    shadowsExposure: 0,
    shadowsSaturation: 0,
    enableBackgroundColor: true,
    backgroundColor: '#fafafa',
    backgroundOpacity: 100,
};
