import React from 'react';
import { withStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';

import { getConfigByType, setConfigForTab, saveSettingsConfig } from '../../actions/InterfaceActions';

function settingsHOC(WrappedComponent, pageType) {
    const classes = () => {
        return {
            detailsRoot: {
                width: '66.66%',
                padding: '0 10px',
                position: 'relative',
            },
            button: {
                margin: '10px auto',
                minWidth: '97%',
                borderRadius: 20,
                position: 'absolute',
                bottom: 60,
            },
        };
    };

    const theme = createMuiTheme({
        palette: {
            primary: teal,
        },
    });

    class HOC extends React.Component {
        constructor(props) {
            super(props);
            this.handleSendConfig = this.handleSendConfig.bind(this);
            this.handleChange = this.handleChange.bind(this);
            this.handleRemove = this.handleRemove.bind(this);
            this.handleAdd = this.handleAdd.bind(this);
            this.state = {};
        }

        componentDidMount() {
            if (pageType === this.props.activeTab) {
                this.props.getConfigByType(pageType);
            }
        }

        componentWillReceiveProps(nextProps) {
            if (this.props.activeTab !== nextProps.activeTab) {
                this.props.getConfigByType(nextProps.activeTab);
            }
            if (this.props.sceneId !== nextProps.sceneId) {
                this.props.getConfigByType('GENERAL');
            }
        }

        handleSendConfig() {
            this.props.saveSettingsConfig({ sceneId: this.props.sceneId, pageType });
        }

        handleChange(type, value) {
            let result = value;

            if (type !== 'CUSTOM') {
                const prevState = this.props.getConfigByType(pageType);
                const oldState = JSON.parse(JSON.stringify(prevState[type]));
                const newStateByType = Array.isArray(oldState)
                    ? oldState.map((item) => (item.id === value.id ? value : item))
                    : value.name;

                result = { [type]: newStateByType };
            }

            this.props.setConfigForTab(result);
        }

        handleAdd(type) {
            const config = this.props.getConfigByType(pageType);
            const oldState = JSON.parse(JSON.stringify(config[type]));
            const newItem = { id: uuid(), name: `${type} item` };
            oldState.push(newItem);

            this.props.setConfigForTab({ [type]: oldState });

            return oldState;
        }

        handleRemove(type, id) {
            const prevState = this.props.getConfigByType(pageType);
            const oldState = JSON.parse(JSON.stringify(prevState[type]));

            const newStateByType = oldState.filter((item) => item.id !== id);
            this.props.setConfigForTab({ [type]: newStateByType });
            return newStateByType;
        }

        render() {
            const { classes, activeTab } = this.props;

            return pageType === activeTab ? (
                <ThemeProvider theme={theme}>
                    <div className={classes.detailsRoot}>
                        <Scrollbars style={{ height: 'calc(100vh - 175px)' }} autoHide>
                            <WrappedComponent
                                onChange={this.handleChange}
                                onAdd={this.handleAdd}
                                onRemove={this.handleRemove}
                                currentConfig={this.props.currentConfig}
                            />
                        </Scrollbars>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            onClick={this.handleSendConfig}
                        >
                            Save
                        </Button>
                    </div>
                </ThemeProvider>
            ) : null;
        }
    }

    const mapStateToProps = (state) => {
        return {
            currentConfig: state.interface.currentConfig,
            configForTab: state.interface.configForTab,
        };
    };

    const mapDispatchToProps = {
        getConfigByType,
        setConfigForTab,
        saveSettingsConfig,
    };

    return compose(withStyles(classes), connect(mapStateToProps, mapDispatchToProps))(HOC);
}

export default settingsHOC;
