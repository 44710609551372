import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/Edit';
import Img from 'material-ui-image';
import React, { useEffect, useState } from 'react';

import { checkImageLink, getBase64 } from '../utils';

const { config } = window;

const { defaultImage, clientHost } = config;

const useStyles = makeStyles(() => ({
    root: {
        width: 100,
        height: 100,
        borderRadius: 0,
        position: 'relative',
    },
    fullWidth: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        position: 'relative',
        background: '#646870',
    },
    content: {
        padding: '0 !important',
    },
    removeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 4,
    },
    editButton: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: 0,
    },
}));

const imgStyles = {
    maxWidth: 'calc(100% - 20px)',
    maxHeight: 'calc(100% - 20px)',
    width: 'auto',
    height: 'auto',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

export default function Image(props) {
    const classes = useStyles();
    const {
        externalLink,
        name,
        onLoadImage,
        removeImage,
        isDisabled,
        fullWidth,
        saveImage,
        sceneId,
        imagePath,
        getProtectedImage,
        clearImagePath,
    } = props;

    const userId = localStorage.getItem('userId');
    const [imageFile, setImageFile] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [originalName, setOriginalName] = useState('');
    const [imagePathToConfig, setImagePath] = useState(null);

    useEffect(() => {
        async function fetchData(externalLink, cb) {
            let blob = '';
            try {
                blob = await getProtectedImage(externalLink);
                cb(window.webkitURL.createObjectURL(blob));
            } catch (error) {
                console.log('ERR', error);
                return null;
            }
        }

        if (externalLink) {
            const linkType = checkImageLink(externalLink);

            if (linkType === 'LINK') {
                handleRefreshImage(externalLink);
            } else if (linkType === 'PATH') {
                setOriginalName(externalLink);
                fetchData(externalLink, setImageFile);
            }
        } else {
            setImageFile('');
        }
    }, [externalLink]);

    useEffect(() => {
        if (imagePath && imagePath[name]) {
            console.log('imageFile', imagePath);
            onLoadImage({
                [name]: imagePath[name],
                [`${name}Path`]: imagePathToConfig,
            });
            clearImagePath();
        }
    }, [imagePath]);

    const handleChange = (e) => {
        const file = e.target.files && e.target.files[0];
        if (!file) return;

        saveImage(sceneId, {
            formDataFields: { file },
            name,
        });
        setImagePath(`${clientHost}/configs/${userId}/${sceneId}/img/${file.name}`);
        getBase64(file, handleRefreshImage);
        clearImagePath();
        setInputValue('');
    };

    const handleRefreshImage = (base64) => {
        setImageFile(base64);
    };

    const handleRemoveImage = () => {
        removeImage(originalName);
        onLoadImage({ [name]: null });
    };

    return (
        <Card classes={{ root: fullWidth ? classes.fullWidth : classes.root }}>
            <Img
                className={classes.media}
                style={{ padding: 10, height: '100%', background: '#E9EAEB' }}
                src={imageFile || defaultImage}
                name={originalName}
                imageStyle={imgStyles}
            />
            <input
                accept="image/*"
                id={`icon-button-file-${name}`}
                type="file"
                disabled={isDisabled}
                style={{ display: 'none' }}
                onChange={handleChange}
                value={inputValue}
            />
            <label htmlFor={`icon-button-file-${name}`} className={classes.editButton}>
                <IconButton aria-label="upload picture" disabled={isDisabled} component="span" style={{ padding: 4 }}>
                    <DownloadIcon />
                </IconButton>
            </label>
            <IconButton disabled={isDisabled} onClick={handleRemoveImage} className={classes.removeButton}>
                <DeleteIcon style={{ color: '#FF6699' }} />
            </IconButton>
        </Card>
    );
}
