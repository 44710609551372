import { makeStyles } from '@material-ui/core';
import React from 'react';
import Image from '../../containers/Image';
import CustomCard from '../CustomCard';

const useStyles = makeStyles((theme) => ({
	card: {
		background: '#f5f5f5',
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		marginTop: 10,
		background: '#646971',
		height: 340,
	},
	content: {
		height: '100%',
		padding: 10,
	},
	imagesTitle: {
		paddingBottom: 10,
		color: '#FFF',
		fontSize: 16,
	},
	imagesWrapper: {
		flexDirection: 'row',
		display: 'flex',
	},
}));

export function HighlightImage(props) {
	const classes = useStyles();

	const { onChange, highlightPicture, onSaveImage, sceneId } = props;

	const handleChangeImage = (img) => {
		onChange(img);
	};

	return (
		<CustomCard classNames={['highlightImageWrapper']}>
			<div className={classes.imagesTitle}>Highlight image</div>
			<Image
				externalLink={highlightPicture}
				name="highlightPicture"
				onLoadImage={handleChangeImage}
				isDisabled={false}
				fullWidth
				sceneId={sceneId}
				saveImage={onSaveImage}
			/>
		</CustomCard>
	);
}
