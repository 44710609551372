import React from 'react';
import { connect } from 'react-redux';
import LoginPage from '../pages/Login';
import { signIn } from '../actions/AuthActions';

function SignIn(props) {
    const { signIn, history, session } = props;

    return <LoginPage signIn={signIn} session={session} history={history} />;
}

const mapStateToProps = (state) => ({
    session: state.auth.session,
});

const mapDispatchToProps = {
    signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
