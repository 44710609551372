import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/FolderOpen';
import SupportIcon from '@material-ui/icons/HelpOutline';
import clsx from 'clsx';
import React from 'react';
import Card from './CustomCard';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.mainColors.listItemDark,
        fontWeight: 500,
        fontSize: 24,
        textShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    },
    secondTitle: {
        color: '#FFE09F',
        textDecoration: 'underline',
    },
    darkerTitle: {
        color: theme.hoverColors.tabDarkBgHover,
    },
    icon: {
        fontSize: 120,
        padding: 15,
        background: '#8a9197',
        borderRadius: '100%',
        color: '#d3d5d7',
        cursor: 'pointer',
        margin: 30,
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            background: theme.hoverColors.tabDarkBgHover,
        },
    },
    darkerIcon: {
        background: '#646870',
        color: '#9A9FA5',
        '&:hover': {
            background: '#767e85',
        },
    },
}));

const ICONS = {
    AddIcon,
    FolderIcon,
    SupportIcon,
};

export default (props) => {
    const { onClick, title, iconType = 'AddIcon', fullWidth, link, cardClassNames = [], secondTitle } = props;

    const classes = useStyles();
    const Icon = ICONS[iconType];

    const titleStyles = clsx(classes.title, {
        [classes.darkerTitle]: !fullWidth,
    });

    const secondTitleStyles = clsx(classes.title, {
        [classes.darkerTitle]: !fullWidth,
        [classes.secondTitle]: secondTitle,
    });

    const iconStyles = clsx(classes.icon, {
        [classes.darkerIcon]: !fullWidth,
    });

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <Card classNames={['backdrop', ...cardClassNames]}>
            <span className={titleStyles}>{title}</span>
            <Icon onClick={handleClick} className={iconStyles} />
            <a href={link} target="blank" className={secondTitleStyles}>
                {secondTitle}
            </a>
        </Card>
    );
};
