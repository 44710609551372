/* eslint-disable prefer-promise-reject-errors */
import queryString from 'query-string';

import { prepareFormBody, prepareFormDataFile } from '../utils';

const { config } = window;

export default class ApiClient {
    constructor({ prefix = 'v1/' } = {}) {
        this.prefix = prefix;
        this.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    }

    async get(url, params) {
        return this.request({
            url,
            params,
            method: 'GET',
        });
    }

    async feedbackPost(url, payload = {}) {
        return this.request({
            url,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });
    }

    async post(url, payload = {}) {
        return this.request({
            url,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: payload }),
        });
    }

    async put(url, payload = {}) {
        return this.request({
            url,
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ data: payload }),
        });
    }

    async patch(url, payload = {}) {
        return this.request({
            url,
            method: 'PATCH',
            body: payload,
        });
    }

    async delete(url, payload = {}) {
        return this.request({
            url,
            method: 'DELETE',
            body: payload,
        });
    }

    /**
     * Post multipart/form-data
     * @param {String} requestUrl - request url
     * @param {Object} formData   - data
     * @returns {Promise<Object>} - response data
     */

    postFormData(url, payload) {
        return this.request({
            body: prepareFormDataFile(payload),
            url,
            method: 'POST',
        });
    }

    putFormData(url, file) {
        return this.request({
            body: prepareFormDataFile(file),
            url,
            method: 'PUT',
        });
    }

    async authPost(url, payload) {
        const data = { ...payload, token: localStorage.getItem('gToken') };
        return this.request({
            url,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            method: 'POST',
            body: prepareFormBody(data),
        });
    }

    async request({ url, method, params = {}, body, headers }) {
        const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
        const newHeaders = new Headers(headers);

        newHeaders.append('x-access-token', this.token || localStorage.getItem('token'));
        // newHeaders.append('token', localStorage.getItem('gToken'));

        let response = {};

        try {
            response = await fetch(`${config.apiUrl}${this.prefix}${url}${query}`, {
                method,
                headers: newHeaders,
                body: method !== 'GET' ? body : undefined,
            });
        } catch (error) {
            console.error('Server error: ', error);
            return Promise.resolve({ success: false, message: 'Server error' });
        }
        const response2 = response.clone();

        if (response.status >= 400 && response.status !== 401) {
            const resBody = await response.json();
            return Promise.resolve({ success: false, message: resBody.message || response.statusText });
        }

        if (response.status === 401) {
            const resBody = await response.json();
            return Promise.resolve({
                success: false,
                message: resBody.message || response.statusText,
                status: 401,
            });
        }

        let data;

        try {
            data = await response.json();
        } catch (error) {
            const res = await response2.blob();
            return res;
        }

        if (/text\/plain/.test(response.headers['content-type'])) {
            return data;
        }

        if (response.status < 400 && data) {
            return { ...data, success: true };
        }

        return Promise.reject('data.error');
    }

    setAuthToken(token) {
        this.token = token;
    }
}
