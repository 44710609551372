import { LOGOUT, SIGN_IN, SIGN_UP, RESENT_EMAIL } from '../actions/AuthActions';

const initialState = {
    session: {},
    resentEmailStatus: true,
};

export default function AuthReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SIGN_IN: {
            return {
                ...state,
                session: payload,
            };
        }

        case SIGN_UP: {
            return {
                ...state,
                session: payload,
            };
        }

        case LOGOUT: {
            return {
                ...state,
                session: {},
            };
        }

        case RESENT_EMAIL: {
            return {
                ...state,
                resentEmailStatus: false,
            };
        }
        default:
            return state;
    }
}
