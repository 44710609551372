export default (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        boxShadow: 'unset',
    },
    rootDark: {
        backgroundColor: theme.mainColors.detailsSectionCardBg,
        minHeight: 50,
    },
    addIconBtn: {
        padding: 5,
    },
    iconAdd: {
        fontSize: 30,
    },
    listItemRoot: {
        height: 50,
    },
    listItemSecpndaryAction: {
        paddingRight: 84,
    },
    iconRemove: {
        '&:hover': {
            fill: theme.hoverColors.removeIconBg,
        },
    },
    textField: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    text: {
        padding: 10,
        margin: 0,
        fontSize: 24,
    },
    activeItem: {
        background: theme.activeColors.yellow,
        '&:hover': {
            background: theme.activeColors.yellow,
        },
    },
    dividerDark: {
        background: '#9a9fa5',
    },
    paper: {
        paddingBottom: 0,
        width: '25%',
        minWidth: 250,
        boxShadow: '0px 2px 30px rgba(0, 0, 0, 0.35)',
        zIndex: 6,
    },
    header: {
        padding: 0,
        height: 50,
        boxShadow: 'unset',
        background: theme.mainColors.white,
    },
    darkRootHeader: {
        backgroundColor: theme.mainColors.dark,
        color: theme.mainColors.contrastColorForDark,
        // borderBottom: `1px solid ${theme.mainColors.detailsSectionCardBg}`,
    },
    buttonsWrapper: {
        right: 0,
        display: 'flex',
    },
    secondaryActionRoot: {
        right: 0,
        display: 'flex',
    },
    whiteBg: {
        background: theme.mainColors.white,
    },
    darkBg: {
        background: theme.mainColors.listItemDark,
    },
    contrastForDarkColor: {
        color: theme.mainColors.contrastListItemDark,
    },
    hoverListItemDark: {
        '&:hover': {
            backgroundColor: theme.hoverColors.hoverListItemDark,
        },
    },
    whiteColor: {
        color: theme.mainColors.contrastColorForDark,
    },
    whiteHover: {
        '&:hover': {
            backgroundColor: '#fffefe1f',
        },
    },
    groupsListWrapper: {
        height: 'calc(100vh - 156px)',
    },
    withoutRound: {
        padding: 7,
        marginRight: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
    listItemWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 44px 10px 10px',
    },
    input: {
        background: '#e9eaeb',
        padding: 6,
        height: 28,
    },
    editableIcon: {
        position: 'absolute',
        right: 14,
        padding: 0,
        fontSize: 24,
        cursor: 'pointer',
        margin: 0,
        color: theme.hoverColors.hoverListItemDark,
    },
    editableRootHeader: {
        background: theme.mainColors.detailsSectionCardBg,
        height: 40,
        minHeight: 40,
        display: 'flex',
    },
    editableHeaderText: {
        fontSize: 16,
        color: theme.mainColors.white,
    },
    editableAddIcon: {
        // fontSize: 24
    },
    editableBtnAddIcon: {
        right: 2,
    },
});
