/* eslint-disable import/no-cycle */
import api from '../apiSingleton';
import history from '../history';
import { showNotification } from './UiEffectsActions';

export const SIGN_IN = 'SIGN_IN';

export function signIn(payload) {
    return async (dispatch) => {
        let result = {};
        const { data, message, success } = await api.auth.signIn(payload);

        if (!data || success === false) {
            dispatch(showNotification(false, message || 'Server error'));
            result = { status: 0, message };
        } else {
            result = { status: 1, message, ...data };
            dispatch({
                type: SIGN_IN,
                payload: data,
            });
        }

        return result;
    };
}

export const SIGN_UP = 'SIGN_UP';

export function signUp(payload) {
    return async (dispatch) => {
        const data = await api.auth.signUp(payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: SIGN_UP,
                payload: data,
            });
        }

        return data;
    };
}

export const RESENT_EMAIL = 'RESENT_EMAIL';

export function resentActivationEmail(payload) {
    return async (dispatch) => {
        const data = await api.auth.resentActivation(payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch({
                type: RESENT_EMAIL,
            });
            dispatch(showNotification(true, message));
        }

        return data;
    };
}

export const LOGOUT = 'LOGOUT';

export function onLogout() {
    return (dispatch) => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        history.push('#/login');
        document.location.reload(true);

        dispatch({
            type: LOGOUT,
        });
    };
}

export function resetPassword(payload) {
    return async (dispatch) => {
        const data = await api.auth.resetPassword(payload);
        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch(showNotification(true, message));
        }

        return data;
    };
}

export function forgotPassword(payload) {
    return async (dispatch) => {
        const data = await api.auth.forgotPassword(payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch(showNotification(true, message));
        }

        return data;
    };
}

export function sendFeedback(payload) {
    return async (dispatch) => {
        const data = await api.auth.sendFeedback(payload);

        const { success, message } = data;

        if (success === false) {
            dispatch(showNotification(false, message || 'Server error'));
        } else {
            dispatch(showNotification(true, message));
        }

        return data;
    };
}
