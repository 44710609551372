import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import reducers from '../reducers';

export default function configureStore() {
    const store = createStore(reducers, applyMiddleware(thunkMiddleware));

    if (module.hot) {
        module.hot.accept(reducers, () => {
            module.hot.accept(reducers, () => store.replaceReducer(reducers));
        });
    }

    return store;
}
