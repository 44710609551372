import React from 'react';
import { connect } from 'react-redux';
import ResetPaage from '../pages/Reset';
import { resetPassword } from '../actions/AuthActions';

function Reset(props) {
    const { resetPassword, history, session } = props;

    return <ResetPaage resetPassword={resetPassword} session={session} history={history} />;
}

const mapStateToProps = (state) => ({
    session: state.auth.session,
});

const mapDispatchToProps = {
    resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
