import _ from 'lodash';
import {
    CREATE_BUTTON,
    CREATE_BUTTON_GROUP,
    CREATE_BUTTON_GROUP_SUCCESS,
    CREATE_BUTTON_SUCCESS,
    GET_BUTTONS_LIST,
    GET_BUTTONS_LIST_SUCCESS,
    GET_BUTTON_ONE,
    REMOVE_ALL_BUTTON,
    REMOVE_BUTTON,
    REMOVE_BUTTON_GROUP,
    UPDATE_BUTTON,
    UPDATE_BUTTON_FOR_GROUP,
    CREATE_BUTTON_MATERAIL,
    REMOVE_BUTTON_MATERAIL,
    UPDATE_BUTTON_MATERAIL,
} from '../actions/ButtonAction';
import { getCleanButtons, getGroupList } from '../utils';

const initialState = {
    buttonList: [],
    buttonGroupList: [],
    currentButton: {},
    isLoading: false,
};

export default function ButtonReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case CREATE_BUTTON:
        case GET_BUTTONS_LIST:
        case CREATE_BUTTON_GROUP: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case REMOVE_ALL_BUTTON: {
            return {
                ...state,
                buttonList: [],
            };
        }

        case GET_BUTTON_ONE: {
            const { button } = payload;

            return {
                ...state,
                currentButton: button,
            };
        }

        case GET_BUTTONS_LIST_SUCCESS: {
            const { buttons } = payload;

            const groupList = getGroupList(buttons);
            const buttonsList = getCleanButtons(buttons);

            return {
                ...state,
                currentButton: {},
                buttonList: [...buttonsList],
                buttonGroupList: [...groupList],
                isLoading: false,
            };
        }

        case CREATE_BUTTON_SUCCESS: {
            const { button } = payload;
            const { buttonGroupId } = button;
            const oldList = buttonGroupId ? state.buttonGroupList : state.buttonList;
            const KEY = buttonGroupId ? 'buttonGroupList' : 'buttonList';
            let newState = oldList;

            if (buttonGroupId) {
                let group = oldList.find((item) => item.buttonId === buttonGroupId);
                const buttons = (group.buttons && group.buttons.length && group.buttons) || [];
                buttons.push(button);
                group = { ...group, buttons };

                newState = oldList.map((item) => (item.buttonId !== buttonGroupId ? item : group));
            }
            // } else {
            //     newState = [...oldList, button];
            // }

            return {
                ...state,
                [KEY]: newState,
                buttonList: [...state.buttonList, button],
                isLoading: false,
            };
        }

        case CREATE_BUTTON_GROUP_SUCCESS: {
            const { button } = payload;
            const oldList = state.buttonGroupList;

            return {
                ...state,
                buttonGroupList: [...oldList, button],
                currentButton: oldList.length ? oldList[0] : button,
                isLoading: false,
            };
        }

        case UPDATE_BUTTON: {
            const { button } = payload;
            const { buttonGroupId } = button;
            const isGroup = !buttonGroupId && button.isGroup;
            const oldList = buttonGroupId || (!buttonGroupId && isGroup) ? state.buttonGroupList : state.buttonList;
            const KEY = buttonGroupId || (!buttonGroupId && isGroup) ? 'buttonGroupList' : 'buttonList';
            let newState = oldList;

            if (buttonGroupId && !isGroup) {
                // GROUP -> BUTTON
                let group = oldList.find((item) => item.buttonId === buttonGroupId);
                let buttons = (group.buttons && group.buttons.length && group.buttons) || [];

                buttons = buttons.map((btn) => (btn.buttonId === button.buttonId ? button : btn));
                group = { ...group, buttons };

                newState = oldList.map((item) => (item.buttonId !== buttonGroupId ? item : group));
            } else if (!buttonGroupId && !isGroup) {
                // BUTTON WITHOUT GROUP
                newState = oldList.map((item) => (item.buttonId === button.buttonId ? button : item));
            } else if (isGroup) {
                newState = oldList.map((item) => (item.buttonId === button.buttonId ? { ...item, ...button } : item));
            }

            return {
                ...state,
                currentButton: button,
                [KEY]: newState,
            };
        }

        case CREATE_BUTTON_MATERAIL:
        case REMOVE_BUTTON_MATERAIL:
        case UPDATE_BUTTON_MATERAIL: {
            const { button } = payload;

            return {
                ...state,
                currentButton: button,
            };
        }

        case UPDATE_BUTTON_FOR_GROUP: {
            const { button, currentGroup } = payload;
            const { buttonGroupId } = button;
            const oldList = state.buttonGroupList;
            let newState = oldList;

            newState.map((group) => {
                const newArr = _.remove(group.buttons, (btn) => btn.buttonId === button.buttonId);

                return newArr;
            });

            let group = oldList.find((item) => item.buttonId === buttonGroupId);
            let buttons = (group.buttons && group.buttons.length && group.buttons) || [];
            buttons = [...buttons, button];
            group = { ...group, buttons };

            newState = oldList.map((item) => (item.buttonId !== buttonGroupId ? item : group));

            return {
                ...state,
                currentButton: currentGroup,
                buttonGroupList: newState,
            };
        }

        case REMOVE_BUTTON: {
            const { button } = payload;
            const buttonGroupId = button && button[0].buttonGroupId;
            const oldList = buttonGroupId ? state.buttonGroupList : state.buttonList;

            const KEY = buttonGroupId ? 'buttonGroupList' : 'buttonList';
            let newState = oldList;

            if (buttonGroupId) {
                let group = state.buttonGroupList.find((groupItem) => groupItem.buttonId === buttonGroupId);
                const buttons = group.buttons.filter((btn) => btn.buttonId !== button[0].buttonId);
                group = { ...group, buttons };

                newState = oldList.map((item) => (item.buttonId !== buttonGroupId ? item : group));
            } else {
                newState =
                    (button && button[0] && oldList.filter((btn) => btn.buttonId !== button[0].buttonId)) || oldList;
            }
            return {
                ...state,
                currentButton: {},
                [KEY]: newState,
                buttonList:
                    (button && button[0] && state.buttonList.filter((btn) => btn.buttonId !== button[0].buttonId)) ||
                    state.buttonList,
            };
        }
        case REMOVE_BUTTON_GROUP: {
            const { button } = payload;
            const oldList = state.buttonGroupList || [];
            const newState =
                (button && button[0] && oldList.filter((btn) => btn.buttonId !== button[0].buttonId)) || oldList;

            return {
                ...state,
                currentButton: newState.length ? newState[0] : {},
                buttonGroupList:
                    (button && button[0] && oldList.filter((btn) => btn.buttonId !== button[0].buttonId)) || oldList,
            };
        }
        default:
            return state;
    }
}
