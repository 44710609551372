import { Input, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
// import Card from './CustomCard';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';
import withDisabled from '../HOC/DisableHOC';

const useStyles = makeStyles(() => ({
    textfield: {
        background: '#e9eaeb',
        padding: 6,
        height: 28,
    },
    label: {
        paddingBottom: 10,
        color: '#FFF',
        fontSize: 16,
    },
    labelForWhiteTheme: {
        color: '#656565',
    },
    inputForWhiteTheme: {
        background: 'white',
        color: '#666666',
        border: '2px solid #bbbfc4',
        borderBottom: 0,
        borderRight: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        '&:not(:last-child)': {
            marginBottom: 5,
        },
    },
    span: {
        width: 48,
        color: 'white',
        textAlign: 'center',
        paddingRight: 10,
    },
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#646971',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
}));

function ThreeInputsBlock(props) {
    const classes = useStyles();

    const {
        title,
        label,
        onChange,
        disabled,
        rows,
        cardStyles,
        isWhite,
        cardClassName,
        nameX,
        valX,
        nameY,
        valY,
        nameZ,
        valZ,
    } = props;

    const [valueX, setValueX] = useState(`${valX}`);
    const [valueY, setValueY] = useState(`${valY}`);
    const [valueZ, setValueZ] = useState(`${valZ}`);

    useEffect(() => {
        if (Number(valX)) setValueX(Number(valX).toString());
    }, [valX]);
    useEffect(() => {
        if (Number(valY)) setValueY(Number(valY).toString());
    }, [valY]);
    useEffect(() => {
        if (Number(valZ)) setValueZ(Number(valZ).toString());
    }, [valZ]);

    const handleChange = (e) => {
        const { value } = e.target;
        const { name } = e.target;
        const numberValue = Number(value);
        switch (name) {
            case nameX:
                setValueX(numberValue);
                break;
            case nameY:
                setValueY(numberValue);
                break;
            case nameZ:
                setValueZ(numberValue);
                break;
            default:
                break;
        }
        onChange(value, name);
    };

    const labelStyles = clsx(classes.label, {
        [classes.labelForWhiteTheme]: isWhite,
    });

    const inputStyless = clsx(classes.textfield, {
        [classes.inputForWhiteTheme]: isWhite,
        [classes.centered]: disabled,
    });

    const isAdmin = localStorage.getItem('role') === 'ADMIN';
    const isDisabled = !isAdmin && disabled;

    return (
        <Card className={classes.cardNodeList}>
            <CardContent className={classes.cardContent} disabled={isDisabled}>
                {label && <div className={labelStyles}>{label}</div>}
                <div className={classes.row}>
                    <span className={classes.span}>X</span>
                    <DebounceInput
                        debounceTimeout={500}
                        element={Input}
                        value={valueX}
                        name={nameX}
                        disabled={isDisabled}
                        variant="filled"
                        fullWidth
                        rows={rows}
                        disableUnderline
                        multiline={rows > 1}
                        classes={{ input: inputStyless }}
                        onChange={handleChange}
                    />
                </div>
                <div className={classes.row}>
                    <span className={classes.span}>Y</span>
                    <DebounceInput
                        debounceTimeout={500}
                        element={Input}
                        value={valueY}
                        disabled={isDisabled}
                        variant="filled"
                        name={nameY}
                        fullWidth
                        rows={rows}
                        disableUnderline
                        multiline={rows > 1}
                        classes={{ input: inputStyless }}
                        onChange={handleChange}
                    />
                </div>
                <div className={classes.row}>
                    <span className={classes.span}>Z</span>
                    <DebounceInput
                        debounceTimeout={500}
                        element={Input}
                        value={valueZ}
                        disabled={isDisabled}
                        variant="filled"
                        name={nameZ}
                        fullWidth
                        rows={rows}
                        disableUnderline
                        multiline={rows > 1}
                        classes={{ input: inputStyless }}
                        onChange={handleChange}
                    />
                </div>
            </CardContent>
        </Card>
    );
}

ThreeInputsBlock.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    cardStyles: PropTypes.string,
    isWhite: PropTypes.bool,
    cardClassName: PropTypes.arrayOf(PropTypes.string),
};

ThreeInputsBlock.defaultProps = {
    title: '',
    label: '',
    onChange: () => {},
    disabled: false,
    rows: 0,
    cardStyles: '',
    isWhite: false,
    cardClassName: ['editableInput'],
};

const CardContent = withDisabled(CardContentComponent);

export default ThreeInputsBlock;
