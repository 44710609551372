import React, { useEffect } from 'react';

const reCaptchaKey = '6LejnOIZAAAAAKQeH2cbQ6G7ciXikJ6xsn-MjBut';

function ReCaptcha() {
    const handleLoaded = (_) => {
        window.grecaptcha.ready((_) => {
            window.grecaptcha.execute(reCaptchaKey, { action: 'homepage' }).then((token) => {
                console.log('token', token);
                localStorage.setItem('gToken', token);
            });
        });
    };

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`;
        script.addEventListener('load', handleLoaded);
        document.body.appendChild(script);
    }, []);

    return <div className="g-recaptcha" data-sitekey={reCaptchaKey} data-size="invisible" />;
}

export default ReCaptcha;
