// import { ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Input from '../CustomInput';
import ButtonIcon from '../ButtonIcon';
import styles from './styles';

const useStyles = makeStyles(styles);

export default (props) => {
    const { itemId, onRemove, name, onChange } = props;

    const classes = useStyles();
    const [id, setId] = useState('');
    const [value, setValue] = useState(name);

    useEffect(() => {
        if (itemId) setId(itemId);
    }, [itemId]);

    useEffect(() => {
        setValue(name);
    }, [name]);

    const handleChange = (data) => {
        setValue(data.value);
        onChange({ name: id, value: data.value });
    };

    const iconStyles = clsx(classes.iconRemove, classes.withoutRound, classes.editableIcon);

    return (
        <div className={classes.listItemWrapper}>
            <Input
                value={value}
                variant="filled"
                fullWidth
                disableUnderline
                classes={{ input: classes.input }}
                onChange={handleChange}
            />
            <ButtonIcon
                onClick={onRemove}
                iconType="DeleteIcon"
                classNames={['withRedIconHover', 'isEditableListColor', 'deleteIcon', 'isEditableRemove']}
            />
        </div>
    );
};
