import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import GRecaptcha from '../ui-components/GRecaptcha';
import withNotificationHOC from '../HOC/withNotificationHOC';

const { appName } = window.config;

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        background: theme.hoverColors.hoverListItemDark,
    },
    button: {
        minWidth: 280,
        height: 40,
        borderRadius: 20,
        background: '#009999',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
        marginTop: 40,
        fontSize: 16,
        '&:hover': {
            background: '#009999',
        },
        '&:disabled': {
            background: '#00999966',
            color: '#fff',
        },
        color: '#fff',
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.35)',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    text: {
        fontWeight: 300,
        fontSize: 18,
        margin: 0,
        fontFamily: 'Roboto',
        minHeight: 20,
        color: '#333333',
    },
}));

function Activation({ history, signIn }) {
    const classes = useStyles();

    const handleClick = async () => {
        const urlParams = new URLSearchParams(history.location.search);
        const code = urlParams.get('oneTimeSigninCode');
        const data = await signIn({ oneTimeSigninCode: code });
        console.log('VERIFICATION', data);
        const { accessToken, id, role } = data;

        if (accessToken) {
            localStorage.setItem('token', accessToken);
            localStorage.setItem('role', role && role.toUpperCase());
            localStorage.setItem('userId', id);
            history.push('/');
        }
        console.log(code);
    };

    return (
        <main className={classes.content}>
            <p className={classes.text}>
                <h2>
                    <b> Congrats!</b>
                </h2>
            </p>
            <p className={classes.text}>
                {' '}
                And Welcome to <b>{appName}</b>
            </p>
            <Button variant="contained" onClick={handleClick} className={classes.button}>
                Log In now
            </Button>
            <GRecaptcha />
        </main>
    );
}

const ActivationWithNotification = withNotificationHOC(Activation);

export default ActivationWithNotification;
