import Base from './Base';

class Highlight extends Base {
    async list(sceneId) {
        const data = await this.apiClient.get(`highlight/${sceneId}`);

        return data;
    }

    async one(sceneId, nodeId) {
        const data = await this.apiClient.get(`highlight/${sceneId}/${nodeId}`);

        return data;
    }

    async create(sceneId, payload) {
        const data = await this.apiClient.post(`highlight/${sceneId}`, payload);

        return data;
    }

    async remove(sceneId, highlightId) {
        const data = await this.apiClient.delete(`highlight/${sceneId}/${highlightId}`);

        return data;
    }

    async update(sceneId, highlightId, payload) {
        const data = await this.apiClient.put(`highlight/${sceneId}/${highlightId}`, payload);

        return data;
    }
}

export default Highlight;
