import { Input, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { createRef, useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewTabIcon from '@material-ui/icons/OpenInNew';
import CoppyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Card from './CustomCard';
import ColorPicker from './ColorPicker';
import ButtonIcon from './ButtonIcon';
import DisabledBackdrop from './DisabledBackdrop';

const useStyles = makeStyles(() => ({
    textfield: {
        background: '#e9eaeb',
        padding: 6,
        height: 28,
    },
    label: {
        paddingBottom: 10,
        color: '#FFF',
        fontSize: 16,
    },
    labelWithButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    button: {
        color: '#DEDFE0',
        '&:hover': {
            color: 'rgb(0,153,0)',
        },
    },
    labelForWhiteTheme: {
        color: '#656565',
    },
    inputForWhiteTheme: {
        background: 'white',
        color: '#666666',
        border: '2px solid #bbbfc4',
        borderBottom: 0,
        borderRight: 0,
        '&:disabled': {
            color: '#66666670',
        },
    },
}));

function EditableInput(props) {
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [pickerVisible, setPickerVisible] = useState(false);

    const {
        title,
        label,
        onChange,
        disabled,
        withDisable,
        rows,
        cardStyles,
        isWhite,
        cardClassName,
        name,
        withColorPicker,
        labelWithButtons,
        withRemove,
        onRemove,
        onCoppy,
        onOpenNewTab,
        url,
    } = props;
    const ref = createRef();

    useEffect(() => {
        setValue(title);
    }, [title]);

    const [state, setState] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (ref.current) {
            const { height } = state;

            const newHeight = ref.current.clientHeight;

            if (height !== newHeight) {
                const width = ref.current.clientWidth;
                setState({ height: newHeight, width });
            }
        }
    }, ref.current);

    const handleChange = (e) => {
        const inputVal = e.target.value;
        setValue(inputVal);
        onChange(inputVal, name);
    };

    const handleChangePicker = (color) => {
        setValue(color);
        onChange(color, name);
    };
    const handleTogleColorPIcker = () => setPickerVisible(!pickerVisible);

    const isAdmin = localStorage.getItem('role') === 'ADMIN';

    const labelStyles = clsx(classes.label, {
        [classes.labelForWhiteTheme]: isWhite,
        [classes.labelWithButtons]: labelWithButtons || withRemove,
    });

    const inputStyless = clsx(classes.textfield, {
        [classes.inputForWhiteTheme]: isWhite,
        [classes.centered]: disabled && !isAdmin,
    });

    return (
        <Card classNames={cardClassName} ref={ref} styles={cardStyles}>
            {withDisable && disabled && !isAdmin && (
                <DisabledBackdrop width={state.width} height={state.height} left={0} top={0} />
            )}
            {label && (
                <div className={labelStyles}>
                    {label}
                    {labelWithButtons && (
                        <div>
                            <CopyToClipboard text={title}>
                                <IconButton className={classes.button} onClick={onCoppy}>
                                    <CoppyIcon fontSize="small" />
                                </IconButton>
                            </CopyToClipboard>
                            <IconButton href={url} target="blank" className={classes.button} onClick={onOpenNewTab}>
                                <OpenInNewTabIcon fontSize="small" />
                            </IconButton>
                        </div>
                    )}
                    {withRemove && (
                        <ButtonIcon
                            onClick={onRemove}
                            iconType="DeleteIcon"
                            styles={{ right: 0 }}
                            classNames={[
                                'withMarginRight',
                                'deleteIcon',
                                'withRedIconHover',
                                'withColorLight',
                                'isEditableRemove',
                            ]}
                        />
                    )}
                </div>
            )}
            <DebounceInput
                debounceTimeout={500}
                element={Input}
                value={value}
                disabled={disabled}
                variant="filled"
                fullWidth
                rows={rows}
                disableUnderline
                multiline={rows > 1}
                classes={{ input: inputStyless }}
                onChange={handleChange}
            />
            {withColorPicker ? (
                <>
                    <ButtonIcon
                        onClick={handleTogleColorPIcker}
                        iconType="ArrowRight"
                        classNames={['withMarginRight', 'colorPickerIcon']}
                    />
                    <ColorPicker
                        pickerVisible={pickerVisible}
                        toglePicker={handleTogleColorPIcker}
                        color={value}
                        onChange={handleChangePicker}
                    />
                </>
            ) : null}
        </Card>
    );
}

EditableInput.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    rows: PropTypes.number,
    cardStyles: PropTypes.object,
    isWhite: PropTypes.bool,
    cardClassName: PropTypes.arrayOf(PropTypes.string),
};

EditableInput.defaultProps = {
    title: '',
    labelWithButtons: false,
    label: '',
    name: '',
    onChange: () => {},
    disabled: false,
    rows: 0,
    cardStyles: {},
    isWhite: false,
    cardClassName: ['editableInput'],
};

export default EditableInput;
