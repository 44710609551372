import {
    Slider as MaterialSlider,
    makeStyles,
    createMuiTheme,
    ThemeProvider,
    Typography,
    withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from './CustomCard';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const useStyles = makeStyles((theme) => ({
    label: {
        color: theme.mainColors.white,
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
        margin: 0,
    },
    text: {
        fontSize: 16,
        marginRight: 6,
    },
    slider: {
        width: '95%',
    },
}));

const CustomizedSlider = withStyles({
    root: {
        color: '#009688',
        height: 4,
        padding: '15px 0',
        marginLeft: 12,
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: '#fff',
        // boxShadow: iOSBoxShadow,
        marginTop: -12,
        marginLeft: -12,
        '&:after': {
            content: null,
        },
        ' &.MuiSlider-active': { boxShadow: 'none !important' },
    },
    track: {
        height: 4,
    },
    active: {
        boxShadow: 'none !important',
    },
    rail: {
        height: 4,
        opacity: 0.5,
        backgroundColor: '#e4e4e4',
    },
    mark: {
        backgroundColor: '#e4e4e4',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
})(MaterialSlider);

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#dedfe0',
        },
    },
});

function Slider(props) {
    const classes = useStyles();
    const [value, setValue] = useState(false);

    const { val, label, type, onChange, disabled, cardStyles, cardClassName, min, max, step } = props;

    useEffect(() => {
        setValue(val);
    }, [val]);

    const handleChange = (event, newVal) => {
        setValue(newVal);
        onChange({ value: newVal }, type);
    };

    return (
        <ThemeProvider theme={theme}>
            <Card classNames={cardClassName} style={cardStyles}>
                {label ? (
                    <div className={classes.label}>
                        <Typography className={classes.text} variant="h5" gutterBottom>
                            {label}
                        </Typography>
                        <Typography className={classes.text} variant="h5" gutterBottom>
                            {value}
                        </Typography>
                    </div>
                ) : null}
                <CustomizedSlider
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    step={step}
                    className={classes.slider}
                    aria-labelledby="continuous-slider"
                />
            </Card>
        </ThemeProvider>
    );
}

Slider.propTypes = {
    val: PropTypes.number,
    step: PropTypes.number,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    cardStyles: PropTypes.string,
    type: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    cardClassName: PropTypes.arrayOf(PropTypes.string),
};

Slider.defaultProps = {
    label: '',
    onChange: () => {},
    type: '',
    disabled: false,
    cardStyles: '',
    min: 0,
    max: 500,
    val: 0,
    step: 1,
    cardClassName: ['Slider'],
};

export default Slider;
