import { GET_CONFIG_BY_TYPE, SET_CONFIG_FOR_TAB } from '../actions/SettingsActions';
import { GET_ONE_CONFIG } from '../actions/ScenesActions';
import { filterConfigByType, normilizeObjToArr } from '../utils';

const initialState = {
    currentConfig: {},
};

export default function SettingsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CONFIG_BY_TYPE: {
            const { config } = payload;

            return {
                ...state,
                currentConfig: { ...config },
            };
        }

        case GET_ONE_CONFIG: {
            const { config } = payload;
            delete config.buttons;
            const newConfig = JSON.parse(JSON.stringify(config));

            const filteredConfig = filterConfigByType(newConfig, 'GENERAL');
            const normalizedState = normilizeObjToArr(filteredConfig);

            return {
                ...state,
                currentConfig: normalizedState,
            };
        }

        case SET_CONFIG_FOR_TAB: {
            console.log('SET_CONFIG_FOR_TAB', payload, state);
            const prevState = state.currentConfig;

            return {
                ...state,
                currentConfig: { ...prevState, ...payload },
            };
        }
        default:
            return state;
    }
}
