import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React, { useEffect, useState } from 'react';
import withNotificationHOC from '../HOC/withNotificationHOC';
import GRecaptcha from '../ui-components/GRecaptcha';

const { appName } = window.config;


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}.
        </Typography>
    );
}


const useStyles = makeStyles((theme) => ({
    wrapper: {},
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        color: 'white',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    withMargin: {
        marginTop: 20,
    },
}));

function SignIn({ signIn, history, session, errorMessage, ...otherProps }) {
    const isLoggedIn = localStorage.getItem('token');

    if (isLoggedIn) history.push('/');

    const classes = useStyles();
    const [state, setState] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (session) {
            const { status, accessToken, id, role, message } = session;

            console.log('session', session);

            if (accessToken) {
                localStorage.setItem('token', accessToken);
                localStorage.setItem('role', role && role.toUpperCase());
                localStorage.setItem('userId', id);
                history.push('/');
            }
        }
    }, [session, history]);

    useEffect(() => {}, [errorMessage]);

    const onSubmit = async () => {
        setLoading(true);
        const res = await signIn(state);

        window.sendinblue.identify(state.email);

        setLoading(false);
        if (res?.status) {
            // history.push('/activation');
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setErrors({ errors, [name]: '' });
        setState({ ...state, [name]: value });

        if (!value) {
            setErrors({ ...errors, [name]: 'Required field' });
        }
    };

    return (
        <div className={classes.wrapper}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {`${appName} Sign In`}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={!!errors.email}
                            helperText={errors.email}
                            onChange={handleOnChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            error={!!errors.password}
                            helperText={errors.password}
                            autoComplete="current-password"
                            onChange={handleOnChange}
                        />
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            className={classes.submit}
                            disabled={!state.password || !state.email}
                        >
                            Sign In
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                        <Grid container>
                            <Grid item xs={12}>
                                <Link href="#/signup" variant="body2">
                                    Don't have an account? Sign Up
                                </Link>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <div className={classes.withMargin}>
                                <Link href="#/forgot-password" variant="body2">
                                    Forgot password? Click here to reset
                                </Link>
                            </div>
                        </Grid>
                    </form>
                </div>
                <Box mt={10}>
                    <Typography component="div" variant="caption">
                        This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Copyright />
                </Box>
                <GRecaptcha />
            </Container>
        </div>
    );
}
const SigninWithNotification = withNotificationHOC(SignIn)


export default SigninWithNotification