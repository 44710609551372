import React from 'react';
import DisabledBackdrop from '../ui-components/DisabledBackdrop';

function withDisabled(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                height: 0,
                width: 0,
                left: 0,
                top: 0,
            };
        }

        componentDidMount() {
            this.updateComponentParams(1);
            window.addEventListener('resize', () => this.updateComponentParams(1));
        }

        componentDidUpdate() {
            this.updateComponentParams();
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateComponentParams);
        }

        updateComponentParams(didMount) {
            const { height } = this.state;
            const component = this.currentElement;
            const isAdmin = localStorage.getItem('role') === 'ADMIN';
            if (!component || isAdmin) return;
            const newHeight = component.clientHeight;

            if (height !== newHeight || didMount) {
                const width = component.clientWidth;
                const left = component.offsetLeft;
                // const top = component.offsetTop;

                this.setState({
                    width,
                    height: newHeight,
                    left,
                });
            }
        }

        render() {
            const { width, height, left, top } = this.state;

            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        ref={(currentElement) => {
                            this.currentElement = currentElement;
                        }}
                    />
                    {this.props.disabled && <DisabledBackdrop width={width} height={height} left={left} top={top} />}
                </>
            );
        }
    };
}

export default withDisabled;
