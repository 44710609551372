import React from 'react';
import { connect } from 'react-redux';
import SupportPage from '../pages/Support';
import { sendFeedback } from '../actions/AuthActions';

function Support(props) {
    return <SupportPage {...props} />;
}

const mapDispatchToProps = {
    sendFeedback,
};

export default connect(null, mapDispatchToProps)(Support);
