import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LockImage from '@material-ui/icons/Lock';

const useStyles = makeStyles(() => ({
    root: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#6469717f',
        zIndex: 1100,
    },
    image: {
        position: 'absolute',
        bottom: 4,
        right: 2,
        fontSize: 16,
        color: '#FFE09F',
    },
}));

const DisabledBackdrop = (props) => {
    const styles = useStyles();

    const { width, height, left, top } = props;

    return (
        <div className={styles.root} style={{ width, height, left, top }}>
            <LockImage className={styles.image} />
        </div>
    );
};
DisabledBackdrop.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
};

export default DisabledBackdrop;
