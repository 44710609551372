import { Box, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { createMuiTheme, makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Image from '../containers/Image';
import Backdrop from './Backdrop';
import ButtonDetailsList from './ButtonDetailsList';
import CustomCard from './CustomCard';
import EditableInput from './EditableInput';
import MaterilsWithNodesList from './MaterialsWithNodesList';
import ButtonIcon from './ButtonIcon';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0000008a',
        },
    },
});

const useStyles = makeStyles(() => ({
    root: {
        minWidth: 275,
        width: '66.66%',
        padding: '0 10px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        paddingBottom: 0,
    },
    card: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginTop: 10,
        marginBottom: 10,
        background: '#646971',
        boxShadow: 'none'
    },
    textfield: {
        background: '#e9eaeb',
    },
    cardNodeList: {
        marginTop: 10,
        marginBottom: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
    },
    cardContent: {
        padding: '0 !important',
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: 10,
    },
    imagesTitle: {
        paddingBottom: 10,
        color: '#FFF',
        fontSize: 16,
    },
    imagesWrapper: {
        flexDirection: 'row',
        display: 'flex',
    },
}));

export default function ButtonsDetails(props) {
    const {
        dataNodes,
        dataHighlights,
        title,
        onChangeConfig,
        addNode,
        onRemoveNode,
        isDisabledAddNode,
        onChangeNode,
        addHighlight,
        onRemoveHighlight,
        onChangeHighlight,
        currentButton,
        chahgeButtonImage,
        changeButtonGroup,
        buttonList,
        sceneId,
        buttonListMaterials,
        buttonListGeometry,
        onCreateGroup,
        createButtonMaterial,
        removeButtonMaterial,
        updateButtonMaterial,
        createMaterialNode,
        removeMaterialNode,
        updateMaterialNode
    } = props;
    const classes = useStyles();
    const [correctButtonList, setButtonList] = useState([]);
    const [nodesList, setNodesList] = useState([]);
    useEffect(() => {
        if (dataNodes) {
            setNodesList(dataNodes);
        }
    }, [dataNodes]);

    const handleAdd = (type) => (type === 'NODES' ? addNode() : addHighlight());
    const handleUpdate = (payload, type) =>
        type === 'NODES' ? onChangeNode(payload.name, payload.value) : onChangeHighlight(payload.name, payload.value);
    const handleRemove = (id, type) => (type === 'NODES' ? onRemoveNode(id) : onRemoveHighlight(id));

    useEffect(() => {
        const btnList =
            currentButton.hasOwnProperty('groupType') && currentButton.groupType === 'MATERIALS'
                ? buttonListMaterials
                : buttonListGeometry;

        const list = btnList.map((button) => {
            return { ...button, id: button.buttonId };
        });

        setButtonList(list);
    }, [buttonList, currentButton]);

    const MAP_BUTTON_IMAGES_KEYS = [
        { key: 'buttonImage', name: 'MAIN' },
        { key: 'buttonHover', name: 'HOVER' },
        { key: 'buttonClicked', name: 'PRESSED' },
    ];

    const handleChangeName = (buttonName) => onChangeConfig({ buttonName });
    const handleChangeButtonMaterial = (buttonMaterial) => onChangeConfig({ buttonMaterial });

    const isGroupActive = currentButton && currentButton.isGroup;
    let buttonType = null;
    if (currentButton && currentButton.buttonMaterial === null) {
        buttonType = 'GEOMETRY';
    } else {
        buttonType = 'MATERIALS';
    }

    const handleAddMaterialWithNodes = () => {
        createButtonMaterial(sceneId)
    };
    const handleRemoveMAterilaButton = (itemId) => {
        removeButtonMaterial(sceneId, itemId)
    }
    const handleUpdateMaterialName=(itemId, name)=> {
        updateButtonMaterial(sceneId, itemId, {materialNode: name })
    }

    const handleCreateMaterialNode = (itemId) => {
        createMaterialNode(sceneId, itemId)
    }

    const handleRemoveMaterialNode = (materialId, nodeId) => removeMaterialNode(sceneId, materialId, nodeId)
    const handleUpdateMaterialNode = (materialId, nodeId, payload) => updateMaterialNode(sceneId, materialId, nodeId, payload)

    


    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                {Object.keys(currentButton).length ? (
                    <Scrollbars style={{ height: 'calc(100vh - 110px)' }} autoHide>
                        {isGroupActive && <EditableInput label="Group type" title={currentButton.groupType} disabled />}
                        <EditableInput
                            label="Display name"
                            title={title}
                            onChange={handleChangeName}
                            disabled={false}
                        />
                        {!isGroupActive && (
                            <CustomCard classNames={['imageWrapper']}>
                                <div className={classes.imagesTitle}>Button image</div>
                                <div className={classes.imagesWrapper}>
                                    {MAP_BUTTON_IMAGES_KEYS.map((item) => {
                                        return isGroupActive ? null : (
                                            <div className={classes.flex} key={item.key}>
                                                <Image
                                                    externalLink={currentButton[item.key]}
                                                    name={item.key}
                                                    title={item.name}
                                                    key={item.key}
                                                    sceneId={sceneId}
                                                    onLoadImage={chahgeButtonImage}
                                                    isDisabled={isDisabledAddNode}
                                                />
                                                <Typography component="div">
                                                    <Box fontSize="12px" padding="6px" color="white">
                                                        {item.name}
                                                    </Box>
                                                </Typography>
                                            </div>
                                        );
                                    })}
                                </div>
                            </CustomCard>
                        )}
                        {!isGroupActive ? (
                            <>
                                {buttonType === 'MATERIALS' && (
                                    <>
                                        {currentButton?.buttonMaterial?.map(({materialID, materialName, materialNode, changeNodes}) => (
                                            <MaterilsWithNodesList
                                                currentButton={currentButton}
                                                label={materialName}
                                                title={materialNode}
                                                nodesList={changeNodes}
                                                id={materialID}
                                                isDisabledAddNode={isDisabledAddNode}
                                                onRemove={handleRemoveMAterilaButton}
                                                onUpdateTitle={handleUpdateMaterialName}
                                                onCreateMaterialNode={handleCreateMaterialNode}
                                                onRemoveMaterialNode = {handleRemoveMaterialNode}
                                                onUpdateMaterialNode={handleUpdateMaterialNode}
                                            />
                                        ))}

                                        <Card className={classes.card}>
                                            <CardContent className={classes.cardContent}>
                                                <ButtonIcon
                                                    onClick={handleAddMaterialWithNodes}
                                                    classNames={['fullWidthAdd', 'isEditableListColor', 'withGreenIconHover']}
                                                />
                                            </CardContent>
                                        </Card>
                                    </>
                                )}
                                {buttonType === 'GEOMETRY' && (
                                    <>
                                    <Card className={classes.cardNodeList}>
                                        <CardContent className={classes.cardContent}>
                                            <ButtonDetailsList
                                                title="Button nodes"
                                                type="NODES"
                                                isDisabled={isDisabledAddNode}
                                                data={nodesList}
                                                onAdd={handleAdd}
                                                onRemove={handleRemove}
                                                onChange={handleUpdate}
                                                idKey="buttonNodeId"
                                                nameKey="buttonNodeName"
                                            />
                                        </CardContent>
                                    </Card>
                                    <Card className={classes.cardNodeList} >
                                        <CardContent className={classes.cardContent}>
                                            <ButtonDetailsList
                                                title="Button highlights"
                                                type="HIGHLIGHTS"
                                                isDisabled={isDisabledAddNode}
                                                data={dataHighlights}
                                                onAdd={handleAdd}
                                                onRemove={handleRemove}
                                                onChange={handleUpdate}
                                                idKey="highlightNodeId"
                                                nameKey="highlightNodeName"
                                            />
                                        </CardContent>
                                    </Card>
                                    </>
                                )}
                            </>
                        ) : null}
                    </Scrollbars>
                ) : (
                    <Backdrop
                        onClick={onCreateGroup}
                        iconType="FolderIcon"
                        cardClassNames={['fullWidth']}
                        title="Add a button group"
                    />
                )}
            </div>
        </MuiThemeProvider>
    );
}

ButtonsDetails.propTypes = {};
