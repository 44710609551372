import { filterConfigByType, prepareConfig } from '../utils';
import defaultConfig from '../ui-components/Interface/initState';
import { saveConfig } from './ScenesActions';

export const GET_CONFIG_BY_TYPE = 'GET_CONFIG_BY_TYPE/INTERFACE';

export function getConfigByType(type) {
    return (dispatch, getState) => {
        const state = getState();
        const oldWorldConfig = state.scene.interfaceConfig;
        const oldCurentConfig = state.interface.currentConfig;
        const mergedConfig =
            type !== 'CUSTOM' ? { ...defaultConfig, ...oldWorldConfig, ...oldCurentConfig } : oldWorldConfig;
        const filteredConfig = filterConfigByType(mergedConfig, type, 'INTERFACE');

        dispatch({
            type: GET_CONFIG_BY_TYPE,
            payload: { type, config: filteredConfig },
        });

        return filteredConfig;
    };
}

export const SET_CONFIG_FOR_TAB = 'SET_CONFIG_FOR_TAB/INTERFACE';

export function setConfigForTab(payload) {
    return (dispatch) => {
        console.log('SET_CONFIG', payload);
        dispatch({
            type: SET_CONFIG_FOR_TAB,
            payload,
        });
    };
}

export const SAVE_SETTING_CONFIG = 'SAVE_SETTING_CONFIG/INTERFACE';

export function saveSettingsConfig({ sceneId, pageType }) {
    return (dispatch, getState) => {
        const state = getState();
        const oldInterface = JSON.parse(JSON.stringify(state.scene.interfaceConfig));
        const oldCurentConfig = JSON.parse(JSON.stringify(state.interface.currentConfig));
        const mergedConfig = { ...oldInterface, ...oldCurentConfig };

        dispatch(saveConfig(sceneId, mergedConfig, 'MOCK'));
    };
}
