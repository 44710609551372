import React from 'react';
import { connect } from 'react-redux';
import VerificationPage from '../pages/Verification';
import { signIn } from '../actions/AuthActions';

function Verification(props) {
    const { history, signIn } = props;

    return <VerificationPage history={history} signIn={signIn} />;
}

const mapStateToProps = (state) => ({
    session: state.auth.session,
});

const mapDispatchToProps = {
    signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
