import React from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { teal } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import StepConnector from '@material-ui/core/StepConnector';

const { steps } = window.config;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        height: '100%',
    },
    stepper: {
        background: 'transparent',
        color: '#fff',
        width: '30%',
        minHeight: '100%',
        position: 'relative',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    title: {
        color: '#fff',
    },
    stepLabel: {
        '&$selected': {
            color: '#66FF00',
        },
    },
    step: {
        marginBottom: 20,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70%',
        maxHeight: '97%',
        padding: 20,
        position: 'relative',
        paddingBottom: 0,
    },
    contentFinish: {
        height: 420,
        width: '60%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        display: 'block',
        overflow: 'hidden',
        boxShadow: '0px 11px 30px rgb(0 0 0 / 30%)',
        objectFit: 'cover',
        maxHeight: '100%',
        maxWidth: '100%',
        objectPosition: '50% 50%',
    },
    button: {
        minWidth: 100,
        height: 40,
        borderRadius: 20,
        marginLeft: 20,
    },
    buttonBack: {
        border: '1px solid #cccdcf',
        minWidth: 100,
        height: 40,
        borderRadius: 20,
        margin: '20px 0',
        color: '#cccdcf',
    },
    stepConnector: {
        display: 'none !important',
    },
    buttonsWrapper: {
        marginTop: 40,
        position: 'absolute',
        bottom: 4,
    },
}));

const theme = createMuiTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: '#66FF00',
                },
                '&$active': {
                    color: '#FFCC00',
                },
                color: '#dedfe0',
                width: 30,
                height: 30,
            },
            text: {
                fill: '#777E86 !important',
                textAnchor: 'middle',
                transform: 'translateY(2px)',
                fontWeight: 500,
                fontSize: 18,
            },
        },
        MuiStepLabel: {
            label: {
                '&$completed': {
                    color: '#66FF00',
                },
                '&$active': {
                    color: '#FFCC00',
                },
                color: '#fff',
                fontSize: 16,
            },
        },
    },
    palette: {
        primary: teal,
    },
});

export default function HorizontalLinearStepper({ onClose }) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    function getStepContent(step) {
        const currentItem = steps.find((item) => item.id === step);
        return <img className={classes.img} src={currentItem?.src} alt="img" />;
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    orientation="vertical"
                    connector={
                        <StepConnector
                            classes={{
                                root: classes.stepConnector,
                            }}
                        />
                    }
                >
                    {steps.map((item, index) => {
                        const stepProps = {};
                        return (
                            <Step key={item.id} {...stepProps} className={classes.step}>
                                <StepLabel>{item.label}</StepLabel>
                            </Step>
                        );
                    })}
                    <div className={classes.buttonsWrapper}>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.buttonBack}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={activeStep === steps.length}
                            className={classes.button}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                </Stepper>
                {activeStep === steps.length ? (
                    <div className={classes.contentFinish}>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <div>
                            <Button onClick={handleReset} className={classes.buttonBack}>
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                                className={classes.button}
                            >
                                Close
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className={classes.content}>{getStepContent(activeStep)}</div>
                )}
            </div>
        </ThemeProvider>
    );
}
