import {
    CREATE_HIGHLIGHT,
    GET_HIGHLIGHTS_LIST,
    GET_HIGHLIGHT_ONE,
    REMOVE_ALL_HIGHLIGHT,
    REMOVE_HIGHLIGHT,
    UPDATE_HIGHLIGHT,
} from '../actions/HighlightAction';

const initialState = {
    highlightList: [],
    currentHighlight: {},
    needSave: false,
};

export default function HighlightReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REMOVE_ALL_HIGHLIGHT: {
            return {
                ...state,
                currentHighlight: {},
                highlightList: [],
            };
        }
        case GET_HIGHLIGHT_ONE:
        case UPDATE_HIGHLIGHT: {
            const { highlight } = payload;
            const oldList = state.highlightList;

            return {
                ...state,
                currentHighlight: highlight,
                highlightList: oldList.map((item) =>
                    item.highlightNodeId === highlight.highlightNodeId ? highlight : item
                ),
            };
        }

        case GET_HIGHLIGHTS_LIST: {
            const { highlights } = payload;

            return {
                ...state,
                currentHighlight: {},
                highlightList: highlights ? [...highlights] : [],
            };
        }

        case CREATE_HIGHLIGHT: {
            const { highlight } = payload;
            const oldList = state.highlightList;

            return {
                ...state,
                highlightList: [...oldList, highlight],
            };
        }

        case REMOVE_HIGHLIGHT: {
            const { highlight } = payload;
            const oldList = state.highlightList;

            return {
                ...state,
                currentHighlight: {},
                highlightList: oldList.filter((node) => node.highlightNodeId !== highlight[0].highlightNodeId),
            };
        }
        default:
            return state;
    }
}
