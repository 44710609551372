const BUTTON_BG = '#009999';
const GREY_BG = '#777e86';
const WHITE_BG = '#FFFFFF';

export default (theme) => {
    return {
        root: {
            width: '75%',
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.35)',
        },
        tabsRoot: {
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.35)',
            height: 50,
            background: theme.mainColors.tabDarkBg,
        },
        tabRoot: {
            padding: 0,
            fontSize: '0.6rem',
            minHeight: 56,
        },
        rootTabPanel: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 112px)',
        },
        rootTabPanelBlack: {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: 'calc(100vh - 112px)',
            background: theme.mainColors.detailsSectionBg,
        },
        borderTab: {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        },
        selectedTab: {
            background: theme.mainColors.dark,
            minHeight: 50,
            paddingTop: 2,
            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.25) inset',
            '&:hover': {
                background: `${theme.mainColors.dark} !important`,
                boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.25) inset',
            },
        },
        noSelectedTabs: {
            color: 'white',
            minHeight: 50,
            paddingTop: 2,
            '&:hover': {
                background: theme.hoverColors.tabDarkBgHover,
            },
        },
        modalButton: {
            width: 120,
            height: 40,
            backgroundColor: BUTTON_BG,
            borderRadius: 20,
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.247058823529412)',
            fontWeight: 400,
            color: 'white',
            fontSize: 16,
            '&:hover': {
                backgroundColor: BUTTON_BG,
            },
        },
        modalRoot: {
            background: GREY_BG,
            maxWidth: 340,
            color: WHITE_BG,
            borderRadius: 20,
        },
        modalContentRoot: {
            padding: '30px !important',
        },
        modalText: {
            textAlign: 'center',
            color: WHITE_BG,
            fontSize: 20,
            fontWeight: 600,
        },
        modalHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        buttonsWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        indicator: {
            display: 'none',
        },
        icons: {
            margin: '0 !important',
        },
    };
};
