import {
    CLEAR_IMAGE_PATH,
    CLEAR_MESSAGES,
    GET_IFRAME,
    GET_ONE_CONFIG,
    GET_SCENES,
    REMOVE_IMAGE,
    REMOVE_SCENE,
    SAVE_CONFIG,
    SAVE_IMAGE,
    SAVE_SCENE,
    SAVE_SCENE_FAILURE,
    SAVE_SCENE_SUCCESS,
    UPDATE_SCENE,
    UPDATE_SCENE_SUCCESS,
} from '../actions/ScenesActions';

import { getWorldConfig, getInterfaceConfig } from '../utils';

const initialState = {
    config: [],
    isLoading: false,
    needSaveId: null,
    errorMsg: '',
    successMsg: '',
    mockConfig: {},
    interfaceConfig: {},
    worldConfig: {},
    imagePath: {},
    preview: {
        url: '',
        embededCode: '',
    },
};

export default function SceneReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_SCENE_SUCCESS: {
            const { scene } = payload;
            const sceneId = scene.id;
            const scenesArray = state.config;
            const newConfig = scenesArray.map((sceneItem) => (sceneItem.id === sceneId ? scene : sceneItem));

            return {
                ...state,
                config: newConfig,
                isLoading: false,
            };
        }
        case SAVE_SCENE:
        case UPDATE_SCENE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case SAVE_SCENE_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        case GET_IFRAME: {
            const { preview } = payload;

            return {
                ...state,
                preview,
            };
        }

        case SAVE_IMAGE: {
            const { image, name } = payload;

            return {
                ...state,
                imagePath: { [name]: image },
            };
        }

        case CLEAR_IMAGE_PATH:
        case REMOVE_IMAGE: {
            return {
                ...state,
                imagePath: {},
            };
        }
        case CLEAR_MESSAGES: {
            return {
                ...state,
                errorMsg: '',
                successMsg: '',
            };
        }

        case GET_SCENES: {
            return {
                ...state,
                config: payload.scenes,
                errorMsg: '',
                successMsg: '',
            };
        }

        case REMOVE_SCENE: {
            const { config } = state;
            const { success, sceneId } = payload;

            const newState = config.filter((scene) => scene.id !== sceneId);

            return {
                ...state,
                config: newState,
                errorMsg: success ? '' : 'Server Error',
                successMsg: success ? 'Scene deleted successfully' : '',
            };
        }

        case SAVE_CONFIG: {
            const { success, mockConfig } = payload;
            console.log('mockConfig', getWorldConfig(mockConfig), getInterfaceConfig(mockConfig));
            return {
                ...state,
                mockConfig,
                worldConfig: getWorldConfig(mockConfig),
                interfaceConfig: getInterfaceConfig(mockConfig),
                errorMsg: success ? '' : 'Server Error',
                successMsg: success ? 'Scene updated successfully' : '',
            };
        }

        case GET_ONE_CONFIG: {
            const { config } = payload;
            delete config.buttons;
            const newConfig = JSON.parse(JSON.stringify(config));

            console.log('GET_ONE_CONFIG', getWorldConfig(newConfig), newConfig);
            return {
                ...state,
                mockConfig: newConfig,
                interfaceConfig: getInterfaceConfig(newConfig),
                worldConfig: getWorldConfig(newConfig),
            };
        }

        case SAVE_SCENE_SUCCESS: {
            const { success, scene } = payload;
            const oldConfig = state.config || [];

            return {
                ...state,
                isLoading: false,
                config: [...oldConfig, scene],
                errorMsg: !success ? 'Server Error, please try again' : '',
                successMsg: success ? 'Success' : '',
            };
        }

        default:
            return state;
    }
}
