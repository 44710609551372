import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';
import CheckBox from '../../CheckBox';
import Slider from '../../Slider';

import withSettingsWrapperHOC from '../SettingsHOC';
import withDisabled from '../../../HOC/DisableHOC';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.POSTPROCESSING;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const PostProcessing = (props) => {
    const { currentConfig, onChange } = props;
    const {
        enablePostProcessing,
        antiAliasingSamples,
        enableBloom,
        bloomThreshold,
        bloomWeight,
        bloomKernel,
        enableColorProcessing,
        globalHue,
        globalDensity,
        globalExposure,
        globalSaturation,
        highlightsHue,
        highlightsDensity,
        highlightsExposure,
        highlightsSaturation,
        midtonesHue,
        midtonesDensity,
        midtonesExposure,
        midtonesSaturation,
        shadowsHue,
        shadowsDensity,
        shadowsExposure,
        shadowsSaturation,
    } = currentConfig;

    const [postProcessing, setPostProcessing] = useState(false);
    const [enabledBloom, setEnabledBloom] = useState(true);
    const [antiAliasing, setAntiAliasing] = useState(4);
    const [blThreshold, setBlThreshold] = useState(0.75);
    const [blWeight, setBlWeight] = useState(0.25);
    const [blKernel, setBlKernel] = useState(64);
    const [colorProcessing, setColorProcessing] = useState(true);
    const [hue, setHue] = useState(true);

    const [stateGlobalDensity, setStateGlobalDensity] = useState(0);
    const [stateGlobalExposure, setStateGlobalExposure] = useState(0);
    const [stateGlobalSaturation, setStateGlobalSaturation] = useState(0);
    const [stateHighlightsHue, setStateHighlightsHue] = useState(0);
    const [stateHighlightsDensity, setStateHighlightsDensity] = useState(0);
    const [stateHighlightsExposure, setStateHighlightsExposure] = useState(0);
    const [stateHighlightsSaturation, setStateHighlightsSaturation] = useState(0);
    const [stateMidtonesHue, setStateMidtonesHue] = useState(0);
    const [stateMidtonesDensity, setStateMidtonesDensity] = useState(0);
    const [stateMidtonesExposure, setStateMidtonesExposure] = useState(0);
    const [stateMidtonesSaturation, setStateMidtonesSaturation] = useState(0);
    const [stateShadowsHue, setStateShadowsHue] = useState(0);
    const [stateShadowsDensity, setStateShadowsDensity] = useState(0);
    const [stateShadowsExposure, setStateShadowsExposure] = useState(0);
    const [stateShadowsSaturation, setStateShadowsSaturation] = useState(0);

    useEffect(() => {
        if (typeof enablePostProcessing !== 'undefined') setPostProcessing(enablePostProcessing);
    }, [enablePostProcessing]);

    useEffect(() => {
        if (typeof antiAliasingSamples !== 'undefined') setAntiAliasing(antiAliasingSamples);
    }, [antiAliasingSamples]);

    useEffect(() => {
        if (typeof enabledBloom !== 'undefined') setEnabledBloom(enabledBloom);
    }, [enableBloom]);

    useEffect(() => {
        if (typeof bloomThreshold !== 'undefined') setBlThreshold(bloomThreshold);
    }, [bloomThreshold]);

    useEffect(() => {
        if (typeof bloomWeight !== 'undefined') setBlWeight(bloomWeight);
    }, [bloomWeight]);

    useEffect(() => {
        if (typeof bloomKernel !== 'undefined') setBlKernel(bloomKernel);
    }, [bloomKernel]);

    useEffect(() => {
        if (typeof enableColorProcessing !== 'undefined') setColorProcessing(enableColorProcessing);
    }, [enableColorProcessing]);

    useEffect(() => {
        if (typeof globalHue !== 'undefined') setHue(globalHue);
    }, [globalHue]);

    useEffect(() => {
        if (typeof globalDensity !== 'undefined') setStateGlobalDensity(globalDensity);
    }, [globalDensity]);

    useEffect(() => {
        if (typeof globalExposure !== 'undefined') setStateGlobalExposure(globalExposure);
    }, [globalExposure]);
    useEffect(() => {
        if (typeof globalSaturation !== 'undefined') setStateGlobalSaturation(globalSaturation);
    }, [globalSaturation]);
    useEffect(() => {
        if (typeof highlightsHue !== 'undefined') setStateHighlightsHue(highlightsHue);
    }, [highlightsHue]);
    useEffect(() => {
        if (typeof highlightsDensity !== 'undefined') setStateHighlightsDensity(highlightsDensity);
    }, [highlightsDensity]);
    useEffect(() => {
        if (typeof highlightsExposure !== 'undefined') setStateHighlightsExposure(highlightsExposure);
    }, [highlightsExposure]);
    useEffect(() => {
        if (typeof highlightsSaturation !== 'undefined') setStateHighlightsSaturation(highlightsSaturation);
    }, [highlightsSaturation]);
    useEffect(() => {
        if (typeof midtonesHue !== 'undefined') setStateMidtonesHue(midtonesHue);
    }, [midtonesHue]);
    useEffect(() => {
        if (typeof midtonesDensity !== 'undefined') setStateMidtonesDensity(midtonesDensity);
    }, [midtonesDensity]);
    useEffect(() => {
        if (typeof midtonesExposure !== 'undefined') setStateMidtonesExposure(midtonesExposure);
    }, [midtonesExposure]);
    useEffect(() => {
        if (typeof midtonesSaturation !== 'undefined') setStateMidtonesSaturation(midtonesExposure);
    }, [midtonesSaturation]);
    useEffect(() => {
        if (typeof shadowsHue !== 'undefined') setStateShadowsHue(shadowsHue);
    }, [shadowsHue]);
    useEffect(() => {
        if (typeof shadowsDensity !== 'undefined') setStateShadowsDensity(shadowsDensity);
    }, [shadowsDensity]);
    useEffect(() => {
        if (typeof shadowsExposure !== 'undefined') setStateShadowsExposure(shadowsExposure);
    }, [shadowsExposure]);
    useEffect(() => {
        if (typeof shadowsSaturation !== 'undefined') setStateShadowsSaturation(shadowsSaturation);
    }, [shadowsSaturation]);

    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };

    const classes = useStyles();

    return (
        <>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.enablePostProcessing}>
                    <CheckBox
                        val={postProcessing}
                        onChange={handleUpdate}
                        type="enablePostProcessing"
                        label="Enable post processing"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.antiAliasingSamples}>
                    <Slider
                        val={antiAliasing}
                        onChange={handleUpdate}
                        label="Anti-aliasing samples"
                        type="antiAliasingSamples"
                        min={0}
                        max={8}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.enableBloom}>
                    <CheckBox val={enabledBloom} onChange={handleUpdate} type="enableBloom" label="Enable bloom" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.bloomThreshold}>
                    <Slider
                        val={blThreshold}
                        onChange={handleUpdate}
                        label="Bloom threshold"
                        type="bloomThreshold"
                        step={0.01}
                        min={0}
                        max={1}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.bloomWeight}>
                    <Slider
                        val={blWeight}
                        onChange={handleUpdate}
                        label="Bloom weight"
                        type="bloomWeight"
                        min={0}
                        max={1}
                        step={0.01}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.bloomKernel}>
                    <Slider
                        val={blKernel}
                        onChange={handleUpdate}
                        label="Bloom kernel"
                        type="bloomKernel"
                        min={0}
                        max={1000}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.enableColorProcessing}>
                    <CheckBox
                        val={colorProcessing}
                        onChange={handleUpdate}
                        type="enableColorProcessing"
                        label="Enable color processing"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.globalHue}>
                    <Slider val={hue} onChange={handleUpdate} label="Global hue" type="globalHue" min={0} max={360} />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.globalDensity}>
                    <Slider
                        val={stateGlobalDensity}
                        onChange={handleUpdate}
                        label="Global density"
                        type="globalDensity"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.globalExposure}>
                    <Slider
                        val={stateGlobalExposure}
                        onChange={handleUpdate}
                        label="Global Exposure"
                        type="globalExposure"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.globalSaturation}>
                    <Slider
                        val={stateGlobalSaturation}
                        onChange={handleUpdate}
                        label="Global Saturation"
                        type="globalSaturation"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.highlightsHue}>
                    <Slider
                        val={stateHighlightsHue}
                        onChange={handleUpdate}
                        label="Highlights Hue"
                        type="highlightsHue"
                        min={0}
                        max={360}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.highlightsDensity}>
                    <Slider
                        val={stateHighlightsDensity}
                        onChange={handleUpdate}
                        label="Highlights Density"
                        type="highlightsDensity"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.highlightsExposure}>
                    <Slider
                        val={stateHighlightsExposure}
                        onChange={handleUpdate}
                        label="Highlights Exposure"
                        type="highlightsExposure"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.highlightsSaturation}>
                    <Slider
                        val={stateHighlightsSaturation}
                        onChange={handleUpdate}
                        label="Highlights Saturation"
                        type="highlightsSaturation"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.midtonesHue}>
                    <Slider
                        val={stateMidtonesHue}
                        onChange={handleUpdate}
                        label="Midtones Hue"
                        type="midtonesHue"
                        min={0}
                        max={360}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.midtonesDensity}>
                    <Slider
                        val={stateMidtonesDensity}
                        onChange={handleUpdate}
                        label="Midtones Density"
                        type="midtonesDensity"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.midtonesExposure}>
                    <Slider
                        val={stateMidtonesExposure}
                        onChange={handleUpdate}
                        label="Midtones Exposure"
                        type="midtonesExposure"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.midtonesSaturation}>
                    <Slider
                        val={stateMidtonesSaturation}
                        onChange={handleUpdate}
                        label="Midtones Saturation"
                        type="midtonesSaturation"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowsHue}>
                    <Slider
                        val={stateShadowsHue}
                        onChange={handleUpdate}
                        label="Shadows Hue"
                        type="shadowsHue"
                        min={0}
                        max={360}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowsDensity}>
                    <Slider
                        val={stateShadowsDensity}
                        onChange={handleUpdate}
                        label="Shadows Density"
                        type="shadowsDensity"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowsExposure}>
                    <Slider
                        val={stateShadowsExposure}
                        onChange={handleUpdate}
                        label="Shadows Exposure"
                        type="shadowsExposure"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.shadowsSaturation}>
                    <Slider
                        val={stateShadowsSaturation}
                        onChange={handleUpdate}
                        label="Shadows Saturation"
                        type="shadowsSaturation"
                        min={-100}
                        max={100}
                    />
                </CardContent>
            </Card>
        </>
    );
};

PostProcessing.propTypes = {
    //  : PropTypes.,
};

const CardContent = withDisabled(CardContentComponent);

const PostProcessingHOC = withSettingsWrapperHOC(PostProcessing, 'POSTPROCESSING');

export default PostProcessingHOC;
