import Base from './Base';

class Scene extends Base {
    async sendConfig(id, payload) {
        const data = await this.apiClient.post(`save-config/${id}`, payload);

        return data;
    }

    async updateScene(id, payload) {
        const { file, name } = payload;
        const isFile = file && file.name;
        const data = isFile
            ? await this.apiClient.putFormData(`update-scene/${id}`, { file })
            : await this.apiClient.put(`update-scene/${id}`, { name });

        return data;
    }

    async sendFile(payload) {
        const data = await this.apiClient.postFormData('save-scene', payload);
        return data;
    }

    async removeImage(path) {
        const data = await this.apiClient.delete(path);
        return data;
    }

    async getIframe(id) {
        const data = await this.apiClient.get(`preview/${id}`);
        return data;
    }

    async sendImage(id, payload) {
        const data = await this.apiClient.postFormData(`images/${id}`, payload);
        return data;
    }

    async getProtectedImage(url) {
        const blob = await this.apiClient.get(url);

        return blob;
    }

    async getConfig(payload) {
        const data = await this.apiClient.get('scenes', payload);

        return data;
    }

    // SCENE

    async getConfigById(id) {
        const data = await this.apiClient.get(`config/${id}`);

        return data;
    }

    async removeScene(id) {
        const data = await this.apiClient.delete(`scene/${id}`);

        return data;
    }
}

export default Scene;
