import React from 'react';
import { connect } from 'react-redux';
import ForgotPage from '../pages/Forgot';
import { forgotPassword } from '../actions/AuthActions';

function Forgot(props) {
    const { forgotPassword, history, session } = props;

    return <ForgotPage forgotPassword={forgotPassword} session={session} history={history} />;
}

const mapStateToProps = (state) => ({
    session: state.auth.session,
});

const mapDispatchToProps = {
    forgotPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
