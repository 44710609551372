import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonIcon from './ButtonIcon';
import styles from './TreeViewStyles';

const useTreeItemStyles = makeStyles(styles);

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {
        labelText,
        nodeId = '',
        labelInfo,
        color,
        needRemove,
        onCreate,
        onRemove,
        bgColor,
        isBlackTheme,
        ...other
    } = props;

    return (
        <TreeItem
            label={
                <div className={isBlackTheme ? classes.labelRootBlack : classes.labelRoot}>
                    <Typography className={isBlackTheme ? classes.labelTexWhite : classes.labelText}>
                        {labelText}
                    </Typography>
                    {onCreate && (
                        <ButtonIcon
                            id={nodeId}
                            onClick={onCreate}
                            iconType="AddIcon"
                            classNames={[isBlackTheme && 'withColorLight', 'withGreenIconHover']}
                        />
                    )}
                    {needRemove && (
                        <ButtonIcon
                            onClick={onRemove}
                            id={nodeId}
                            iconType="DeleteIcon"
                            classNames={[
                                'withMarginRight',
                                'deleteIcon',
                                'withRedIconHover',
                                isBlackTheme && 'withColorLight',
                            ]}
                        />
                    )}
                </div>
            }
            classes={{
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
                iconContainer: classes.iconContainer,
            }}
            nodeId={nodeId}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {},
});

export default function (props) {
    const classes = useStyles();
    const { label, children, id, onRemove, onSelect, onCreate } = props;

    const handleSelect = (e, val) => {
        e.preventDefault();
        e.stopPropagation();
        onSelect(val);
    };

    const handleRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove(id);
    };

    const handleCreate = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onCreate(id);
    };

    const needRemove = typeof onRemove === 'function';

    return (
        <TreeView
            className={classes.root}
            onNodeSelect={handleSelect}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            defaultExpanded={[id]}
        >
            <StyledTreeItem
                nodeId={id}
                labelText={label}
                onLabelClick={(e) => {
                    e.preventDefault();
                }}
                onRemove={handleRemove}
                needRemove={needRemove}
                onCreate={handleCreate}
                isBlackTheme={!needRemove}
            >
                {children}
            </StyledTreeItem>
        </TreeView>
    );
}
