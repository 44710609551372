/* eslint-disable no-shadow */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onLogout } from '../actions/AuthActions';

import MainAppPage from '../pages/MainApp';

const MainApp = (props) => {
    const { onLogout } = props;

    return <MainAppPage {...props} logout={onLogout} />;
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.button.isLoading || state.scene.isLoading,
    };
};

const mapDispatchToProps = {
    onLogout,
};

MainApp.propTypes = {
    onLogout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
