import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Dashboard, Category, LiveHelp, Message, LibraryBooks } from '@material-ui/icons';

import ScenesList from '../ui-components/List';
import Tabs from '../ui-components/Tabs/SupportTabs';
import withNotificationHOC from '../HOC/withNotificationHOC';

const SUPPORT_MOCK = [
    { id: 'online-help', name: 'Online Help' },
    { id: 'feedback', name: 'Feedback form' },
];
const TABS_LIST = [
    {
        icon: Dashboard,
        label: 'Application',
        disabled: false,
        withBorder: true,
        id: 1,
    },
    {
        icon: Category,
        label: 'models',
        disabled: false,
        withBorder: true,
        id: 2,
    },
    {
        icon: LiveHelp,
        label: 'q&a',
        disabled: true,
        withBorder: true,
        id: 3,
    },
    {
        icon: Message,
        label: 'forum',
        disabled: true,
        withBorder: true,
        id: 4,
    },
    {
        icon: LibraryBooks,
        label: 'blog',
        disabled: true,
        withBorder: false,
        id: 5,
    },
];

const useStyles = makeStyles(() => ({
    content: {
        flexGrow: 1,
        paddingTop: 60,
    },
    root: {
        width: '100%',
        height: 'calc(100vh - 60px)',
        display: 'flex',
        justifyContent: 'space-between',
        overflow: 'hidden',
    },
}));

function SuportPage({ sendFeedback }) {
    const classes = useStyles();
    const [activeItemId, setActiveItemId] = useState('online-help');
    const handleClickItem = (id) => {
        setActiveItemId(id);
    };

    return (
        <main className={classes.content}>
            <div className={classes.root}>
                <ScenesList
                    data={SUPPORT_MOCK}
                    title="Support"
                    idKey="id"
                    arrowIcon
                    nameKey="name" // SHOULD BE REPLACED name -> sceneName
                    onItemClick={handleClickItem}
                    activeItemId={activeItemId}
                />
                <Tabs backdropWithTabs tabsList={TABS_LIST} activeViewId={activeItemId} sendFeedback={sendFeedback} />
            </div>
        </main>
    );
}

export default withNotificationHOC(SuportPage);
