import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContentComponent from '@material-ui/core/CardContent';

// COMPONENTS
import Slider from '../../Slider';
import CheckBox from '../../CheckBox';
import EditableInput from '../../EditableInput';
import ThreeInputsBlock from '../../ThreeInputsBlock';

// HOC
import withSettingsWrapperHOC from '../SettingsHOC';
import withDisabled from '../../../HOC/DisableHOC';

const { config } = window;
const disabledFields = config.DISABLED_ITEMS.CAMERA;

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
        position: 'relative',
    },
    cardContent: {
        padding: '0 !important',
    },
}));

const CameraContainer = (props) => {
    const { currentConfig, onChange } = props;
    const {
        fieldOfView,
        limitRotationAngle,
        allowPanning,
        zoomButtons,
        zoomPinchEnabled,
        minZoom,
        maxZoom,
        zoomButtonsPrecision,
        zoomPinchPrecision,
        zoomWheelPrecision,
        initx,
        inity,
        initz,
        targetx,
        targety,
        targetz,
    } = currentConfig;

    const [stateFieldOfView, setStateFieldOfView] = useState(60);
    const [stateLimitRotationAngle, setStateLimitRotationAngle] = useState(90);
    const [stateAllowPanning, setStateAllowPanning] = useState(false);

    const [stateZoomButtons, setStateZoomButtons] = useState(true);
    const [stateZoomPinchEnabled, setStateZoomPinchEnabled] = useState(false);
    const [stateMinZoom, setStateMinZoom] = useState(0);
    const [stateMaxZoom, setStateMaxZoom] = useState(20);
    const [stateZoomButtonsPrecision, setStateZoomButtonsPrecision] = useState(20);
    const [stateZoomPinchPrecision, setStateZoomPinchPrecision] = useState(20);
    const [stateZoomWheelPrecision, setStateZoomWheelPrecision] = useState(20);

    const [stateInitx, setStateInitx] = useState(0);
    const [stateInity, setStateInity] = useState(0);
    const [stateInitz, setStateInitz] = useState(0);

    const [stateTargetx, setStateTargetx] = useState(0);
    const [stateTargety, setStateTargety] = useState(0);
    const [stateTargetz, setStateTargetz] = useState(0);

    useEffect(() => {
        if (typeof fieldOfView !== 'undefined') setStateFieldOfView(fieldOfView);
    }, [fieldOfView]);
    useEffect(() => {
        if (typeof limitRotationAngle !== 'undefined') setStateLimitRotationAngle(limitRotationAngle);
    }, [limitRotationAngle]);
    useEffect(() => {
        if (typeof allowPanning !== 'undefined') setStateAllowPanning(allowPanning);
    }, [allowPanning]);
    useEffect(() => {
        if (typeof zoomButtons !== 'undefined') setStateZoomButtons(zoomButtons);
    }, [zoomButtons]);
    useEffect(() => {
        if (typeof zoomPinchEnabled !== 'undefined') setStateZoomPinchEnabled(zoomPinchEnabled);
    }, [zoomPinchEnabled]);
    useEffect(() => {
        if (typeof minZoom !== 'undefined') setStateMinZoom(minZoom);
    }, [minZoom]);
    useEffect(() => {
        if (typeof maxZoom !== 'undefined') setStateMaxZoom(maxZoom);
    }, [maxZoom]);
    useEffect(() => {
        if (typeof zoomButtonsPrecision !== 'undefined') setStateZoomButtonsPrecision(zoomButtonsPrecision);
    }, [zoomButtonsPrecision]);
    useEffect(() => {
        if (typeof zoomPinchPrecision !== 'undefined') setStateZoomPinchPrecision(zoomPinchPrecision);
    }, [zoomPinchPrecision]);
    useEffect(() => {
        if (typeof zoomWheelPrecision !== 'undefined') setStateZoomWheelPrecision(zoomWheelPrecision);
    }, [zoomWheelPrecision]);

    useEffect(() => {
        if (Number(initx)) setStateInitx(Number(initx));
    }, [initx]);

    useEffect(() => {
        if (Number(inity)) setStateInity(Number(inity));
    }, [inity]);
    useEffect(() => {
        if (Number(initz)) setStateInitz(Number(initz));
    }, [initz]);

    useEffect(() => {
        if (Number(targetx)) setStateTargetx(Number(targetx));
    }, [targetx]);

    useEffect(() => {
        if (Number(targety)) setStateTargety(Number(targety));
    }, [targety]);
    useEffect(() => {
        if (Number(targetz)) setStateTargetz(Number(targetz));
    }, [targetz]);

    const handleUpdate = ({ name, value }, type) => {
        onChange(type, { id: name, name: value });
    };

    const handleChangeInput = (val, type) => {
        const value = Number.isNaN(Number(val)) ? val : Number(val);
        onChange(type, { name: value });
    };

    const classes = useStyles();
    console.log('disabledFields', disabledFields);
    return (
        <>
            <ThreeInputsBlock
                onChange={handleChangeInput}
                nameX="initx"
                valX={stateInitx}
                nameY="inity"
                valY={stateInity}
                nameZ="initz"
                valZ={stateInitz}
                label="Camera position"
                disabled={disabledFields.init}
            />
            <ThreeInputsBlock
                nameX="targetx"
                valX={stateTargetx}
                nameY="targety"
                valY={stateTargety}
                nameZ="targetz"
                valZ={stateTargetz}
                onChange={handleChangeInput}
                label="Camera target"
                disabled={disabledFields.target}
            />
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.fieldOfView}>
                    <Slider
                        val={stateFieldOfView}
                        onChange={handleUpdate}
                        label="Field of view"
                        type="fieldOfView"
                        min={1}
                        max={360}
                    />
                </CardContent>
            </Card>

            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.limitRotationAngle}>
                    <Slider
                        val={stateLimitRotationAngle}
                        onChange={handleUpdate}
                        label="Rotation limit angle"
                        type="limitRotationAngle"
                        min={1}
                        max={360}
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.allowPanning}>
                    <CheckBox
                        val={stateAllowPanning}
                        onChange={handleUpdate}
                        type="allowPanning"
                        label="Allow panning"
                    />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.zoomButtons}>
                    <CheckBox val={stateZoomButtons} onChange={handleUpdate} type="zoomButtons" label="Zoom buttons" />
                </CardContent>
            </Card>
            <Card className={classes.cardNodeList}>
                <CardContent className={classes.cardContent} disabled={disabledFields.zoomPinchEnabled}>
                    <CheckBox
                        val={stateZoomPinchEnabled}
                        onChange={handleUpdate}
                        type="zoomPinchEnabled"
                        label="Pinch zoom"
                    />
                </CardContent>
            </Card>
            <EditableInput
                label="Minimal zoom"
                title={stateMinZoom}
                onChange={handleChangeInput}
                name="minZoom"
                disabled={disabledFields.minZoom}
                withDisable
            />
            <EditableInput
                label="Maximal zoom"
                title={stateMaxZoom}
                onChange={handleChangeInput}
                name="maxZoom"
                disabled={disabledFields.maxZoom}
                withDisable
            />
            <EditableInput
                label="Zoom buttons precision"
                title={stateZoomButtonsPrecision}
                onChange={handleChangeInput}
                name="zoomButtonsPrecision"
                disabled={disabledFields.zoomButtonsPrecision}
                withDisable
            />
            <EditableInput
                label="Mouse wheel zoom precision"
                title={stateZoomWheelPrecision}
                onChange={handleChangeInput}
                name="zoomWheelPrecision"
                disabled={disabledFields.zoomWheelPrecision}
                withDisable
            />
            <EditableInput
                label="Pinch zoom precision"
                title={stateZoomPinchPrecision}
                onChange={handleChangeInput}
                name="zoomPinchPrecision"
                disabled={disabledFields.zoomPinchPrecision}
                withDisable
            />
        </>
    );
};

CameraContainer.propTypes = {
    //  : PropTypes.,
};
const CardContent = withDisabled(CardContentComponent);
const CameraContainerHOC = withSettingsWrapperHOC(CameraContainer, 'CAMERA');

export default CameraContainerHOC;
