import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import withNotificationHOC from '../HOC/withNotificationHOC';
import GRecaptcha from '../ui-components/GRecaptcha';

const { appName } = window.config;

const { config } = window;
const { termsOfUse, privacyPolicy, cookiePolicy } = config;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '} {new Date().getFullYear()}.
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    termsStyles: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
    },
    gCaptcha: {
        marginTop: 60,
    },
}));

function SignUp({ signUp, session, signIn, history }) {
    const classes = useStyles();
    const [state, setState] = useState({ password: '', email: '' });
    const [isChecked, setIsChecked] = useState(false);
    const { addToast } = useToasts();

    useEffect(() => {
        if (session) {
            const { status, accessToken, id, role, message } = session;

            if (!status && message) addToast(message, { appearance: 'error' });
            if (accessToken) {
                localStorage.setItem('token', accessToken);
                localStorage.setItem('role', role && role.toUpperCase());
                localStorage.setItem('userId', id);
                history.push('/');
            }
        }
    }, [session]);

    const handleClick = async () => {
        const result = await signUp(state);
        localStorage.setItem('email', state.email);

        window.sendinblue.identify(state.email);

        if (result.success) {
            history.push('/activation');
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleOnCkecked = () => setIsChecked(!isChecked);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {`${appName} Sign Up`}
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12}>
                            <TextField
                                autoComplete="fname"
                                name="username"
                                variant="outlined"
                                required
                                fullWidth
                                onChange={handleOnChange}
                                label="Username"
                                autoFocus
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                onChange={handleOnChange}
                                label="Email Address"
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                onChange={handleOnChange}
                                name="password"
                                label="Password"
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.termsStyles}>
                        <Checkbox value={isChecked} color="primary" onChange={handleOnCkecked} />
                        <Typography component="div" variant="caption">
                            I have read, understand, and agree to{' '}
                            <a href={termsOfUse} target="blank">
                                General Terms of use
                            </a>
                            , the{' '}
                            <a href={privacyPolicy} target="blank">
                                Privacy Policy
                            </a>{' '}
                            and the{' '}
                            <a href={cookiePolicy} target="blank">
                                Cookie Policy
                            </a>{' '}
                        </Typography>
                    </div>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        disabled={!isChecked}
                        className={classes.submit}
                    >
                        Sign Up
                    </Button>

                    <Grid container justify="flex-end">
                        <Grid xs={12} item>
                            <Link href="#/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Typography component="div" variant="caption">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </Typography>
            </Box>
            <Box mt={1}>
                <Copyright />
            </Box>
            <GRecaptcha />
        </Container>
    );
}

const SignUpWithNotification = withNotificationHOC(SignUp);

export default SignUpWithNotification;
