import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Backdrop from '../Backdrop';
import Styles from './Styles';
import TabPanel from './TabPanel';
import withDisabled from '../../HOC/DisableHOC';
import FeedbackForm from '../Support/FeedbackForm';

const { config } = window;
const { appName, supportLinkApplication, supportLinkModels } = config;

const useStyles = makeStyles(Styles);

export default function ({ tabsList, activeViewId, sendFeedback }) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenTab = (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <Paper square className={classes.root}>
            {activeViewId === 'online-help' ? (
                <>
                    <Box boxShadow={1} style={{ position: 'relative', zIndex: 3 }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            textColor="secondary"
                            className={classes.tabsRoot}
                            classes={{
                                indicator: classes.indicator,
                            }}
                        >
                            {tabsList.map((tab) => {
                                const Icon = tab.icon;
                                return (
                                    <TabWithDisabled
                                        key={tab.id}
                                        label={tab.label}
                                        classes={{
                                            fullWidth: classes.noSelectedTabs,
                                            wrapper: tab.disabled && classes.noSelectedTabs,
                                            root: classes.tabRoot,
                                            selected: classes.selectedTab,
                                        }}
                                        icon={<Icon className={classes.icons} />}
                                        disabled={tab.disabled}
                                        className={tab.withBorder ? classes.borderTab : ''}
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Backdrop
                            fullWidth
                            iconType="SupportIcon"
                            onClick={() => handleOpenTab(supportLinkApplication)}
                            title="For complete online manual please visit:"
                            cardClassNames={['backdrop_full']}
                            secondTitle={`${appName} online manual`}
                            link={supportLinkApplication}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Backdrop
                            fullWidth
                            iconType="SupportIcon"
                            onClick={() => handleOpenTab(supportLinkModels)}
                            title="For complete online manual please visit:"
                            cardClassNames={['backdrop_full']}
                            secondTitle={`${appName} models preparation manual`}
                            link={supportLinkModels}
                        />
                    </TabPanel>
                </>
            ) : (
                <FeedbackForm headerTitle="Feedback form" sendFeedback={sendFeedback} />
            )}
        </Paper>
    );
}

const TabWithDisabled = withDisabled(Tab);
