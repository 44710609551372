import React from 'react';
import { connect } from 'react-redux';
import SignUpPage from '../pages/SignUp';
import { signUp, signIn } from '../actions/AuthActions';

function SignUp(props) {
	const { signUp, signIn, history, session } = props;

	return <SignUpPage signIn={signIn} signUp={signUp} history={history} session={session} />;
}

const mapStateToProps = (state) => ({
	session: state.auth.session,
});

const mapDispatchToProps = {
	signUp,
	signIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
