export default (theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: '#505658',
            color: 'red',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'red',
        },
    },
    content: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    group: {
        marginLeft: 0,
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
        padding: 0,
        height: 50,
        // '&:hover': {
        //     backgroundColor: 'rgba(222, 223, 224, 1)'
        // },
        '&:selected': {
            backgroundColor: '#505658',
        },
    },
    labelRoot: {
        height: 50,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
        borderBottom: '1px solid #00000033',
        paddingLeft: 36,
        background: theme.mainColors.treeViewBg,
        '&:hover': {
            background: theme.hoverColors.treeViewBgHover,
        },
        color: '#585f65',
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
        fontSize: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    labelTexWhite: {
        flexGrow: 1,
        fontSize: 20,
        color: '#d0d2d4;',
    },
    labelRootBlack: {
        display: 'flex',
        height: 50,
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
        border: '1px solid #2d2e2f',
        paddingLeft: 36,
        background: '#44494c',
        margin: '5px 0',
    },
    iconContainer: {
        position: 'absolute',
        zIndex: 100,
        left: 10,
        width: 26,
        '& > svg ': {
            fontSize: 26,
            fill: '#555c62',
        },
    },
    iconRemove: {
        fontSize: 26,
        color: '#555c62',
        '&:hover': {
            fill: 'rgb(255,102, 153)',
        },
    },
    iconCreate: {
        fontSize: 26,
        color: '#555c62',
        '&:hover': {
            fill: 'rgb(0,153, 0)',
        },
    },
    withoutRound: {
        fontSize: 26,
        padding: 0,
        marginRight: 5,
        '&:hover': {
            background: 'transparent',
        },
    },
});
