import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CustomCard from '../../CustomCard';
import withSettingsWrapperHOC from '../InterfaceHOC';

const useStyles = makeStyles(() => ({
    cardNodeList: {
        marginTop: 10,
        boxShadow: 'none',
        background: '#d2d5d7',
        border: 0,
        borderRadius: 0,
    },
    cardContent: {
        padding: '0 !important',
    },
    root: {
        width: '100%',
        height: '100%',
    },
    button: {
        margin: '20px 0 10px 0',
        minWidth: 270,
        borderRadius: 20,
    },
    inputRoot: {
        background: '#e9eaeb',
        padding: 10,
        height: '100%',
    },
    inputItem: {
        height: '100%',
    },
}));

const CustomContaoner = (props) => {
    const { currentConfig, onChange } = props;

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(JSON.stringify(currentConfig, null, 2));
    }, [currentConfig]);

    const handleUpdate = (e) => {
        const val = e.target.value;
        setValue(val);
    };

    const handleStringify = (e) => {
        const val = e.target.value;
        try {
            onChange('CUSTOM', JSON.parse(val));
        } catch (error) {
            console.log('Error parsing', val);
        }
    };

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <CustomCard classNames={['textareaWrapper']}>
                    <TextField
                        label=""
                        multiline
                        rows={20}
                        onChange={handleUpdate}
                        fullWidth
                        value={value}
                        onBlur={handleStringify}
                        className={classes.inputRoot}
                        InputProps={{
                            className: classes.inputItem,
                        }}
                        inputProps={{
                            style: { height: '100%' },
                        }}
                    />
                </CustomCard>
            </div>
        </>
    );
};

CustomContaoner.propTypes = {
    //  : PropTypes.,
};

const CustomContsinerHOC = withSettingsWrapperHOC(CustomContaoner, 'CUSTOM');

export default CustomContsinerHOC;
