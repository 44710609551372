/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import CustomList from '../List';

import General from './General/GeneralContainer';
import Custom from './Custom/CustomContainer';

import withNotification from '../../HOC/withNotificationHOC';

const { config } = window;
const { INTERFACE_TABS } = config;

function InterfaceTab(props) {
    const { sceneId } = props;
    const [activeTab, setActiveTab] = useState('GENERAL');

    useEffect(() => {
        setActiveTab('GENERAL');
    }, [sceneId]);

    const handleClickListItem = (id) => {
        setActiveTab(id);
    };

    return (
        <>
            <CustomList
                title="Interface"
                onItemClick={handleClickListItem}
                onCreate={null}
                onRemoveItem={null}
                data={INTERFACE_TABS}
                idKey="id"
                nameKey="name"
                activeItemId={activeTab}
                isDisabledCreateButton
                isDisabledCreateGroup
                arrowIcon
                isDark
                wrapperStyle={{
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',
                    width: '33.33%',
                    zIndex: 2,
                }}
            />
            <General sceneId={sceneId} activeTab={activeTab} />
            <Custom sceneId={sceneId} activeTab={activeTab} />
        </>
    );
}

export default withNotification(InterfaceTab);
