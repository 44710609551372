import { Dialog, DialogActions, DialogContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { renameFile, getFileExtension } from '../../utils';
import EditableInput from '../EditableInput';
import DialogTitle from './DialogTitle';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ScenesDialog(props) {
    const { onClose, open, title, sceneName, buttonName } = props;
    const [file, setFile] = React.useState('');
    const [error, setError] = React.useState('');
    const [errorName, setErrorName] = React.useState('');
    const [fileName, setFileName] = React.useState(sceneName);
    const classes = useStyles();

    useEffect(() => {
        console.log('useEffect', sceneName, fileName);

        if (sceneName && !fileName) setFileName(sceneName);
    }, [sceneName, fileName]);

    const clearState = () => {
        setFile('');
        setFileName('');
        setError('');
        setErrorName('');
    };

    const handleCancel = () => {
        console.log('handleCancel',handleCancel);
        clearState();
        onClose();
    };

    const handleOk = () => {
        if ((!props.isEdit && !file ) || !fileName) {
            !fileName && setErrorName('Please enter the scene name');
            !file && setError('Please choose a file to upload');

            return;
        }

        const renamedFile = file ? renameFile(file, fileName) : null;
        onClose(renamedFile, fileName);
        clearState();
    };

    const handleChange = (e) => {
        const file = e.target.files && e.target.files[0];
        setError('');
        setErrorName('');

        if (!file || getFileExtension(file.name) !== 'glb') {
            setError('Only .GLB files allowed!');

            return;
        }

        setFile(file);
        // setFileName(file.name);
    };

    const handleChangeName = (name) => {
        setFileName(name);
    };

    const buttonStylesAdd = clsx(classes.button, classes.addButton);

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            classes={{
                paperScrollPaper: classes.dialogRoot,
            }}
        >
            <DialogTitle onClose={handleCancel}>{title}</DialogTitle>
            <DialogContent dividers className={classes.rootBody}>
                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.paper}>
                    <EditableInput
                        title={fileName || ''}
                        label="Scene name"
                        onChange={handleChangeName}
                        cardClassName={['whiteModal']}
                        cardStyles={{ width: '100%', marginBottom: 20 }}
                        isWhite
                    />
                    {errorName && <span className={classes.errorName}>{errorName}</span>}
                    <EditableInput
                        title={file?.name || ''}
                        disabled
                        label="Scene file"
                        onChange={() => {}}
                        cardClassName={['whiteModal']}
                        isWhite
                    />
                    {error && <span className={classes.error}>{error}</span>}
                    <Button variant="contained" component="label" className={classes.button}>
                        Choose
                        <input type="file" style={{ display: 'none' }} accept=".GLB" onChange={handleChange} />
                    </Button>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', padding: '20px 0 30px 0 ' }}>
                <Button
                    onClick={handleOk}
                    // disabled={!fileName || !fileName.length || !file}
                    className={buttonStylesAdd}
                    classes={{ disabled: buttonStylesAdd }}
                >
                    {buttonName}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ScenesDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    sceneName: PropTypes.string,
    buttonName: PropTypes.string,
};

ScenesDialog.defaultProps = {
    sceneName: '',
    buttonName: 'ADD',
};
