import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from '../actions/UiEffectsActions';

const initialState = {
    message: '',
    notificationType: 'error',
    show: false,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_NOTIFICATION: {
            const { message, isSuccess, show } = payload;

            const notificationType = isSuccess ? 'success' : 'error';

            return {
                ...state,
                message,
                notificationType,
                show,
            };
        }

        case HIDE_NOTIFICATION: {
            const { message, show } = payload;
            console.log('RRERERERERER');
            return {
                ...state,
                message,
                show,
            };
        }

        default:
            return state;
    }
}
