import { Divider, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import ListItem from './ListItem';
import styles from './styles';

const useStyles = makeStyles(styles);

export default (props) => {
    const classes = useStyles();
    const {
        data,
        isDark,
        activeItemId,
        onClickItem,
        idKey,
        nameKey,
        isEditable,
        onRemove,
        onChange,
        arrowIcon,
        onEdit,
    } = props;

    const dividerStyles = clsx({
        [classes.dividerDark]: isDark,
    });

    const rootStyles = clsx(classes.root, {
        [classes.rootDark]: isEditable,
    });

    return (
        <List className={rootStyles}>
            {data && data.length
                ? data.map((item, index) => {
                      if (item) {
                          const id = item[idKey];
                          const name = item[nameKey];
                          return (
                              <React.Fragment key={id}>
                                  {/* {index === 1 && !isEditable ? <Divider /> : null} */}
                                  <ListItem
                                      activeItemId={activeItemId}
                                      isDark={isDark}
                                      itemId={id}
                                      name={name}
                                      onRemove={onRemove}
                                      onClickItem={onClickItem}
                                      isEditable={isEditable}
                                      onChange={onChange}
                                      arrowIcon={arrowIcon}
                                      onEdit={onEdit}
                                      disabled={item?.disabled}
                                  />
                                  {isEditable ? null : <Divider className={dividerStyles} />}
                              </React.Fragment>
                          );
                      }
                      return null;
                  })
                : null}
        </List>
    );
};
