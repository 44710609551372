import CssBaseline from '@material-ui/core/CssBaseline';
import { Paper, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import TreeView from '../TreeView';
import Header from './Header';
import List from './List';
import styles from './styles';

const useStyles = makeStyles(styles);

export default function (props) {
    const classes = useStyles();
    const {
        data,
        title,
        idKey,
        nameKey,
        onRemoveItem,
        onCreate,
        onCreateAdditional,
        onItemClick,
        activeItemId,
        isDisabledCreateGroup,
        isDisabledCreateButton,
        groupList,
        isNeedCreateGroup,
        showCreateGroupBtn,
        additionalClasses,
        wrapperStyle,
        isDark,
        arrowIcon,
        onEditItem,
        disabled,
    } = props;

    const handleItemClick = (id) => onItemClick(id);
    const handleRemove = (id) => (onRemoveItem && onRemoveItem(id)) || null;
    const handleRemoveGroup = (id) => (onRemoveItem && onRemoveItem(id, 'GROUP')) || null;
    const handleSelectGroup = (id) => onItemClick(id, 'GROUP');
    const handleCreateGroup = () => onCreateAdditional('GROUP');
    const handleCreateButton = (groupId) => onCreateAdditional(groupId);
    const handleEdit = (id) => onEditItem(id);

    const paperRootStyles = clsx(classes.paper, {
        [classes.darkRootHeader]: isDark,
    });

    return (
        <>
            <CssBaseline />
            <Paper square className={paperRootStyles} style={wrapperStyle}>
                <Header
                    title={title}
                    onCreate={onCreate}
                    onCreateAdditional={handleCreateGroup}
                    isDisabledCreateButton={isDisabledCreateButton}
                    isDisabledAdditionalBtn={isDisabledCreateGroup}
                    isNeedCreateGroup={isNeedCreateGroup}
                    showCreateGroupBtn={showCreateGroupBtn}
                    isDark={isDark}
                />
                <Divider />
                {groupList && groupList.length > 0 && (
                    <Scrollbars style={{ height: 'calc(100vh - 156px)' }} autoHide>
                        <div className={classes.groupsListWrapper}>
                            {groupList.map((item) => {
                                return (
                                    <TreeView
                                        label={item[nameKey]}
                                        key={item.buttonId}
                                        id={item.buttonId}
                                        onSelect={handleSelectGroup}
                                        onRemove={handleRemoveGroup}
                                        onCreate={handleCreateButton}
                                    >
                                        <List
                                            data={item.buttons}
                                            key={item.buttonId}
                                            additionalClasses={additionalClasses}
                                            activeItemId={activeItemId}
                                            onClickItem={handleItemClick}
                                            idKey={idKey}
                                            nameKey={nameKey}
                                            isDark={isDark}
                                            onRemove={(onRemoveItem && handleRemove) || null}
                                        />
                                    </TreeView>
                                );
                            })}
                        </div>
                    </Scrollbars>
                )}
                {data && data.length > 0 && (
                    <Scrollbars style={{ height: 'calc(100vh - 110px)' }} autoHide>
                        <List
                            data={data}
                            additionalClasses={additionalClasses}
                            isBlackTheme={isNeedCreateGroup}
                            onRemove={handleRemove}
                            onEdit={onEditItem ? handleEdit : null}
                            arrowIcon={arrowIcon}
                            onClickItem={handleItemClick}
                            activeItemId={activeItemId}
                            isDark={isDark}
                            idKey={idKey}
                            nameKey={nameKey}
                        />
                    </Scrollbars>
                )}
            </Paper>
        </>
    );
}
