import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import withNotificationHOC from '../HOC/withNotificationHOC';

const { appName } = window.config;

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        background: theme.hoverColors.hoverListItemDark,
    },
    button: {
        minWidth: 280,
        height: 40,
        borderRadius: 20,
        background: '#009999',
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.25)',
        marginTop: 40,
        fontSize: 16,
        '&:hover': {
            background: '#009999',
        },
        '&:disabled': {
            background: '#00999966',
            color: '#fff',
        },
        color: '#fff',
        textShadow: '0px 1px 3px rgba(0, 0, 0, 0.35)',
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    text: {
        fontWeight: 300,
        fontSize: 18,
        margin: 0,
        fontFamily: 'Roboto',
        height: 20,
        color: '#333333',
    },
}));

function Activation({ resentEmailStatus, resentActivationEmail }) {
    const classes = useStyles();

    const handleClick = () => {
        const email = localStorage.getItem('email');
        resentActivationEmail({ email });
    };

    return (
        <main className={classes.content}>
            <div className={classes.text}>Thank you for choosing {appName}!</div>
            <div className={classes.text}> </div>
            <div className={classes.text}>An activation email has been sent to your mailbox.</div>
            <div className={classes.text}>
                To start using the application, please follow the instructions in the activation email.
            </div>
            <div className={classes.text}> </div>
            <div className={classes.text}>
                If you haven&apos;t received the activation email, please click the button below to send it again.
            </div>
            <Button variant="contained" onClick={handleClick} className={classes.button}>
                RESEND ACTIVATION EMAIL
            </Button>
        </main>
    );
}

const ActivationWithNotification = withNotificationHOC(Activation);

export default ActivationWithNotification;
