import React, { useEffect, useState } from 'react';
import Header from './List/Header';
import List from './List/List';

export default function ButtonDetailsList(props) {
    const { data, onAdd, isDisabled, onRemove, onChange, title, type, idKey, nameKey } = props;
    const [nodesList, setList] = useState([]);

    useEffect(() => {
        if (data) {
            setList(data);
        }
    }, [data]);

    const handleRemove = (id) => onRemove(id, type);

    const handleChange = (payload) => {
        onChange(payload, type);
    };

    const handleAdd = () => onAdd(type);

    return (
        <>
            <Header
                title={title}
                onCreate={handleAdd}
                isNeedCreateGroup={false}
                showCreateGroupBtn={false}
                isDark
                isEditable
            />
            <List
                onItemClick={() => {}}
                onRemove={handleRemove}
                data={nodesList}
                idKey={idKey}
                nameKey={nameKey}
                onChange={handleChange}
                onClickItem={() => {}}
                isDisabledCreateButton={isDisabled}
                isDisabledCreateGroup
                isDark
                isEditable
            />
        </>
    );
}
