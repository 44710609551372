import React, { useEffect, useRef, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Fade } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Apps, Language, More, OndemandVideo, Web } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import Backdrop from '../Backdrop';
import ButtonDetails from '../ButtonDetails';
import HighlightTab from '../Highlights/HighlightTab';
import List from '../List';
import DialogTitle from '../Modals/DialogTitle';
import Preview from '../Preview/Preview';
import Styles from './Styles';
import TabPanel from './TabPanel';

import withDisabled from '../../HOC/DisableHOC';

import DeleteModal from '../Modals/DeleteDialog';

import SettingsTab from '../Settings/SettingsTab';
import InterfaceTab from '../Interface/InterfaceTab';

const useStyles = makeStyles(Styles);

export default function (props) {
    const classes = useStyles();
    const {
        buttonList,
        updateNode,
        updateButton,
        updateHighlight,
        createButton,
        sceneId,
        removeButton,
        createNode,
        removeNode,
        createHighlight,
        removeHighlight,
        currentButton,
        getOneButton,
        nodeList,
        highlightList,
        createButtonGroup,
        buttonGroupList,
        updateButtonForGroup,
        currentHighlight,
        getOneHighlight,
        saveImage,
        buttonListMaterials,
        buttonListGeometry,
        preview,
        getIframe,
        showBackdrop,
        onOpenDialog,
        tabsList,
        backdropWithTabs,
        createButtonMaterial,
        removeButtonMaterial,
        updateButtonMaterial,
        createMaterialNode,
        removeMaterialNode,
        updateMaterialNode,
        firstButtonId,
    } = props;

    const [value, setValue] = useState(0);
    const [buttonId, setButtonId] = useState(null);
    const [groupId, setGroupId] = useState(null);
    const [openGroupModal, setOpenGroupModal] = useState(false);
    const [idForRemove, setIdForRemove] = useState(null);
    const [typeForRemove, setTypeForRemove] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const prevSceneIdRef = useRef();
    const prevButtonIdRef = useRef(null);

    const handleOpenButton = (id, type) => {
        if (id) {
            if (type && type === 'GROUP') {
                setGroupId(id);
                getOneButton(id);
                setButtonId(null);
            } else if (type !== 'GROUP' && id !== buttonId) {
                getOneButton(id);
                setButtonId(id);
                setGroupId(null);
            }
        }
    };

    useEffect(() => {
        if (sceneId && prevSceneIdRef.current !== sceneId && buttonGroupList.length) {
            handleOpenButton(firstButtonId, 'GROUP');
        }
    }, [buttonGroupList, sceneId]);

    useEffect(() => {
        console.log('sceneId, prevSceneIdRef', firstButtonId);

        if (prevButtonIdRef.current !== firstButtonId) {
            setGroupId(firstButtonId);
            prevButtonIdRef.current = firstButtonId;
        }
    }, [firstButtonId]);

    useEffect(() => {
        if (value === 4 && sceneId) {
            if (
                +localStorage.getItem('previeTabSceneId') &&
                +localStorage.getItem('previeTabSceneId') === sceneId &&
                preview.url
            )
                return;
            getIframe(sceneId);
            localStorage.setItem('previeTabSceneId', sceneId);
        }

        prevSceneIdRef.current = sceneId;
    }, [sceneId, value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => setOpenGroupModal(false);

    const handleCreateButton = (id) => {
        createButton(sceneId, id);
        handleClose();
    };

    const handleCreateGeometry = () => {
        createButtonGroup(sceneId, 'GEOMETRY');
        setButtonId(null);
        handleClose();
    };

    const handleCreateMaterials = () => {
        createButtonGroup(sceneId, 'MATERIALS');
        setButtonId(null);
        handleClose();
    };

    const handleCreateNode = () => createNode(sceneId, buttonId);
    const handleRemoveNode = (id) => removeNode(sceneId, buttonId, id);
    const handleCloseDelete = () => setIsOpenDeleteModal(false);

    const handleOpenRemoveModal = (id, type) => {
        setIsOpenDeleteModal(true);
        setIdForRemove(id);
        setTypeForRemove(type);
    };

    const handleRemoveButton = async (id, type = null) => {
        await removeButton(sceneId, id, type);

        handleCloseDelete();
    };

    const handleCreateHighlight = () => {
        const oldHighlights = (currentButton && currentButton.highlights) || [];
        updateButton(sceneId, buttonId, {
            highlights: [
                ...oldHighlights,
                {
                    highlightNodeId: new Date().getTime().toString(),
                    highlightNodeName: `Highlight ${oldHighlights.length + 1}`,
                },
            ],
        });
    };

    const handleChangeHighlight = (id, highlightNodeName) => {
        const oldHighlights = (currentButton && currentButton.highlights) || [];
        const currentItem = oldHighlights.find((item) => item.highlightNodeId === id);
        const highlights = oldHighlights.map((item) =>
            item.highlightNodeId === id ? { ...currentItem, highlightNodeName } : item
        );

        updateButton(sceneId, buttonId, { highlights });
    };

    const handleRemoveHighlight = (id) => {
        const oldHighlights = (currentButton && currentButton.highlights) || [];
        const highlights = oldHighlights.filter((item) => item.highlightNodeId !== id);
        updateButton(sceneId, buttonId, { highlights });
    };

    const handleChangeButtonName = async (payload) => {
        console.log('sceneId, buttonId || groupId,', sceneId, buttonId, groupId);
        await updateButton(sceneId, buttonId || groupId, payload);
    };
    const handleChahgeButtonImage = (payload) => {
        updateButton(sceneId, buttonId, payload);
    };
    const handleChangeButtonGroup = (id) => updateButtonForGroup(sceneId, id, { buttonGroupId: groupId });
    const handleChangeNode = (id, nodeName) => updateNode({ buttonId, nodeId: id, value: nodeName, sceneId });
    const handleOpenButtonModal = (type) => {
        type === 'GROUP' ? setOpenGroupModal(true) : handleCreateButton(type);
    };

    const role = localStorage.getItem('role');

    const TABS_LIST = [
        { icon: Apps, label: 'Buttons', disabled: false, withBorder: true, id: 1 },
        {
            icon: More,
            label: 'HIGHLIGHTS',
            disabled: false,
            withBorder: true,
            id: 2,
        },
        { icon: Web, label: 'INTERFACE', disabled: !(role === 'ADMIN' || role === 'EDITOR'), withBorder: true, id: 3 },
        {
            icon: Language,
            label: 'Settings',
            disabled: !(role === 'ADMIN' || role === 'EDITOR' || role === 'CUSTOMER'),
            withBorder: true,
            id: 4,
        },
        {
            icon: OndemandVideo,
            label: 'Preview',
            disabled: false,
            withBorder: false,
            id: 5,
        },
    ];

    console.log('RENDER groupId', groupId);
    return (
        <Paper square className={classes.root}>
            {showBackdrop ? (
                <Backdrop
                    cardClassNames={['backdrop_full']}
                    fullWidth
                    onClick={onOpenDialog}
                    title="Add your 3D file here"
                />
            ) : (
                <>
                    <Box boxShadow={1} style={{ position: 'relative', zIndex: 3 }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            textColor="secondary"
                            className={classes.tabsRoot}
                            classes={{
                                indicator: classes.indicator,
                            }}
                        >
                            {(tabsList || TABS_LIST).map((tab) => {
                                const Icon = tab.icon;
                                return (
                                    <TabWithDisabled
                                        key={tab.id}
                                        label={tab.label}
                                        classes={{
                                            fullWidth: classes.noSelectedTabs,
                                            wrapper: tab.disabled && classes.noSelectedTabs,
                                            root: classes.tabRoot,
                                            selected: classes.selectedTab,
                                        }}
                                        icon={<Icon className={classes.icons} />}
                                        disabled={tab.disabled}
                                        className={tab.withBorder ? classes.borderTab : ''}
                                    />
                                );
                            })}
                        </Tabs>
                    </Box>
                    <>
                        <TabPanel value={value} index={0}>
                            <Fade timeout={1000} style={{ height: '100%' }} in={Boolean(value === 0)}>
                                <div className={classes.rootTabPanelBlack}>
                                    <List
                                        title="Buttons"
                                        onItemClick={handleOpenButton}
                                        onCreate={null}
                                        onCreateAdditional={handleOpenButtonModal}
                                        onRemoveItem={(id, type) =>
                                            type ? handleOpenRemoveModal(id, type) : handleRemoveButton(id, type)
                                        }
                                        data={[]} // BUTTONS WITHOUT GROUP
                                        idKey="buttonId"
                                        nameKey="buttonName"
                                        showCreateGroupBtn
                                        activeItemId={buttonId}
                                        isDisabledCreateButton={!sceneId || !buttonGroupList.length}
                                        isDisabledCreateGroup={!sceneId}
                                        withGroups
                                        groupList={buttonGroupList}
                                        isNeedCreateGroup
                                        isDark
                                        wrapperStyle={{
                                            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.35)',
                                            width: '33.33%',
                                            zIndex: 2,
                                        }}
                                    />
                                    <Dialog
                                        open={openGroupModal}
                                        keepMounted
                                        onClose={handleClose}
                                        classes={{ paper: classes.modalRoot }}
                                    >
                                        <DialogTitle isDark onClose={handleClose}>
                                            Add group
                                        </DialogTitle>
                                        <DialogContent dividers className={classes.modalContentRoot}>
                                            <DialogContentText className={classes.modalText}>
                                                Please select the type of the group to create
                                            </DialogContentText>
                                            <DialogActions className={classes.buttonsWrapper}>
                                                {createButtonGroup && (
                                                    <Button
                                                        onClick={handleCreateGeometry}
                                                        className={classes.modalButton}
                                                    >
                                                        Geometry
                                                    </Button>
                                                )}
                                                {createButtonGroup && (
                                                    <Button
                                                        onClick={handleCreateMaterials}
                                                        className={classes.modalButton}
                                                    >
                                                        Materials
                                                    </Button>
                                                )}
                                            </DialogActions>
                                        </DialogContent>
                                    </Dialog>
                                    {buttonGroupList.length ? (
                                        <ButtonDetails
                                            dataNodes={nodeList}
                                            currentButton={currentButton}
                                            title={(currentButton && currentButton.buttonName) || ''}
                                            onChangeConfig={(payload) => handleChangeButtonName(payload)}
                                            onChangeNode={handleChangeNode}
                                            onRemoveNode={handleRemoveNode}
                                            onRemoveHighlight={handleRemoveHighlight}
                                            onChangeHighlight={handleChangeHighlight}
                                            addNode={handleCreateNode}
                                            addHighlight={handleCreateHighlight}
                                            isDisabledAddNode={!buttonId}
                                            chahgeButtonImage={handleChahgeButtonImage}
                                            changeButtonGroup={handleChangeButtonGroup}
                                            buttonList={buttonList}
                                            dataHighlights={currentButton.highlights || []}
                                            sceneId={sceneId}
                                            buttonListMaterials={buttonListMaterials}
                                            buttonListGeometry={buttonListGeometry}
                                            createButtonMaterial={createButtonMaterial}
                                            removeButtonMaterial={removeButtonMaterial}
                                            updateButtonMaterial={updateButtonMaterial}
                                            createMaterialNode={createMaterialNode}
                                            removeMaterialNode={removeMaterialNode}
                                            updateMaterialNode={updateMaterialNode}
                                            onCreateGroup={() => {
                                                handleOpenButtonModal('GROUP');
                                            }}
                                        />
                                    ) : (
                                        <Backdrop
                                            onClick={() => {
                                                handleOpenButtonModal('GROUP');
                                            }}
                                            iconType="FolderIcon"
                                            title="Add a button group"
                                        />
                                    )}
                                </div>
                            </Fade>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Fade timeout={1000} style={{ height: '100%' }} in={Boolean(value)}>
                                <div className={classes.rootTabPanelBlack}>
                                    <HighlightTab
                                        highlightList={highlightList}
                                        highlight={currentButton}
                                        currentHighlight={currentHighlight}
                                        buttonId={buttonId}
                                        sceneId={sceneId}
                                        getOneHighlight={getOneHighlight}
                                        removeHighlight={removeHighlight}
                                        createHighlight={createHighlight}
                                        updateHighlight={updateHighlight}
                                        saveImage={saveImage}
                                    />
                                </div>
                            </Fade>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Fade timeout={1000} style={{ height: '100%' }} in={Boolean(value)}>
                                <div className={classes.rootTabPanelBlack}>
                                    <InterfaceTab sceneId={sceneId} />
                                </div>
                            </Fade>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Fade timeout={1000} style={{ height: '100%' }} in={Boolean(value)}>
                                <div className={classes.rootTabPanelBlack}>
                                    <SettingsTab sceneId={sceneId} />
                                </div>
                            </Fade>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Fade timeout={1000} style={{ height: '100%' }} in={Boolean(value)}>
                                <div
                                    className={classes.rootTabPanelBlack}
                                    style={{
                                        flexDirection: 'column',
                                        justifyContent: 'initial',
                                    }}
                                >
                                    <Scrollbars style={{ height: 'calc(100vh - 112px)' }} autoHide>
                                        <Preview url={preview.url} embededCode={preview.embededCode} />
                                    </Scrollbars>
                                </div>
                            </Fade>
                        </TabPanel>
                    </>
                </>
            )}
            <DeleteModal
                onClose={handleCloseDelete}
                onDelete={handleRemoveButton}
                title="Delete group"
                pageName="group of buttons"
                id={idForRemove}
                type={typeForRemove}
                isOpen={isOpenDeleteModal}
            />
        </Paper>
    );
}

const TabWithDisabled = withDisabled(Tab);
