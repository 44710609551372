import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';

import DialogTitle from './DialogTitle';

const BUTTON_BG = '#009999';
const BUTTON_BG_RED = '#cc0202';
const GREY_BG = '#777e86';
const WHITE_BG = '#FFFFFF';

const Styles = () => ({
    modalButton: {
        width: 120,
        height: 40,
        backgroundColor: BUTTON_BG,
        borderRadius: 20,
        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.247058823529412)',
        fontWeight: 400,
        color: 'white',
        fontSize: 16,
        '&:hover': {
            backgroundColor: BUTTON_BG,
        },
    },
    modalRoot: {
        background: GREY_BG,
        maxWidth: 340,
        color: WHITE_BG,
        borderRadius: 20,
    },
    modalContentRoot: {
        padding: '30px !important',
    },
    modalText: {
        textAlign: 'center',
        color: WHITE_BG,
        fontSize: 20,
        fontWeight: 600,
    },
    modalHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    redBtn: {
        backgroundColor: BUTTON_BG_RED,
        '&:hover': {
            backgroundColor: BUTTON_BG_RED,
        },
    },
});
const useStyles = makeStyles(Styles);

const DeleteDialog = (props) => {
    const { isOpen, onClose, pageName, onDelete, title, id, type } = props;
    const classes = useStyles();

    const handleRemove = () => onDelete(id, type);

    return (
        <Dialog open={isOpen} keepMounted onClose={onClose} classes={{ paper: classes.modalRoot }}>
            <DialogTitle isDark onClose={onClose}>
                {title}
            </DialogTitle>
            <DialogContent dividers className={classes.modalContentRoot}>
                <DialogContentText className={classes.modalText}>
                    {`Do you really want to delete an entire ${pageName}`}
                </DialogContentText>
                <DialogActions className={classes.buttonsWrapper}>
                    <Button onClick={onClose} className={classes.modalButton}>
                        CANCEL
                    </Button>
                    <Button onClick={handleRemove} className={`${classes.modalButton} ${classes.redBtn}`}>
                        DELETE
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

DeleteDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    pageName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default DeleteDialog;
